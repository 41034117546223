import { StyleSheet, Wrap, useColors } from "components/common";
import ShieldIcon from "assets/icons/shield-righthalf.svg";

export const ControlHeader = () => {
  const colors = useColors();

  return (
    <Wrap style={styles.header}>
      <ShieldIcon height={100} width={100} color={colors.textSecondary} />
    </Wrap>
  );
};

const styles = StyleSheet.create({
  header: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 32,
    marginBottom: 16,
    opacity: 0.8,
  },
});
