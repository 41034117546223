import { FragmentType, gql, getFragmentData } from "__generated__";
import { UserPhoto } from "components/User/UserPhoto";
import { StyleSheet, Text, View, useColors } from "components/common";
import { Link } from "expo-router";
import { useUpdateCurrentUserPhoto } from "utils/photo";

const Header_SettingsFragment = gql(`
  fragment Header_SettingsFragment on User {
    id
    firstName
    lastName
    ...Photo_UserFragment
  }
`);

type SettingsHeaderProps = {
  user?: FragmentType<typeof Header_SettingsFragment> | null;
};

export const SettingsHeader = (props: SettingsHeaderProps) => {
  const user = getFragmentData(Header_SettingsFragment, props.user);

  const colors = useColors();
  const [upload] = useUpdateCurrentUserPhoto();

  const photo = (
    <View>
      <UserPhoto size={100} user={user} upload={upload} />
    </View>
  );
  const name = [user?.firstName, user?.lastName].filter((_) => _).join(" ");

  return (
    <View
      style={[
        styles.header,
        {
          backgroundColor: colors.card,
          borderColor: colors.border,
        },
      ]}
    >
      {user ? (
        <Link
          style={{ alignSelf: "center" }}
          disabled={!user}
          href={`/user/${user?.id}/`}
        >
          {photo}
        </Link>
      ) : (
        photo
      )}
      <Text style={styles.name} loading={!user} shimmerMaxWidth={160}>
        {user && <Link href={`/user/${user?.id}/`}>{name}</Link>}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    gap: 16,
    flex: 1,
    justifyContent: "center",
    marginHorizontal: -16,
    marginTop: -16,
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginBottom: 16,
  },
  name: {
    fontWeight: "600",
    textAlign: "center",
    fontSize: 18,
  },
});
