import { HeaderButton } from "components/common";
import { Link, Stack } from "expo-router";
import { useTranslation } from "react-i18next";

export const unstable_settings = {
  initialRouteName: "index",
};

export default function Layout() {
  const { t } = useTranslation("pages");
  const { t: g } = useTranslation();

  return (
    <Stack screenOptions={{ headerTitleAlign: "center" }}>
      <Stack.Screen
        name="index"
        options={{
          title: t("settings.title"),
          headerRight: () => (
            <Link asChild href="/profile/edit">
              <HeaderButton text={g("edit")} />
            </Link>
          ),
        }}
      />
    </Stack>
  );
}
