import { FragmentType, gql, getFragmentData } from "__generated__";
import { UserPhoto } from "components/User/UserPhoto";
import { StyleSheet, Text, View } from "components/common";
import { useTranslation } from "react-i18next";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useUpdateCurrentUserPhoto } from "utils/photo";

const ProfileEditHeader_UserFragment = gql(`
  fragment ProfileEditHeader_UserFragment on User {
    ...Photo_UserFragment
  }
`);

type ProfileEditHeaderProps = {
  user?: FragmentType<typeof ProfileEditHeader_UserFragment> | null;
};

export const ProfileEditHeader = (props: ProfileEditHeaderProps) => {
  const user = getFragmentData(ProfileEditHeader_UserFragment, props.user);

  const { t } = useTranslation("ProfileEditHeader");
  const [upload, updatePhoto] = useUpdateCurrentUserPhoto();

  return (
    <View style={styles.header}>
      <UserPhoto size={100} upload={upload} user={user} />
      <View style={[upload && { opacity: 0.5 }]}>
        <TouchableOpacity disabled={!!upload} onPress={updatePhoto}>
          <Text style={styles.name} colorName="primary">
            {t("choosePhoto")}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    gap: 16,
    flex: 1,
    justifyContent: "center",
    marginHorizontal: -16,
    marginTop: -16,
    paddingVertical: 16,
    marginBottom: 16,
  },
  name: {
    textAlign: "center",
    fontSize: 17,
  },
});
