// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(app)/(root)/(modal)/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/_layout.tsx"); } },
  "./(app)/(root)/(modal)/ad/[id]/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/ad/[id]/index.tsx"); } },
  "./(app)/(root)/(modal)/ad/create.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/ad/create.tsx"); } },
  "./(app)/(root)/(modal)/campaign/[id]/ads/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/campaign/[id]/ads/index.tsx"); } },
  "./(app)/(root)/(modal)/campaign/[id]/edit.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/campaign/[id]/edit.tsx"); } },
  "./(app)/(root)/(modal)/campaign/[id]/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/campaign/[id]/index.tsx"); } },
  "./(app)/(root)/(modal)/campaign/create.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/campaign/create.tsx"); } },
  "./(app)/(root)/(modal)/campaign/picker/[id].tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/campaign/picker/[id].tsx"); } },
  "./(app)/(root)/(modal)/car/[id]/driver/add.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/car/[id]/driver/add.tsx"); } },
  "./(app)/(root)/(modal)/car/[id]/edit.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/car/[id]/edit.tsx"); } },
  "./(app)/(root)/(modal)/car/[id]/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/car/[id]/index.tsx"); } },
  "./(app)/(root)/(modal)/car/create.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/car/create.tsx"); } },
  "./(app)/(root)/(modal)/car/picker/[id].tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/car/picker/[id].tsx"); } },
  "./(app)/(root)/(modal)/org/[id]/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/org/[id]/index.tsx"); } },
  "./(app)/(root)/(modal)/org/[id]/settings.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/org/[id]/settings.tsx"); } },
  "./(app)/(root)/(modal)/org/add.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/org/add.tsx"); } },
  "./(app)/(root)/(modal)/org/picker/[id].tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/org/picker/[id].tsx"); } },
  "./(app)/(root)/(modal)/orgMember/[id]/edit.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/orgMember/[id]/edit.tsx"); } },
  "./(app)/(root)/(modal)/orgMember/[id]/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/orgMember/[id]/index.tsx"); } },
  "./(app)/(root)/(modal)/orgMember/invite.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/orgMember/invite.tsx"); } },
  "./(app)/(root)/(modal)/orgMember/picker/[id].tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/orgMember/picker/[id].tsx"); } },
  "./(app)/(root)/(modal)/user/[id]/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/user/[id]/index.tsx"); } },
  "./(app)/(root)/(modal)/user/invite.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(modal)/user/invite.tsx"); } },
  "./(app)/(root)/(tabs)/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/_layout.tsx"); } },
  "./(app)/(root)/(tabs)/campaigns/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/campaigns/_layout.tsx"); } },
  "./(app)/(root)/(tabs)/campaigns/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/campaigns/index.tsx"); } },
  "./(app)/(root)/(tabs)/cars/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/cars/_layout.tsx"); } },
  "./(app)/(root)/(tabs)/cars/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/cars/index.tsx"); } },
  "./(app)/(root)/(tabs)/control/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/control/_layout.tsx"); } },
  "./(app)/(root)/(tabs)/control/ads.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/control/ads.tsx"); } },
  "./(app)/(root)/(tabs)/control/campaigns.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/control/campaigns.tsx"); } },
  "./(app)/(root)/(tabs)/control/cars.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/control/cars.tsx"); } },
  "./(app)/(root)/(tabs)/control/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/control/index.tsx"); } },
  "./(app)/(root)/(tabs)/control/orgs.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/control/orgs.tsx"); } },
  "./(app)/(root)/(tabs)/control/settings.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/control/settings.tsx"); } },
  "./(app)/(root)/(tabs)/control/users.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/control/users.tsx"); } },
  "./(app)/(root)/(tabs)/orgMembers/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/orgMembers/_layout.tsx"); } },
  "./(app)/(root)/(tabs)/orgMembers/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/orgMembers/index.tsx"); } },
  "./(app)/(root)/(tabs)/settings/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/settings/_layout.tsx"); } },
  "./(app)/(root)/(tabs)/settings/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/(tabs)/settings/index.tsx"); } },
  "./(app)/(root)/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/_layout.tsx"); } },
  "./(app)/(root)/profile/edit.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/profile/edit.tsx"); } },
  "./(app)/(root)/signUp.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/(root)/signUp.tsx"); } },
  "./(app)/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/_layout.tsx"); } },
  "./(app)/debug/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/debug/_layout.tsx"); } },
  "./(app)/debug/device.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/debug/device.tsx"); } },
  "./(app)/debug/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/debug/index.tsx"); } },
  "./(app)/report/[id].tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/report/[id].tsx"); } },
  "./(app)/signIn/_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/signIn/_layout.tsx"); } },
  "./(app)/signIn/code.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/signIn/code.tsx"); } },
  "./(app)/signIn/index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/(app)/signIn/index.tsx"); } },
  "./404.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/404.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/_layout.tsx"); } },
  "./index.tsx": { enumerable: true, get() { return require("/Users/tony/Dev/Projects/incarmedia.by/incarmedia-app/src/app/index.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;