import { Link } from "expo-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SectionListMenu, usePickerCallback } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getCarListItem } from "./getCarListItem";

export const CarList_QueryFragment = gql(`
  fragment CarList_QueryFragment on Query {
    cars(orgId: $orgId) {
      id
      currentAd {
        id
      }
      ...GetListItem_CarFragment
    }
  }
`);

export type CarListProps = {
  query?: FragmentType<typeof CarList_QueryFragment>;
  showOrgName?: boolean;
  picker?: boolean;
  orgId?: string;
};

export const CarList = (props: CarListProps) => {
  const query = getFragmentData(CarList_QueryFragment, props.query);
  const { t } = useTranslation("CarList");
  const onPick = usePickerCallback(props.picker);

  const sections = useMemo((): SectionListMenu[] => {
    const availableCars = query?.cars?.filter((car) => !car.currentAd);
    const busyCars = query?.cars?.filter((car) => car.currentAd);

    return [
      {
        key: "add",
        data: [
          {
            key: "add",
            buttonType: "primary",
            centerContent: true,
            title: t("add"),
            Link: (
              <Link
                href={{
                  pathname: "/car/create",
                  params: { orgId: props.orgId as string },
                }}
              />
            ),
          },
        ],
      },
      {
        key: "available",
        title: t("available"),
        data: availableCars?.map((car) =>
          getCarListItem({ car, showOrgName: props.showOrgName, onPick })
        ) || [getCarListItem()],
      },
      {
        key: "busy",
        visible: !!busyCars?.length,
        title: t("busy"),
        data: busyCars?.map((car) =>
          getCarListItem({ car, showOrgName: props.showOrgName })
        ) || [getCarListItem()],
      },
    ];
  }, [onPick, props.orgId, props.showOrgName, query?.cars, t]);

  return <SectionListMenu sections={sections} />;
};
