import { StyleSheet, Text, View, openPicker, useColors } from "../common";
import ChevronDown from "assets/icons/chevron-down.svg";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useWindowDimensions } from "react-native";
import { useMemo } from "react";
import { selectCurrentOrgId, setCurrentOrgId } from "store/settings";
import { useTranslation } from "react-i18next";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useSelector } from "react-redux";
import { OrgMemberInviteStatus } from "__generated__/graphql";
import { dispatch } from "store";

const OrgSwitcher_QueryFragment = gql(`
  fragment OrgSwitcher_QueryFragment on Query {
    user {
      id
      orgsMember {
        id
        jobTitle
        role
        inviteStatus
        org {
          id
          name
        }
      }
    }
  }
`);

type OrgSwitcherProps = {
  query?: FragmentType<typeof OrgSwitcher_QueryFragment>;
};

export const OrgSwitcher = (props: OrgSwitcherProps) => {
  const query = getFragmentData(OrgSwitcher_QueryFragment, props.query);
  const { t } = useTranslation("OrgSwitcher");
  const currentOrgId = useSelector(selectCurrentOrgId);

  const orgMember = useMemo(() => {
    return query?.user?.orgsMember.find(
      (member) =>
        member.org.id === currentOrgId &&
        member.inviteStatus === OrgMemberInviteStatus.Joined
    );
  }, [query?.user?.orgsMember, currentOrgId]);

  const dimensions = useWindowDimensions();
  const colors = useColors();

  const content = (
    <View>
      <View style={styles.row}>
        <Text
          style={styles.title}
          numberOfLines={1}
          colorName="primary"
          loading={!query}
          shimmerMinWidth={150}
        >
          {orgMember ? orgMember?.org.name : t("chooseOrg")}
        </Text>
        {query !== undefined && (
          <ChevronDown style={styles.arrow} color={colors.primary} />
        )}
      </View>
      <View>
        <Text
          style={styles.subtitle}
          numberOfLines={1}
          colorName="textSecondary"
          loading={!query}
          shimmerMaxWidth={100}
        >
          {orgMember?.jobTitle}
          {orgMember?.role && orgMember.role !== "member"
            ? ` (${t(`role.${orgMember.role}`)})`
            : ""}
        </Text>
      </View>
    </View>
  );

  if (!query) return content;

  return (
    <View style={[styles.container, { maxWidth: dimensions.width - 200 }]}>
      <TouchableOpacity
        onPress={() =>
          openPicker("/org/picker", { member: true }, (orgId) => {
            dispatch(setCurrentOrgId(orgId));
          })
        }
      >
        {content}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
  },
  title: {
    fontWeight: "600",
    fontSize: 16,
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  row: {
    alignItems: "center",
    gap: 8,
    alignSelf: "center",
    justifyContent: "center",
  },

  arrow: {
    position: "absolute",
    start: "100%",
    marginStart: 6,
  },
});
