import { SectionListMenu } from "components/common";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { UserHeader } from "./UserHeader";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { getCarListItem } from "components/Car/getCarListItem";

const User_QueryFragment = gql(`
  fragment User_QueryFragment on Query {
    user(id: $id) {
      id
      phone
      inviter {
        id
        firstName
        lastName
      }
      orgsMember {
        id
        org {
          ...GetListItem_OrgFragment
        }
        cars {
          ...GetListItem_CarFragment
        }
      }
      ...Header_UserFragment
    }
  }
`);

type UserProps = {
  query?: FragmentType<typeof User_QueryFragment>;
};

export const User = (props: UserProps) => {
  const query = getFragmentData(User_QueryFragment, props.query);

  const { t } = useTranslation("User");

  const cars = useMemo(() => {
    return query?.user?.orgsMember.map((member) => member.cars).flat();
  }, [query?.user?.orgsMember]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "info",
        data: [
          {
            key: "phone",
            label: t("phone"),
            title: formatIncompletePhoneNumber("+" + query?.user?.phone),
            loading: !query,
            shimmerMaxWidth: 145,
          },
          {
            key: "inviter",
            label: t("inviter"),
            visible: !!query?.user?.inviter,
            title: [
              query?.user?.inviter?.firstName,
              query?.user?.inviter?.lastName,
            ].join(" "),
            loading: !query,
            shimmerMaxWidth: 145,
          },
        ],
      },
      {
        key: "orgs",
        title: t("orgs", { count: query?.user?.orgsMember.length }),
        visible: !!query?.user?.orgsMember.length,
        data:
          query?.user?.orgsMember.map((orgMember) => ({
            ...getOrgListItem({ org: orgMember.org }),
          })) || [],
      },
      {
        key: "cars",
        title: t("cars", { count: cars?.length }),
        visible: !!cars?.length,
        data: cars?.map((car) => getCarListItem({ car })) || [],
      },
    ];
  }, [cars, query, t]);

  return (
    <SectionListMenu
      sections={sections}
      ListHeaderComponent={<UserHeader user={query?.user} />}
    />
  );
};
