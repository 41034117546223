import { StyleSheet, View, ViewProps } from "react-native";
import { useColors } from "../Themed";
import { Wrap } from "../Wrap";
import { SectionListMenuItemProps } from "./SectionListMenuItem";
import Animated, {
  FadeIn,
  FadeOut,
  LinearTransition,
} from "react-native-reanimated";

const defaultLeadingWidth = 38;

export const Separator = ({
  style,
  leadingItem,
  trailingItem,
  ...props
}: ViewProps & {
  leadingItem?: SectionListMenuItemProps;
  trailingItem?: SectionListMenuItemProps;
  highlighted?: boolean;
}) => {
  const colors = useColors();

  const withLeading = !!(
    leadingItem?.LeadingComponent ||
    leadingItem?.leadingIconColor ||
    leadingItem?.LeadingIcon ||
    trailingItem?.LeadingComponent ||
    trailingItem?.leadingIconColor ||
    trailingItem?.LeadingIcon
  );

  const editableWidth = leadingItem?.editable ? 32 : 0;
  const leadingWidth = Math.max(
    (leadingItem?.leadingWidth || defaultLeadingWidth) + editableWidth,
    (trailingItem?.leadingWidth || defaultLeadingWidth) + editableWidth
  );

  return (
    <Animated.View
      layout={LinearTransition}
      entering={FadeIn}
      exiting={FadeOut}
      {...props}
    >
      <Wrap style={{ backgroundColor: colors.card }}>
        <View
          style={[
            styles.separator,
            withLeading && { marginStart: leadingWidth + 16 * 2 },
            props.highlighted && { marginStart: 0 },
            { backgroundColor: colors.border },
            style,
          ]}
        />
      </Wrap>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  separator: {
    height: StyleSheet.hairlineWidth,
    marginStart: 16,
  },
});
