import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { OrgList } from "components/Org/OrgList";
import { useLocalSearchParams } from "expo-router";

const OrgPicker_Query = gql(`
  query OrgPicker_Query($hideCurrentOrgMemberRole: Boolean = false, $member: Boolean) {
    ...OrgList_QueryFragment
  }
`);

export default function Page() {
  const { member } = useLocalSearchParams<{ member?: string }>();

  const { data } = useQuery(OrgPicker_Query, {
    variables: {
      member: member === "true",
    },
  });

  return <OrgList query={data} picker />;
}
