import { StyleSheet, useWindowDimensions } from "react-native";
import Animated, {
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import { Main } from "./Main";
import { About } from "./About";
import { Benefits } from "./Benefits";
import { Pricing } from "./Pricing";
import { darkColor, primaryColor } from "./Constants";
import { Monitoring } from "./Monitoring";
import { Gallery } from "./Gallery";
import { Stats } from "./Stats";
import { HowWeWork } from "./HowWeWork";
import { Facts } from "./Facts";
import { Locations } from "./Locations";
import { Brands } from "./Brands";
import { Contacts } from "./Contacts";
import { Header } from "./Header";
import Head from "expo-router/head";

export const Home = () => {
  const dimensions = useWindowDimensions();
  const y = useSharedValue(0);
  const contentHeight = useSharedValue(dimensions.height);

  const onScroll = useAnimatedScrollHandler(
    (e) => {
      y.value = e.contentOffset.y;
      contentHeight.value = e.contentSize.height - dimensions.height;
    },
    [dimensions.height]
  );

  const progressStyle = useAnimatedStyle(() => {
    return {
      width: `${(y.value / contentHeight.value) * 100}%`,
    };
  }, []);

  return (
    <>
      <Animated.View style={[styles.progress, progressStyle]} />
      <Animated.ScrollView
        id="home-scroll"
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
        onScroll={onScroll}
      >
        <Head>
          <title>Реклама в такси - INCAR MEDIA</title>
          <meta
            name="description"
            content="Компания «Инкар Медиа» является лидером в сегменте рекламы в такси. За 5 лет работы мы накопили богатый опыт и заслужили безупречную репутацию."
          />
        </Head>
        <Header />
        <Main />
        <About />
        <Benefits />
        <Pricing />
        <Monitoring />
        <Gallery />
        <Stats />
        <HowWeWork />
        <Facts />
        <Locations />
        <Brands />
        <Contacts />
      </Animated.ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: darkColor,
  },
  contentContainer: {
    flexGrow: 1,
  },
  progress: {
    height: 4,
    backgroundColor: primaryColor,
    position: "absolute",
    width: "50%",
    left: 0,
    borderRadius: 2,
    zIndex: 1,
  },
});
