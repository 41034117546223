import { StyleSheet, Text, View } from "react-native";
import { Title } from "../common/Title";
import { Safe } from "../common/Safe";
import "./index.css";
import { darkColor } from "../Constants";
import LaptopImage from "./laptop.webp";
import { cssClass } from "utils/css";
import { Section } from "../common/Section";
import { Image } from "expo-image";

export const Monitoring = () => {
  return (
    <Section style={styles.container}>
      <Safe>
        <View style={[cssClass("monitoring-rows"), styles.rows]}>
          <View style={[cssClass("monitoring-row"), styles.row]}>
            <Title>Онлайн-мониторинг размещения</Title>
            <Text style={styles.description}>
              В режиме реального времени наблюдайте за ходом рекламной кампании
            </Text>
            <View style={styles.items}>
              {[
                "Отслеживайте пробег авто и трекинг по зонам",
                "Если машина в ремонте или ДТП, мы бесплатно заменим рекламу",
                "Медиаметрические показатели - GRP, OTS, Reach, частота контакта",
                "Гарантия размещения - к каждому авто прикреплено фото с размещенной рекламой",
              ].map((_, index) => (
                <View
                  key={index}
                  style={[cssClass("monitoring-item"), styles.item]}
                >
                  <View
                    style={[
                      cssClass("monitoring-item-container"),
                      styles.itemContainer,
                    ]}
                  >
                    <View style={styles.itemMarker} />
                    <Text style={styles.itemText}>{_}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View
            style={[cssClass("monitoring-row"), styles.row, styles.rightSide]}
          >
            <View
              style={[
                cssClass("monitoring-image-container"),
                styles.imageContainer,
              ]}
            >
              <Image
                style={styles.image}
                placeholder={LaptopImage}
                source={LaptopImage}
                alt="Ноутбук с открытым экраном, на котором отображается карта города Минска с метками на определенных точках"
              />
            </View>
          </View>
        </View>
      </Safe>
    </Section>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  rows: {
    flexDirection: "row",
    gap: 64,
  },
  row: {
    flex: 1,
    gap: 16,
  },
  description: {
    color: darkColor,
    fontSize: 20,
    fontWeight: "500",
  },

  rightSide: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  imageContainer: {
    width: "100%",
    maxWidth: 600,
    aspectRatio: 900 / 600,
    borderRadius: 8,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  items: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  item: {
    width: "50%",
  },
  itemContainer: {
    flexDirection: "row",
    marginVertical: 16,
    marginRight: 32,
  },
  itemMarker: {
    width: 6,
    height: 6,
    backgroundColor: "#ffc515",
    marginTop: 7,
    marginRight: 10,
  },
  itemText: {
    fontSize: 16,
  },
});
