import { forwardRef } from "react";
import { RectButton as _RectButton } from "react-native-gesture-handler";
import { ThemeProps } from "./types";
import { useColor } from "./useColor";

export type RectButtonProps = ThemeProps & _RectButton["props"];
export const RectButton = forwardRef<_RectButton, RectButtonProps>(
  function RectButton(props, ref) {
    const { style, ...rest } = props;
    const color = useColor(props);

    return (
      <_RectButton
        ref={ref}
        style={[{ backgroundColor: color }, style]}
        {...rest}
      />
    );
  }
);
