import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import {
  View,
  StyleSheet,
  Platform,
  Text,
  StyleProp,
  ViewStyle,
} from "react-native";
import Svg, { Circle } from "react-native-svg";
import Animated, {
  useSharedValue,
  useAnimatedProps,
  withTiming,
  Easing,
  useAnimatedReaction,
  interpolate,
  runOnJS,
} from "react-native-reanimated";

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

type TimerProgressBarProps = {
  style?: StyleProp<ViewStyle>;
  duration: number; // Продолжительность таймера в секундах
  size?: number;
  onComplete?: () => void; // Колбэк по окончании таймера
};

export type TimerProgressBarHandles = {
  start: () => void;
};

const TimerProgressBar = forwardRef<
  TimerProgressBarHandles,
  TimerProgressBarProps
>(function TimerProgressBar({ style, duration, size = 26, onComplete }, ref) {
  const radius = size / 2;
  const strokeWidth = 2;
  const [timeLeft, setTimeLeft] = useState(duration);
  const padding = strokeWidth / 2;
  const svgSize = (radius + padding) * 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = useSharedValue(0);

  const animatedProps = useAnimatedProps(() => ({
    strokeDashoffset: Platform.OS === "android" ? 0 : strokeDashoffset.value,
    strokeDasharray: circumference,
  }));

  useEffect(() => {
    strokeDashoffset.value = withTiming(circumference, {
      duration: duration * 1000,
      easing: Easing.linear,
    });
  }, [circumference, duration, strokeDashoffset]);

  useAnimatedReaction(
    () => {
      return Math.floor(
        interpolate(
          strokeDashoffset.value / circumference,
          [1, 0],
          [0, duration]
        ) + 0.5
      );
    },
    (currentValue, previousValue) => {
      if (currentValue !== previousValue) {
        runOnJS(setTimeLeft)(currentValue);
      }
    }
  );
  useAnimatedReaction(
    () => strokeDashoffset.value / circumference,
    (currentValue, previousValue) => {
      if (currentValue !== previousValue) {
        if (currentValue === 1 && onComplete) {
          runOnJS(onComplete)();
        }
      }
    }
  );

  useImperativeHandle(ref, () => ({
    start: () => {
      strokeDashoffset.value = 0;
    },
  }));

  const formatTime = (time: number) => {
    const seconds = time % 60;
    return seconds;
  };

  return (
    <View style={[styles.container, style]}>
      <Svg
        width={svgSize}
        height={svgSize}
        viewBox={`${0} ${0} ${size + strokeWidth} ${size + strokeWidth}`}
      >
        <Circle
          cx={radius + padding}
          cy={radius + padding}
          r={radius}
          fill="none"
          stroke="#fff1"
          strokeWidth={strokeWidth}
        />
        <AnimatedCircle
          animatedProps={animatedProps}
          cx={radius + padding}
          cy={radius + padding}
          r={radius}
          fill="none"
          stroke="white"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          transform={`translate(${
            2 * (radius + padding)
          }, 0) scale(-1, 1) rotate(-90, ${radius + padding}, ${
            radius + padding
          })`}
        />
      </Svg>
      <Text style={styles.timerText}>{formatTime(timeLeft)}</Text>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  timerText: {
    position: "absolute",
    fontSize: 16,
    color: "white",
    fontVariant: Platform.select({
      default: ["tabular-nums"],
      web: "tabular-nums" as unknown as [],
    }),
  },
});

export default TimerProgressBar;
