import { Stack, router, useNavigation } from "expo-router";
import { HeaderButton } from "./Button";
import { Platform, StyleSheet, View } from "react-native";
import { BaseButton, TouchableOpacity } from "react-native-gesture-handler";
import CloseLightIcon from "assets/icons/close-light.svg";
import CloseDarkIcon from "assets/icons/close-dark.svg";
import { ReactNode } from "react";
import { NativeStackNavigationOptions } from "@react-navigation/native-stack";
import { NavigationState, PartialState } from "@react-navigation/core";
import { useColors } from "./Themed";
import { t } from "i18next";
import "./StackModal.css";
import useColorScheme from "hooks/useColorScheme";
import { StatusBar } from "expo-status-bar";

export const modalOptions: NativeStackNavigationOptions = {
  headerShown: false,
  presentation: Platform.OS === "web" ? "transparentModal" : "formSheet",
  animation: "default",
};

export const CloseButton = () => {
  const colorScheme = useColorScheme();

  const Icon = colorScheme === "light" ? CloseLightIcon : CloseDarkIcon;

  return (
    <TouchableOpacity
      style={{
        opacity: 0.7,
        marginLeft: Platform.OS === "web" ? 16 : 0,
      }}
      onPress={() => router.back()}
    >
      <Icon width={30} height={30} />
    </TouchableOpacity>
  );
};

export const modalCloseOptions: NativeStackNavigationOptions = {
  headerLeft: () => <CloseButton />,
};

export const modalCancelOptions: NativeStackNavigationOptions = {
  headerLeft: () => (
    <HeaderButton
      style={{ marginLeft: Platform.OS === "web" ? -2 : -8 }}
      text={t("cancel")}
      onPress={() => router.back()}
    />
  ),
};

export const ModalContainer = ({ children }: { children?: ReactNode }) => {
  const colors = useColors();
  if (Platform.OS === "web")
    return (
      <View style={[{ $$css: true, _: "modal-container" }, styles.container]}>
        <View
          style={[
            { $$css: true, _: "modal-parent-screen" },
            styles.parentScreen,
          ]}
        >
          <View
            style={[styles.parentScreenCard, { backgroundColor: colors.card }]}
          />
        </View>
        <BaseButton style={styles.overlay} onPress={() => router.back()} />
        <View
          style={[
            { $$css: true, _: "modal" },
            styles.modal,
            {
              borderColor: colors.border,
              backgroundColor: colors.background,
            },
          ]}
        >
          {children}
        </View>
      </View>
    );

  return children;
};

export const StackModal = ({
  children,
  screenOptions,
}: {
  children?: ReactNode;
  screenOptions?: NativeStackNavigationOptions;
}) => {
  const navigation = useNavigation();

  const stack = (
    <Stack
      screenOptions={(props) => {
        // eslint-disable-next-line react/prop-types
        const isFirst = isFirstRoute(navigation.getState(), props.route.key);

        return {
          headerTitleAlign: "center",
          ...screenOptions,
          ...(isFirst ? modalCloseOptions : {}),
        };
      }}
    >
      {children}
    </Stack>
  );

  if (Platform.OS === "web") return <ModalContainer>{stack}</ModalContainer>;

  return (
    <>
      <StatusBar style="light" animated />
      {stack}
    </>
  );
};

function isFirstRoute(state: NavigationState, routeKey: string) {
  return findRoute(state, routeKey) ?? true;
}

function findRoute(
  state: NavigationState | PartialState<NavigationState>,
  routeKey: string
): boolean | null {
  for (const route of state.routes) {
    if (route.key === routeKey) {
      const index = state.routes.indexOf(route as never);
      return index === 0;
    }

    if (route.state) {
      const result = findRoute(route.state, routeKey);
      if (result !== null) return result;
    }
  }

  return null;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 64 + 8,
    paddingBottom: 49 + 8,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "#0002",
  },
  parentScreen: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "#000",
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingTop: 16,
    display: "none",
  },
  parentScreenCard: {
    height: 16,
    flex: 1,
    borderRadius: 10,
  },
  modal: {
    flex: 1,
    maxWidth: 600,
    width: "100%",
    borderRadius: 10,
    overflow: "hidden",
    borderWidth: StyleSheet.hairlineWidth,
    ...Platform.select({
      default: {
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: {
          width: 5,
          height: 5,
        },
        shadowOpacity: 1,
        shadowRadius: 20,
        elevation: 20,
      },
      web: {
        boxShadow: "0 5px 20px rgba(0, 0, 0, 0.25)",
      },
    }),
  },
});
