import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { SignUp } from "components/SignUp/SignUp";
import { HeaderButton } from "components/common";
import { Stack } from "expo-router";
import { useTranslation } from "react-i18next";
import { dispatch } from "store";
import { signOut } from "store/actions/signOut";

const SignUp_Query = gql(`
  query SignUp_Query {
    ...SignUp_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(SignUp_Query);
  const { t } = useTranslation();

  return (
    <>
      <Stack.Screen
        options={{
          headerLeft: () => (
            <HeaderButton
              text={t("logout")}
              onPress={() => {
                dispatch(signOut());
              }}
            />
          ),
        }}
      />
      <SignUp query={data} />
    </>
  );
}
