import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { OrgList } from "components/Org/OrgList";

const ControlOrgs_Query = gql(`
  query ControlOrgs_Query($hideCurrentOrgMemberRole: Boolean = false, $member: Boolean) {
    ...OrgList_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(ControlOrgs_Query);

  return <OrgList query={data} />;
}
