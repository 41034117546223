import {
  ActivityIndicator,
  HeaderButton,
  SectionListMenu,
  SectionListMenuItem,
  StyleSheet,
  UserContent,
  View,
} from "components/common";
import { Stack, router } from "expo-router";
import useActionSheet, { UseActionSheetValue } from "hooks/useActionSheet";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MinusCircleRedIcon from "assets/icons/minus-circle-red.svg";
import { TouchableOpacity } from "react-native-gesture-handler";
import { gql, getFragmentData, FragmentType } from "__generated__";
import { useMutation } from "@apollo/client";
import { OrgMemberPermission } from "__generated__/graphql";
import { RemoveCarDriver_Mutation } from "components/Car/mutations";
import { RemoveOrgMember_Mutation } from "./mutations";
import { CarPhoto } from "components/Car/CarPhoto";
import { getOrgListItem } from "components/Org/getOrgListItem";
import {
  getOrgMemberAdminFeaturesSection,
  permissionKeys,
} from "./getOrgMemberAdminFeaturesSection";
import { getUserListItem } from "components/User/getUserListItem";

type InputErrors = {
  jobTitle?: string;
};

const TransferOrg_Mutation = gql(`
  mutation TransferOrg_Mutation($orgId: ObjectID!, $memberId: ObjectID!) {
    trasferOrg(orgId: $orgId, memberId: $memberId) {
      id
      role
      permissions
    }
  }
`);

const SaveOrgMember_Mutation = gql(`
  mutation SaveOrgMember_Mutation(
    $id: ObjectID!
    $jobTitle: String!
    $permissions: [OrgMemberPermission!]!
  ) {
    saveOrgMember(id: $id, jobTitle: $jobTitle, permissions: $permissions) {
      id
      jobTitle
      permissions
    }
  }
`);

export const OrgMemberEdit_QueryFragment = gql(`
  fragment OrgMemberEdit_QueryFragment on Query {
    orgMember(id: $id) {
      id
      orgId
      inviteStatus
      role
      jobTitle
      permissions
      cars {
        id
        brand
        model
        year
        plate
        drivers {
          id
        }
      }
      user {
        id
        firstName
        lastName
        ...Photo_UserFragment
        ...GetListItem_UserFragment
      }
      org {
        id
        name
        member {
          id
          permissions
          role
        }
        ...GetListItem_OrgFragment
        ...GetAdminFeaturesSection_OrgMemberFragment
      }
    }
    user {
      id
    }
  }
`);

type OrgMemberEditProps = {
  query?: FragmentType<typeof OrgMemberEdit_QueryFragment>;
  invite?: boolean;
};

export default function OrgMemberEdit(props: OrgMemberEditProps) {
  const query = getFragmentData(OrgMemberEdit_QueryFragment, props.query);
  const { t } = useTranslation("OrgMemberEdit");
  const { t: g } = useTranslation();

  const [body, setBody] = useState<{
    jobTitle: string;
    permissions: OrgMemberPermission[];
  }>({
    jobTitle: query?.orgMember?.jobTitle || "",
    permissions:
      query?.orgMember?.permissions?.filter((_): boolean => _ !== undefined) ||
      [],
  });

  useEffect(() => {
    query?.orgMember?.id &&
      setBody({
        jobTitle: query?.orgMember?.jobTitle || "",
        permissions: query?.orgMember?.permissions || [],
      });
  }, [
    query?.orgMember?.id,
    query?.orgMember?.jobTitle,
    query?.orgMember?.permissions,
  ]);

  const jobTitleRef = useRef<SectionListMenuItem>(null);

  const [errors, setErrors] = useState<Partial<InputErrors>>({});
  useEffect(() => {
    errors.jobTitle && jobTitleRef.current?.shake();
  }, [errors.jobTitle]);

  const [saveMember, { loading, data }] = useMutation(SaveOrgMember_Mutation);
  const [removeMember, { loading: loadingRemove, data: removeData }] =
    useMutation(RemoveOrgMember_Mutation, {
      update(cache) {
        cache.evict({ id: `OrgMember:${query?.orgMember?.id}` });
      },
    });

  useEffect(() => {
    data && router.back();
  }, [data, removeData]);

  const submit = useCallback(() => {
    const errors: InputErrors = {};
    if (!body.jobTitle) errors.jobTitle = t("jobTitle.empty");

    setErrors(errors);

    if (Object.keys(errors).length) return;

    query?.orgMember &&
      saveMember({
        variables: {
          id: query.orgMember.id,
          jobTitle: body.jobTitle,
          permissions: body.permissions,
        },
      });
  }, [body.jobTitle, body.permissions, query?.orgMember, saveMember, t]);

  const removeActions = useMemo(
    (): UseActionSheetValue[] => [
      {
        text: t("removeFromOrg"),
        destructive: true,
        onPress: () => {
          removeMember({ variables: { id: query?.orgMember?.id || "" } });
        },
      },
    ],
    [query?.orgMember?.id, removeMember, t]
  );
  const showRemoveConfirm = useActionSheet(removeActions);

  const [transferOrg, { loading: transferLoading }] =
    useMutation(TransferOrg_Mutation);
  const transferActions = useMemo(
    (): UseActionSheetValue[] => [
      {
        text: t("transferConfirm.changeOwner"),
        destructive: true,
        onPress: () => {
          transferOrg({
            variables: {
              orgId: query?.orgMember?.org.id || "",
              memberId: query?.orgMember?.id || "",
            },
          });
        },
      },
    ],
    [query?.orgMember?.id, query?.orgMember?.org.id, t, transferOrg]
  );
  const showTransferConfirm = useActionSheet(transferActions, {
    title: t("transferConfirm.title"),
    message: t("transferConfirm.message", {
      orgName: query?.orgMember?.org.name,
      userName: `${query?.orgMember?.user?.firstName} ${query?.orgMember?.user?.lastName}`,
    }),
  });

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "user",
        visible: !props.invite,
        data: [
          getUserListItem({ user: query?.orgMember?.user }),
          getOrgListItem({ org: query?.orgMember?.org }),
        ],
      },
      {
        key: "jobTitleEdit",
        title: t("jobTitle.label"),
        error: errors.jobTitle,
        description: query
          ? !query.orgMember?.org.member?.permissions.includes(
              OrgMemberPermission.MembersManagement
            )
            ? t("jobTitle.noMembersManagementPermission")
            : query.orgMember?.role === "owner" &&
              query.orgMember.org.member?.role !== "owner"
            ? t("jobTitle.cantChangeOwner")
            : undefined
          : undefined,
        data: [
          {
            key: "jobTitle",
            ref: jobTitleRef,
            type: "textInput",
            loading: !query && !props.invite,
            disabled:
              !loading &&
              !(
                query?.orgMember?.org.member?.permissions.includes(
                  OrgMemberPermission.MembersManagement
                ) &&
                (query?.orgMember?.role !== "owner" ||
                  (query?.orgMember?.role === "owner" &&
                    query.orgMember.org.member?.role === "owner"))
              ),
            textInputProps: {
              value: body.jobTitle,
              onChangeText: (jobTitle) => {
                setErrors({ ...errors, jobTitle: undefined });
                setBody({ ...body, jobTitle });
              },
              editable:
                !loading &&
                query?.orgMember?.org.member?.permissions.includes(
                  OrgMemberPermission.MembersManagement
                ) &&
                (query?.orgMember.role !== "owner" ||
                  (query?.orgMember.role === "owner" &&
                    query.orgMember.org.member?.role === "owner")),
            },
          },
        ],
      },
      getOrgMemberAdminFeaturesSection({
        org: query?.orgMember?.org,
        edit: true,
        permissions: body.permissions,
        onChangePermissions(permissions) {
          setBody({ ...body, permissions });
        },
      }),
      {
        key: "cars",
        title: t("cars.title"),
        visible: !!query?.orgMember?.cars?.length,
        data:
          query?.orgMember?.cars?.map((_) => ({
            key: _?.id || "",
            LeadingComponent: (
              <CarItemLeading
                carId={_?.id || ""}
                memberId={query?.orgMember?.id || ""}
              />
            ),
            leadingWidth: 40 + 32,
            Content: (
              <UserContent
                title={[_?.brand, _?.model, _?.year].join(" ")}
                subtitle={_?.plate}
              />
            ),
            trailingText: t("cars.driversCount", {
              count: _?.drivers?.length,
            }),
          })) || [],
      },
      {
        key: "transferOrgOwnership",
        visible:
          !!query?.orgMember &&
          query.orgMember.org.member?.role === "owner" &&
          query?.orgMember.user.id !== query?.user?.id &&
          permissionKeys.every((key) => body.permissions.includes(key)),
        data: [
          {
            key: "transferOrgOwnership",
            title: t("transferOrgOwnership"),
            buttonType: "primary",
            centerContent: true,
            loading: transferLoading,
            onPress: showTransferConfirm,
          },
        ],
      },
      {
        key: "remove",
        visible: !!query?.orgMember?.id,
        description: !query?.orgMember?.org.member?.permissions.includes(
          OrgMemberPermission.MembersManagement
        )
          ? t("noPermissionToRemove")
          : query?.orgMember?.role === "owner"
          ? t("cantRemoveOwner")
          : undefined,
        data: [
          {
            key: "remove",
            buttonType: "destructive",
            title: t("removeFromOrg"),
            centerContent: true,
            disabled:
              !query?.orgMember?.org.member?.permissions.includes(
                OrgMemberPermission.MembersManagement
              ) || query?.orgMember?.role === "owner",
            loading: loadingRemove,
            onPress: showRemoveConfirm,
          },
        ],
      },
    ];
  }, [
    props.invite,
    query,
    t,
    errors,
    loading,
    body,
    transferLoading,
    showTransferConfirm,
    loadingRemove,
    showRemoveConfirm,
  ]);

  return (
    <>
      <Stack.Screen
        options={{
          headerRight: query?.orgMember?.id
            ? () => (
                <HeaderButton
                  text={g("done")}
                  bold
                  loading={loading}
                  onPress={submit}
                />
              )
            : undefined,
        }}
      />
      <SectionListMenu sections={sections} />
    </>
  );
}

type CarItemLeadingProps = {
  carId: string;
  memberId: string;
};
const CarItemLeading = ({ carId, memberId }: CarItemLeadingProps) => {
  const [removeCarDriver, { loading }] = useMutation(RemoveCarDriver_Mutation);

  return (
    <View style={styles.carItemLeading}>
      <View style={styles.carItemLeadingButton}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <TouchableOpacity
            onPress={() => {
              removeCarDriver({ variables: { carId, memberId } });
            }}
          >
            <MinusCircleRedIcon />
          </TouchableOpacity>
        )}
      </View>
      <CarPhoto />
    </View>
  );
};

const styles = StyleSheet.create({
  carItemLeading: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
  carItemLeadingButton: {
    width: 16,
    height: 16,
    justifyContent: "center",
    alignItems: "center",
  },
});
