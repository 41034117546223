import {
  Buttons,
  SectionListMenu,
  UserContent,
  useColors,
} from "components/common";
import { Link } from "expo-router";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ClockIcon from "assets/icons/clock.svg";
import XMarkCircleRedIcon from "assets/icons/xmark-circle-red.svg";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { useMutation } from "@apollo/client";
import { OrgMemberInviteStatus } from "__generated__/graphql";
import { CarPhoto } from "components/Car/CarPhoto";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { getOrgMemberAdminFeaturesSection } from "./getOrgMemberAdminFeaturesSection";
import { getUserListItem } from "components/User/getUserListItem";

export const OrgMember_QueryFragment = gql(`
  fragment OrgMember_QueryFragment on Query {
    orgMember(id: $id) {
      id
      orgId
      inviteStatus
      role
      jobTitle
      permissions
      cars {
        id
        brand
        model
        year
        plate
        drivers {
          id
        }
      }
      user {
        id
        phone
        ...Photo_UserFragment
        ...GetListItem_UserFragment
      }
      org {
        id
        ...GetListItem_OrgFragment
        ...GetAdminFeaturesSection_OrgMemberFragment
      }
    }
  }
`);

const ResendOrgMemberInvite_Mutation = gql(`
  mutation ResendOrgMemberInvite_Mutation($id: ObjectID!) {
    resendOrgMemberInvite(id: $id) {
      id
      inviteStatus
    }
  }
`);

export type OrgMemberProps = {
  query?: FragmentType<typeof OrgMember_QueryFragment>;
};

export const OrgMember = (props: OrgMemberProps) => {
  const query = getFragmentData(OrgMember_QueryFragment, props.query);
  const colors = useColors();

  const { t } = useTranslation("OrgMember");

  const [resendOrgMemberInvite, { data: resendData, loading }] = useMutation(
    ResendOrgMemberInvite_Mutation
  );

  const phone = useMemo(
    () => formatIncompletePhoneNumber("+" + query?.orgMember?.user?.phone),
    [query?.orgMember?.user?.phone]
  );

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "user",
        data: [
          getUserListItem({ user: query?.orgMember?.user }),
          getOrgListItem({ org: query?.orgMember?.org }),
        ],
      },
      {
        key: "invite",
        visible:
          !!query?.orgMember?.inviteStatus &&
          [
            OrgMemberInviteStatus.Pending,
            OrgMemberInviteStatus.Rejected,
          ].includes(query?.orgMember?.inviteStatus),
        data: [
          {
            key: "inviteStatus",
            loading: !query?.orgMember?.inviteStatus,
            shimmerMaxWidth: 80,
            Content:
              query?.orgMember?.inviteStatus &&
              query?.orgMember.inviteStatus !== "joined" ? (
                <>
                  <Buttons
                    style={{ gap: 8 }}
                    buttons={[
                      {
                        type: "flat",
                        Icon:
                          query?.orgMember.inviteStatus &&
                          {
                            pending: ClockIcon,
                            rejected: XMarkCircleRedIcon,
                          }[query?.orgMember.inviteStatus],
                        text:
                          query?.orgMember.inviteStatus &&
                          t(`inviteStatuse.${query?.orgMember.inviteStatus}`),
                        textColor: colors.text,
                        disabled: true,
                        style: { opacity: 1 },
                      },
                      ...(!resendData
                        ? ([
                            {
                              type: "semitransparent",
                              text: t("resendInvite"),
                              loading,
                              onPress: () => {
                                resendOrgMemberInvite({
                                  variables: { id: query?.orgMember?.id || "" },
                                });
                              },
                            },
                          ] as const)
                        : []),
                    ]}
                  />
                </>
              ) : undefined,
          },
        ],
      },
      {
        key: "info",
        data: [
          {
            key: "role",
            label: t("role"),
            title: t(`roles.${query?.orgMember?.role}`, { defaultValue: "" }),
            loading: !query?.orgMember?.role,
            shimmerMaxWidth: 60,
          },
          {
            key: "jobTitle",
            label: t("jobTitle"),
            title: query?.orgMember?.jobTitle,
            loading: !query?.orgMember?.jobTitle,
            shimmerMaxWidth: 80,
          },
          {
            key: "phone",
            label: t("phone"),
            title: phone,
            loading: !query?.orgMember?.user?.phone,
            shimmerMaxWidth: 80,
          },
        ],
      },
      getOrgMemberAdminFeaturesSection({
        org: query?.orgMember?.org,
        permissions: query?.orgMember?.permissions,
      }),
      {
        key: "cars",
        title: t("cars.title"),
        visible: !!query?.orgMember?.cars?.length,
        data:
          query?.orgMember?.cars?.map((_) => ({
            key: _?.id || "",
            Link: <Link href={`/car/${_?.id}/`} />,
            LeadingComponent: <CarPhoto />,
            leadingWidth: 40,
            Content: (
              <UserContent
                title={[_?.brand, _?.model, _?.year].join(" ")}
                subtitle={_?.plate}
              />
            ),
            trailingText: t("cars.driversCount", {
              count: _?.drivers?.length,
            }),
          })) || [],
      },
    ];
  }, [
    colors.text,
    loading,
    phone,
    query,
    resendData,
    resendOrgMemberInvite,
    t,
  ]);

  return <SectionListMenu sections={sections} />;
};
