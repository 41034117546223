import {
  Shimmer,
  StyleSheet,
  Upload,
  UploadProgressIndicator,
  View,
  useColors,
} from "./common";
import Animated, { ZoomIn, ZoomOut } from "react-native-reanimated";
import ClockIcon from "assets/icons/clock.svg";
import XMarkCircleRedIcon from "assets/icons/xmark-circle-red.svg";
import { Image } from "expo-image";
import { FC, useMemo } from "react";
import { cloudinary } from "utils/cloudinary";
import { Resize } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { SvgProps } from "react-native-svg";

const AssetFragment = gql(`
  fragment AssetFragment on Asset {
   public_id
   thumbhash
  }
`);

export type PhotoProps = {
  asset?: FragmentType<typeof AssetFragment> | null;
  size?: number;
  status?: "pending" | "rejected";
  loading?: boolean;
  upload?: Upload;
  Icon?: FC<SvgProps>;
};
export const Photo = ({
  size = 40,
  status,
  upload,
  loading,
  Icon,
  ...rest
}: PhotoProps) => {
  const colors = useColors();
  const asset = getFragmentData(AssetFragment, rest.asset);
  const extraIconSize = size * (16 / 40);

  const uri = useMemo(() => {
    if (!asset) return undefined;
    return cloudinary
      .image(asset?.public_id)
      .resize(Resize.auto(size * 2, size * 2).gravity(autoGravity()))
      .toURL();
  }, [asset, size]);

  return (
    <View
      style={[
        styles.container,
        { width: size, height: size, borderRadius: size / 2 },
      ]}
    >
      {loading ? (
        <Shimmer
          style={{
            width: size,
            height: size,
            borderRadius: size / 2,
          }}
        />
      ) : asset ? (
        <Image
          source={{ uri: upload?.asset.uri ?? uri }}
          placeholder={{
            thumbhash: asset.thumbhash,
          }}
          style={{
            width: size,
            height: size,
            borderRadius: size / 2,
          }}
          transition={300}
        />
      ) : (
        Icon && (
          <Icon
            style={styles.icon}
            width="50%"
            height="50%"
            color={colors.textSecondary}
          />
        )
      )}

      {status && (
        <Animated.View
          key={status}
          style={[
            styles.pendingIcon,
            {
              backgroundColor: colors.card + "80",
              borderColor: colors.card + "80",
            },
          ]}
          entering={ZoomIn}
          exiting={ZoomOut}
        >
          {status === "pending" && (
            <ClockIcon
              color={colors.text}
              width={extraIconSize}
              height={extraIconSize}
            />
          )}
          {status === "rejected" && <XMarkCircleRedIcon />}
        </Animated.View>
      )}
      <UploadProgressIndicator style={styles.progress} upload={upload} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    backgroundColor: "#8882",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: "50%",
    height: "50%",
    opacity: 0.6,
  },
  pendingIcon: {
    position: "absolute",
    borderRadius: 100,
    right: -3,
    bottom: -3,
    borderWidth: 2,
    flexDirection: "row",
  },
  progress: {
    position: "absolute",
  },
});
