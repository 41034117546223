import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectDevice } from "store/device";
import { usePushNotifications } from "./usePushNotifications";
import { omit } from "utils/omit";
import { selectLang } from "store/settings";
import Constants from "expo-constants";
import { gql } from "__generated__";
import { useMutation } from "@apollo/client";

const RegisterDevice_Mutation = gql(`
  mutation RegisterDevice_Mutation($data: DeviceInput!) {
    registerDevice(data: $data)
  }
`);

export const useRegisterDevice = () => {
  const [registerDevice] = useMutation(RegisterDevice_Mutation);
  const appLocale = useSelector(selectLang);

  usePushNotifications();

  const device = useSelector(selectDevice);

  useEffect(() => {
    if (!device.id) return;

    const data = omit(device, "_persist");

    registerDevice({
      variables: {
        data: {
          appVersion: Constants.expoConfig?.version || "unknown",
          appLocale,
          ...data,
        },
      },
    });
  }, [appLocale, device, registerDevice]);
};
