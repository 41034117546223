import axios, { AxiosProgressEvent } from "axios";
import { ImagePickerAsset } from "expo-image-picker";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { nanoid } from "nanoid";
import { Platform } from "react-native";
import { UploadApiResponse } from "cloudinary-react-native/lib/typescript/src/api/upload/model/params/upload-params";
import { toast } from "./Toast";
import { DocumentPickerAsset } from "expo-document-picker";

export type Upload = {
  id: string;
  asset: ImagePickerAsset | DocumentPickerAsset;
  abort?: () => void;
  progress?: AxiosProgressEvent;
  completed: boolean;
  remove: () => void;
};
type UploadsContextType = Map<string, Upload[]>;

export const UploadsContext = createContext<
  [UploadsContextType, Dispatch<SetStateAction<UploadsContextType>>]
>([
  new Map<string, Upload[]>(),
  () => {
    console.error("UploadsManagerProvider not provided");
  },
]);

interface UploadsManagerProviderProps {
  children?: ReactNode;
}
export const UploadsManagerProvider = ({
  children,
}: UploadsManagerProviderProps) => {
  const [value, setValue] = useState<UploadsContextType>(new Map());

  return (
    <UploadsContext.Provider value={[value, setValue]}>
      {children}
    </UploadsContext.Provider>
  );
};

export const useUploads = (key: string) => {
  const [ctx, setCtx] = useContext(UploadsContext);

  const uploads = useMemo(() => {
    return ctx.get(key) || [];
  }, [ctx, key]);

  const uploadAsset = useCallback(
    async (
      asset: ImagePickerAsset | DocumentPickerAsset,
      removeCompleted = true
    ) => {
      const id = nanoid();
      const controller = new AbortController();
      const upload: Upload = {
        id,
        asset,
        abort: () => controller.abort(),
        completed: false,
        remove: () => {
          setCtx((ctx) => {
            const uploads = ctx.get(key) || [];
            return new Map(
              ctx.set(
                key,
                uploads.filter((u) => u.id !== id)
              )
            );
          });
        },
      };
      setCtx((ctx) => {
        const uploads = ctx.get(key) || [];
        return new Map(ctx.set(key, [upload, ...uploads]));
      });

      const formData = new FormData();

      formData.append("timestamp", Date.now().toString());
      formData.append("upload_preset", "default");
      formData.append("api_key", "554561175349774");

      const name = "name" in asset ? asset.name : asset.fileName;
      if (Platform.OS === "web")
        formData.append("file", await assetToFile(asset));
      else
        formData.append("file", {
          uri: asset.uri,
          name: name || "file",
          type: asset.mimeType,
        } as unknown as File);

      const data = await axios
        .post<UploadApiResponse>(
          "https://api.cloudinary.com/v1_1/incarmedia/image/upload",
          formData,
          {
            signal: controller.signal,
            onUploadProgress: (progress) => {
              upload.progress = progress;
              setCtx((ctx) => new Map(ctx));
            },
          }
        )
        .then((response) => response.data)
        .catch((error: Error) => {
          error.message !== "canceled" && toast(error.message);
          upload.remove();
        })
        .finally(() => {
          delete upload.abort;
          setCtx((ctx) => new Map(ctx));
          removeCompleted && upload.remove();
        });

      upload.completed = true;
      setCtx((ctx) => new Map(ctx));

      return { data, upload };
    },
    [key, setCtx]
  );
  return [uploads, uploadAsset] as const;
};

export async function assetToFile(
  asset: ImagePickerAsset | DocumentPickerAsset
): Promise<File> {
  const res: Response = await fetch(asset.uri);
  const blob: Blob = await res.blob();
  const fileName = "name" in asset ? asset.name : asset.fileName;

  return new File([blob], fileName || "", {
    type: asset.mimeType,
  });
}
