import { Image, StyleSheet, Text, View } from "react-native";
import { Title } from "../common/Title";
import { Safe } from "../common/Safe";
import "./index.css";
import { darkColor, secondaryColor } from "../Constants";
import { Section } from "../common/Section";
import { cssClass } from "utils/css";
import BackgroundImage from "./background.webp";

const parseBoldText = (inputText: string) => {
  const boldTextRegex = /<b>(.*?)<\/b>/g;
  const parts = inputText.split(boldTextRegex);

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      return (
        <Text key={index} style={styles.bold}>
          {part}
        </Text>
      );
    } else {
      return part;
    }
  });
};

export const Facts = () => {
  return (
    <Section style={styles.container}>
      <View style={styles.backgroundContainer}>
        <Image style={styles.background} source={BackgroundImage} />
      </View>
      <Safe>
        <Title dark>5 фактов о рекламе в такси</Title>
        <View style={styles.items}>
          {[
            "<b>90 000 поездок</b> на такси совершается <b>ежедневно</b> в крупных городах Беларуси",
            "<b>3 000 км</b> проезжает такси <b>в неделю</b>, а это больше, чем городской транспорт",
            "<b>95%</b> участников дорожного движения <b>замечают рекламу</b> на транспорте",
            "<b>Качественные локации</b>: аэропорты, центр города, бизнес-центры, вокзалы, дворы домов",
            "<b>Желтый цвет выделяет</b> автомобиль такси среди потока машин",
          ].map((_, index) => (
            <View key={index} style={[cssClass("facts-item"), styles.item]}>
              <Text style={styles.itemStep}>#{index + 1}</Text>
              <Text style={styles.itemText}>{parseBoldText(_)}</Text>
            </View>
          ))}
        </View>
      </Safe>
    </Section>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: darkColor,
  },
  backgroundContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.1,
    overflow: "hidden",
  },
  background: {
    minWidth: "100%",
    minHeight: "100%",
  },
  items: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  item: {
    flex: 1,
  },
  itemStep: {
    color: "#ffc515",
    fontSize: 32,
    fontWeight: "500",
  },
  itemText: {
    color: secondaryColor,
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22,
    marginTop: 16,
    paddingEnd: 32,
  },
  bold: {
    fontWeight: "600",
    color: "#4daedc",
  },
});
