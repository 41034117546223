.app-main-button {
  transition: all 0.15s;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1) !important;
}
.app-main-button:not(.app-main-button-disabled):hover {
  transform: scale(1.05) translate3d(0px, 0px, 0px) perspective(1px) !important;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2) !important;
}

.app-main-button-disabled {
  cursor: default;
}
