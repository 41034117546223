import { FragmentType, getFragmentData, gql } from "__generated__";
import { Photo, PhotoProps } from "components/Photo";
import UserIcon from "assets/icons/user.svg";

const Photo_UserFragment = gql(`
  fragment Photo_UserFragment on User {
    photo {
      ...AssetFragment
    }
  }
`);

type UserPhotoProps = Omit<PhotoProps, "type"> & {
  user?: FragmentType<typeof Photo_UserFragment> | null;
};

export const UserPhoto = (props: UserPhotoProps) => {
  const user = getFragmentData(Photo_UserFragment, props.user);

  return (
    <Photo
      {...props}
      asset={user?.photo}
      Icon={UserIcon}
      loading={!props.user}
    />
  );
};
