import { SectionListMenu, SectionListMenuItem } from "components/common";
import { router } from "expo-router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Attachments } from "components/Attachments";
import { gql, getFragmentData, FragmentType } from "__generated__";
import { useMutation } from "@apollo/client";
import {
  Campaigns_QueryDocument,
  ControlAdCmpaigns_QueryDocument,
} from "__generated__/graphql";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { useCampaignDraft } from "store/drafts";

type InputErrors = {
  name?: string;
  regions?: string;
};

const AddCampaign_Mutation = gql(`
  mutation AddCampaign_Mutation($orgId: ObjectID!, $data: CampaignCreateInput!) {
    addCampaign(orgId: $orgId, data: $data) {
      id
      name
      periodDays
      carsCount
      regions
      comment
      attachments {
        public_id
        thumbhash
      }
    }
  }
`);

const CampaignCreate_QueryFragment = gql(`
  fragment CampaignCreate_QueryFragment on Query {
    org(id: $orgId) {
      id
      ...GetListItem_OrgFragment
    }
  }
`);

type CampaignEditProps = {
  query?: FragmentType<typeof CampaignCreate_QueryFragment>;
};

export const CampaignCreate = (props: CampaignEditProps) => {
  const query = getFragmentData(CampaignCreate_QueryFragment, props.query);

  const { t } = useTranslation("CampaignCreate");
  const { t: g } = useTranslation();

  const [addCampaign, { loading }] = useMutation(AddCampaign_Mutation, {
    refetchQueries: [Campaigns_QueryDocument, ControlAdCmpaigns_QueryDocument],
    onCompleted() {
      router.back();
      resetDraft();
    },
  });

  const [draft, setDraft, resetDraft] = useCampaignDraft();
  const [uploading, setUploading] = useState(false);

  const nameRef = useRef<SectionListMenuItem>(null);
  const periodDaysRef = useRef<SectionListMenuItem>(null);
  const carsCountRef = useRef<SectionListMenuItem>(null);
  const regionsRef = useRef<SectionListMenuItem>(null);

  const [errors, setErrors] = useState<InputErrors>({});
  useEffect(() => {
    errors.name && nameRef.current?.shake();
  }, [errors]);

  const submit = useCallback(() => {
    const errors: InputErrors = {};
    if (!draft.name?.length) errors.name = t("name.empty");
    if (!draft.regions?.length) errors.regions = t("regions.empty");

    setErrors(errors);

    if (Object.keys(errors).length) return;

    query?.org &&
      addCampaign({
        variables: {
          orgId: query.org.id,
          data: {
            name: draft.name,
            periodDays: Number(draft.periodDays),
            carsCount: Number(draft.carsCount),
            regions: draft.regions,
            comment: draft.comment,
            attachments: draft.attachments,
          },
        },
      });
  }, [
    addCampaign,
    draft.attachments,
    draft.carsCount,
    draft.comment,
    draft.name,
    draft.periodDays,
    draft.regions,
    query?.org,
    t,
  ]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "org",
        data: [
          getOrgListItem({
            key: "org",
            org: query?.org,
            picker: true,
            loading: !query,
            onOrgIdChange: (orgId) => setDraft({ orgId }),
          }),
        ],
      },
      {
        key: "name",
        title: t("name.title"),
        error: errors.name,
        data: [
          {
            ref: nameRef,
            key: "name",
            type: "textInput",
            textInputProps: {
              value: draft.name,
              onChangeText: (name) => {
                setErrors({ ...errors, name: undefined });
                setDraft({ name });
              },
            },
          },
        ],
      },
      {
        key: "periodDays",
        title: t("periodDays.title"),
        description: t("periodDays.description"),
        data: [
          {
            ref: periodDaysRef,
            key: "periodDays",
            type: "textInput",
            textInputProps: {
              value: String(draft.periodDays),
              onChangeText: (periodDays) => {
                setDraft({ periodDays });
              },
              onBlur: () => {
                let count = parseInt(draft.periodDays);
                if (count < 30 || isNaN(count)) count = 30;

                setDraft({ periodDays: String(count) });
              },
            },
          },
        ],
      },
      {
        key: "carsCount",
        title: t("carsCount.title"),
        description: t("carsCount.description"),
        data: [
          {
            ref: carsCountRef,
            key: "carsCount",
            type: "textInput",
            textInputProps: {
              value: draft.carsCount,
              onChangeText: (carsCount) => {
                setDraft({ carsCount });
              },
              onBlur: () => {
                let count = parseInt(draft.carsCount);
                if (count < 50 || isNaN(count)) count = 50;

                setDraft({ carsCount: String(count) });
              },
            },
          },
        ],
      },
      {
        key: "regions",
        title: t("regions.title"),
        description: t("regions.description"),
        error: errors.regions,
        data: [
          {
            ref: regionsRef,
            key: "regions",
            type: "textInput",
            textInputProps: {
              value: draft.regions.join(", "),
              onChangeText: (value) => {
                setErrors({ ...errors, regions: undefined });
                setDraft({ regions: value.split(", ").filter((_) => _) });
              },
            },
          },
        ],
      },
      {
        key: "layouts",
        title: t("layouts.title"),
        data: [
          {
            key: "layouts",
            Component: (
              <Attachments
                attachments={draft.attachments}
                onChange={(attachments) => setDraft({ attachments })}
                editable
                onUploadingStateChange={setUploading}
              />
            ),
          },
        ],
      },
      {
        key: "comment",
        title: t("comment.title"),
        data: [
          {
            key: "comment",
            type: "textInput",
            textInputProps: {
              value: draft.comment,
              onChangeText: (comment) => setDraft({ comment }),
              multiline: true,
            },
          },
        ],
      },
      {
        key: "add",
        data: [
          {
            key: "add",
            style: { marginTop: 16 },
            title: g(uploading ? "filesUploading" : "add"),
            buttonType: "primary",
            centerContent: true,
            loading,
            disabled: !query?.org || uploading,
            onPress: submit,
          },
        ],
      },
    ];
  }, [
    draft.attachments,
    draft.carsCount,
    draft.comment,
    draft.name,
    draft.periodDays,
    draft.regions,
    errors,
    g,
    loading,
    query,
    setDraft,
    submit,
    t,
    uploading,
  ]);

  return <SectionListMenu sections={sections} />;
};
