import { persistReducer } from "redux-persist";
import {
  createSelector,
  createSlice,
  DeepPartial,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "store";
import { storage } from "utils/storage";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";

type AdDraft = {
  orgId?: string;
  campaignId?: string;
  carId: string | undefined;
  attachments: {
    public_id: string;
    thumbhash?: string;
  }[];
};

type CampaignDraft = {
  orgId?: string;
  name: string;
  periodDays: string;
  carsCount: string;
  regions: string[];
  comment: string;
  attachments: {
    public_id: string;
    thumbhash?: string;
  }[];
};

type CarDraft = {
  orgId?: string;
  plate: string;
  brand: string;
  model: string;
  year: string;
  driverIds: string[];
};

type Drafts<T> = { [type: string]: T };

export type DraftsState = Readonly<{
  ad: Drafts<AdDraft>;
  campaign: Drafts<CampaignDraft>;
  car: Drafts<CarDraft>;
}>;
type DraftTypes = keyof DraftsState;
type DraftValue = DraftsState[DraftTypes][0];
type UnwrapDrafts<T> = T extends Drafts<infer U> ? U : never;
type DraftsInitial = {
  [K in keyof DraftsState]: UnwrapDrafts<DraftsState[K]>;
};

const initialState: DraftsState = {
  ad: {},
  campaign: {},
  car: {},
};

const initial: DraftsInitial = {
  ad: {
    carId: undefined,
    attachments: [],
  },
  campaign: {
    name: "",
    periodDays: "30",
    carsCount: "50",
    regions: [],
    comment: "",
    attachments: [],
  },
  car: {
    plate: "",
    brand: "",
    model: "",
    year: "",
    driverIds: [],
  },
};

export const drafts = createSlice({
  name: "drafts",
  initialState,
  reducers: {
    setDraft: (state, action: PayloadAction<DeepPartial<DraftsInitial>>) => {
      Object.entries(action.payload).forEach((_) => {
        const [type, drafts] = _ as [keyof DraftsState, DraftValue];

        Object.entries(drafts).forEach(([key, draft]) => {
          if (!state[type][key]) state[type][key] = initial[type];

          Object.assign(state[type][key], draft);
        });
      });
    },
  },
});

export const { setDraft } = drafts.actions;

const selectArg = <T extends DraftTypes>(
  state: RootState,
  arg: { type: T; key: string }
) => arg;
const selectDrafts = (state: RootState) => state.drafts;
const selectDraft = createSelector([selectDrafts, selectArg], (drafts, arg) => {
  return drafts[arg.type][arg.key] || initial[arg.type];
});

const useDraft = <T extends DraftTypes>(type: T, key?: string) => {
  type DraftType = DraftsState[typeof type][0];

  const _key = key || "";
  const draft = useSelector((state: RootState) =>
    selectDraft(state, { type, key: _key })
  ) as DraftType;
  const dispatch = useDispatch();

  const setDraft = useCallback(
    (draft: DeepPartial<DraftType>) => {
      dispatch(drafts.actions.setDraft({ [type]: { [_key]: draft } }));
    },
    [_key, dispatch, type]
  );

  const resetDraft = useCallback(() => {
    dispatch(
      drafts.actions.setDraft({
        [type]: {
          [_key]: initial[type],
        },
      })
    );
  }, [_key, dispatch, type]);

  return useMemo(() => {
    return [draft, setDraft, resetDraft] as const;
  }, [draft, resetDraft, setDraft]);
};

export const useAdDraft = (key?: string) => {
  return useDraft("ad", key);
};

export const useCampaignDraft = (key?: string) => {
  return useDraft("campaign", key);
};

export const useCarDraft = (key?: string) => {
  return useDraft("car", key);
};

export default {
  reducer: persistReducer({ key: "drafts", storage }, drafts.reducer),
};
