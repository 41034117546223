import { Link } from "expo-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SectionListMenu, usePickerCallback } from "components/common";
import UserAddIcon from "assets/icons/user-add.svg";
import sortArray from "sort-array";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { OrgMemberPermission } from "__generated__/graphql";
import { getOrgMemberListItem } from "./getOrgMemberListItem";
import { useSelector } from "react-redux";
import { selectCurrentOrgId } from "store/settings";

const OrgMemberList_QueryFragment = gql(`
  fragment OrgMemberList_QueryFragment on Query {
    orgMembers(orgId: $orgId) {
      id
      role
      orgId
      inviteStatus
      permissions
      ...GetListItem_OrgMemberFragment
    }
  }
`);

type OrgMemberListProps = {
  query?: FragmentType<typeof OrgMemberList_QueryFragment>;
  picker?: boolean;
  orgId?: string;
};

export const OrgMemberList = (props: OrgMemberListProps) => {
  const query = getFragmentData(OrgMemberList_QueryFragment, props.query);
  const onPick = usePickerCallback(props.picker);

  const { t: g } = useTranslation();
  const { t } = useTranslation("OrgMemberList");

  const currentOrgId = useSelector(selectCurrentOrgId);
  const orgMember = useMemo(() => {
    return query?.orgMembers?.find((member) => member.orgId === currentOrgId);
  }, [currentOrgId, query?.orgMembers]);

  const data = useMemo(() => {
    if (!query?.orgMembers) return;

    const sortedMembers = sortArray([...query.orgMembers], {
      by: ["inviteStatus", "role"],
      order: ["inviteStatus", "role"],
      customOrders: {
        role: ["owner", "admin", "member"],
        inviteStatus: ["rejected", "pending", "joined"],
      },
    });

    return [
      {
        key: "admins",
        data: sortedMembers.filter((_) => _.role !== "member"),
      },
      {
        key: "members",
        data: sortedMembers.filter((_) => _.role === "member"),
      },
    ] as const;
  }, [query?.orgMembers]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "inviteUser",
        visible: Boolean(
          orgMember?.inviteStatus === "joined" &&
            orgMember.permissions.includes(
              OrgMemberPermission.MembersManagement
            )
        ),
        data: [
          {
            key: "inviteUser",
            buttonType: "primary",
            title: g("invite"),
            Link: (
              <Link
                href={{
                  pathname: "/orgMember/invite/",
                  params: { orgId: props.orgId as string },
                }}
              />
            ),
            LeadingIcon: UserAddIcon,
          },
        ],
      },
      ...(data?.map(({ key, data }) => ({
        key,
        title: t(key),
        visible: data.length > 0,
        data: data.map((member) => getOrgMemberListItem({ member, onPick })),
      })) || [
        {
          key: "admins",
          title: t("admins"),
          data: [getOrgMemberListItem()],
        },
      ]),
    ];
  }, [
    data,
    g,
    onPick,
    orgMember?.inviteStatus,
    orgMember?.permissions,
    props.orgId,
    t,
  ]);

  return <SectionListMenu sections={sections} />;
};
