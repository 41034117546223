import {
  Theme as _Theme,
  DefaultTheme as _DefaultTheme,
  DarkTheme as _DarkTheme,
} from "@react-navigation/native";
import color from "color";

export type Theme = _Theme & {
  colors: _Theme["colors"] & {
    backgroundPrimary: string;
    textSecondary: string;
    destructive: string;
  };
};

export const primary = "#0279fe";
export const destructive = "#FE564B";

export const DefaultTheme: Theme = {
  dark: false,
  colors: {
    ..._DefaultTheme["colors"],
    primary,
    background: color(_DefaultTheme["colors"].background).hex(),
    card: color(_DefaultTheme["colors"].card).hex(),
    backgroundPrimary: _DefaultTheme["colors"].card,
    text: color(_DefaultTheme["colors"].text).hex(),
    border: color(_DefaultTheme["colors"].border).hex(),
    textSecondary: color(_DefaultTheme["colors"].text).alpha(0.6).hexa(),
    destructive,
  },
};

export const DarkTheme: Theme = {
  dark: false,
  colors: {
    ..._DarkTheme["colors"],
    primary,
    background: color(_DarkTheme["colors"].background).hex(),
    backgroundPrimary: _DarkTheme["colors"].background,
    card: "#1c1c1e",
    text: color(_DarkTheme["colors"].text).hex(),
    border: "#333335",
    textSecondary: color(_DarkTheme["colors"].text).alpha(0.6).hexa(),
    destructive,
  },
};

export const shadow = {
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 4.65,

  elevation: 8,
};

export default {
  light: DefaultTheme,
  dark: DarkTheme,
};
