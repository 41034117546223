import { StyleSheet, View } from "react-native";
import Icon from "assets/icons/icon-white.svg";
import { Link } from "react-scroll";
import { Link as RLink } from "expo-router";
import { secondaryColor } from "../Constants";
import { cssClass } from "utils/css";
import "./index.css";
import { Safe } from "../common/Safe";

export const Header = () => {
  return (
    <View style={styles.container}>
      <Safe style={styles.safe}>
        <Link
          style={styles.logoContainer}
          aria-label="Главная"
          href="#main"
          to="main"
          containerId="home-scroll"
          smooth={true}
        >
          <Icon width={24} height={32} />
        </Link>
        <View style={[cssClass("header-menu"), styles.menu]}>
          {[
            { id: "about", text: "О нас" },
            { id: "pricing", text: "Цены" },
            { id: "examples", text: "Примеры работ" },
            { id: "contacts", text: "Контакты" },
          ].map((_) => (
            <Link
              key={_.id}
              className="app-header-menu-item"
              style={styles.menuItem}
              href={"#" + _.id}
              to={_.id}
              containerId="home-scroll"
              smooth={true}
            >
              {_.text}
            </Link>
          ))}
        </View>
        <View style={styles.contacts}>
          <RLink style={styles.phone} href="tel:+375291200444">
            +375 (29) 1-200-444
          </RLink>
          <RLink style={styles.email} href="mailto:info@incarmedia.by">
            info@incarmedia.by
          </RLink>
        </View>
      </Safe>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
    paddingHorizontal: 32,
    position: "absolute",
    width: "100%",
  },
  safe: {
    paddingVertical: 16,
    pointerEvents: "box-none",
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    width: "100%",
    justifyContent: "space-between",
  },
  logoContainer: {
    display: "flex",
  },
  menu: {
    flexDirection: "row",
    gap: 8,
    flex: 1,
  },
  menuItem: {
    color: "white",
    textDecorationLine: "none",
    fontSize: 15,
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 11,
    paddingRight: 11,
    borderRadius: 6,
    textTransform: "uppercase",
  },
  contacts: {
    alignItems: "flex-end",
    gap: 4,
  },
  phone: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  email: {
    color: secondaryColor,
    fontSize: 16,
  },
});
