import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { OrgMember } from "components/OrgMember/OrgMember";
import { HeaderButton } from "components/common";
import { Stack, useLocalSearchParams } from "expo-router";
import { useTranslation } from "react-i18next";

export const OrgMember_Query = gql(`
  query OrgMember_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {
    orgMember(id: $id) {
      id
      role
      inviteStatus
    }
    ...OrgMember_QueryFragment
  }
`);

export default function Page() {
  const { id } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(OrgMember_Query, { variables: { id } });
  const { t: g } = useTranslation();

  return (
    <>
      <Stack.Screen
        options={{
          headerRight:
            data?.orgMember?.role !== "member" &&
            data?.orgMember?.inviteStatus === "joined"
              ? () => (
                  <HeaderButton
                    text={g("edit")}
                    href={`/orgMember/${id}/edit`}
                  />
                )
              : undefined,
        }}
      />
      <OrgMember query={data} />
    </>
  );
}
