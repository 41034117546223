@media screen and (max-width: 940px) {
  .app-benefits-item {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 580px) {
  .app-benefits-items {
    gap: 16px;
  }
  .app-benefits-item {
    width: 100%;
  }
}
