import { modalOptions } from "components/common";
import { Stack } from "expo-router";
import { useTranslation } from "react-i18next";

export const unstable_settings = {
  initialRouteName: "(root)",
};

export default function AppLayout() {
  const { t: g } = useTranslation();
  const { t } = useTranslation("pages");

  return (
    <Stack
      screenOptions={{
        headerShown: false,
        animation: "fade_from_bottom",
      }}
    >
      <Stack.Screen name="(root)" options={{ title: g("appName") }} />
      <Stack.Screen name="debug" options={modalOptions} />
      <Stack.Screen name="report/[id]" options={{ title: t("report.title") }} />
    </Stack>
  );
}
