import { useMemo } from "react";
import { SectionListMenu } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getAdListItem } from "./getAdListItem";
import { useTranslation } from "react-i18next";
import { Link } from "expo-router";

const AdList_QueryFragment = gql(`
  fragment AdList_QueryFragment on Query {
    ads {
      ...GetListItem_AdFragment
    }
  }
`);

type CampaignReportListProps = {
  query?: FragmentType<typeof AdList_QueryFragment>;
};

export const AdList = (props: CampaignReportListProps) => {
  const query = getFragmentData(AdList_QueryFragment, props.query);
  const { t } = useTranslation("AdList");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "create",
        data: [
          {
            key: "create",
            buttonType: "primary",
            centerContent: true,
            title: t("create"),
            Link: <Link href="/ad/create" />,
          },
        ],
      },
      {
        key: "ads",
        data: query?.ads?.map((ad) =>
          getAdListItem({
            ad,
            showOrgName: true,
            showCampaignName: true,
          })
        ) || [getAdListItem()],
      },
    ];
  }, [query?.ads, t]);

  return <SectionListMenu sections={sections} />;
};
