import {
  Fragment,
  ReactChild,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import Emittery from "emittery";
import {
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import Animated, {
  FadeOut,
  FlipInXDown,
  LinearTransition,
} from "react-native-reanimated";
import * as Haptics from "expo-haptics";

import { t } from "i18next";
import { FullWindowOverlay } from "react-native-screens";
import { BlurView } from "expo-blur";
import { Wrap } from "./Wrap";
import { Button } from "./Button";
import TimerProgressBar from "./TimerProgressBar";
import { ScrollView } from "react-native-gesture-handler";

const emitter = new Emittery();

interface ToastProps {
  text: string;
}

type CreatedToastProps = ToastProps & { id: string; timerId: number };

export const toast = (text: string) => {
  const id = Math.random().toString();
  emitter.emit("toast", { id, text, timerId: 0 });
};

export const inDevToast = () => toast(t("inDev"));

interface Props {
  children?: ReactChild;
}

const tabBarHeight = Platform.select({
  web: 49,
  default: 46,
});

export function ToastProvider({ children }: Props) {
  const dimensions = useWindowDimensions();
  const insets = useSafeAreaInsets();
  const [toasts, setToasts] = useState<CreatedToastProps[]>([]);
  const toastRef = useRef<CreatedToastProps[]>(toasts);

  const remove = useCallback((id: string) => {
    toastRef.current = toastRef.current.filter((_) => _.id !== id);
    setToasts([...toastRef.current]);
  }, []);

  useEffect(() => {
    const unsubscribe = emitter.on("toast", (props: CreatedToastProps) => {
      Platform.OS !== "web" && Haptics.notificationAsync();

      // eslint-disable-next-line react/prop-types
      const prevProps = toastRef.current.find((_) => _.text === props.text);
      if (prevProps) prevProps.timerId++;
      // eslint-disable-next-line react/prop-types
      toastRef.current = toastRef.current.filter((_) => _.text !== props.text);
      toastRef.current.push(prevProps || props);
      setToasts([...toastRef.current]);
    });
    return unsubscribe;
  }, [dimensions.height, remove, toasts]);

  const FullWindowWrap = Platform.OS === "web" ? Fragment : FullWindowOverlay;

  return (
    <>
      {children}
      <FullWindowWrap>
        <SafeAreaView
          style={[
            styles.safe,
            {
              top: insets.top,
              bottom: insets.bottom + tabBarHeight,
            },
          ]}
        >
          <KeyboardAvoidingView
            style={styles.keyboard}
            behavior={Platform.OS === "ios" ? "padding" : "height"}
          >
            <Wrap style={styles.container}>
              {toasts.map(({ id, text, timerId }) => {
                return (
                  <Animated.View
                    key={id}
                    layout={LinearTransition}
                    entering={FlipInXDown}
                    exiting={Platform.OS === "web" ? undefined : FadeOut}
                  >
                    <BlurView
                      style={styles.toastBlur}
                      intensity={100}
                      tint="dark"
                    >
                      <View style={styles.toast}>
                        <TimerProgressBar
                          key={timerId}
                          style={styles.toastLeading}
                          duration={5}
                          onComplete={() => remove(id)}
                        />
                        <ScrollView
                          contentContainerStyle={{ paddingVertical: 12 }}
                          showsVerticalScrollIndicator={false}
                          centerContent
                        >
                          <Text style={styles.toastText}>{text}</Text>
                        </ScrollView>
                        <Button
                          style={styles.button}
                          textColor="#85cff6"
                          type="flat"
                          text={t("hide")}
                          onPress={() => remove(id)}
                        />
                      </View>
                    </BlurView>
                  </Animated.View>
                );
              })}
            </Wrap>
          </KeyboardAvoidingView>
        </SafeAreaView>
      </FullWindowWrap>
    </>
  );
}

const styles = StyleSheet.create({
  safe: {
    ...StyleSheet.absoluteFillObject,
    pointerEvents: "box-none",
  },
  keyboard: {
    flex: 1,
    pointerEvents: "box-none",
  },
  container: {
    flex: 1,
    justifyContent: "flex-end",
    pointerEvents: "box-none",
    gap: 8,
    paddingVertical: 16,
  },
  toastBlur: {
    borderRadius: 12,
    paddingHorizontal: 12,
    overflow: "hidden",
    marginHorizontal: 16,
  },
  toast: {
    alignItems: "center",
    flexDirection: "row",
    maxHeight: 150,
    gap: 12,
  },
  toastLeading: {
    marginVertical: 12,
  },
  toastText: {
    color: "white",
    flex: 1,
    fontWeight: "600",
  },
  button: {
    height: 30,
    borderRadius: 4,
    paddingHorizontal: 6,
    marginEnd: -2,
    marginVertical: -2,
  },
});
