import { StyleSheet } from "react-native";
import { View } from "./Themed";
import { mobileMaxWidth } from "constants/Device";
import { ViewProps } from "react-native-svg/lib/typescript/fabric/utils";

export const Wrap = ({ style, ...rest }: ViewProps) => {
  return <View style={[styles.container, style]} {...rest} />;
};

const styles = StyleSheet.create({
  container: {
    maxWidth: mobileMaxWidth,
    alignSelf: "center",
    width: "100%",
  },
});
