import { useHeaderHeight } from "@react-navigation/elements";
import { forwardRef } from "react";
import {
  StyleSheet,
  KeyboardAvoidingViewProps,
  KeyboardAvoidingView as _KeyboardAvoidingView,
  Platform,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const KeyboardAvoidingView = forwardRef<
  _KeyboardAvoidingView,
  KeyboardAvoidingViewProps & { largeTitle?: boolean }
>(function KeyboardAvoidingView(props, ref) {
  const { style, largeTitle, ...rest } = props;

  const insets = useSafeAreaInsets();
  const headerHeight = useHeaderHeight();

  return (
    <_KeyboardAvoidingView
      ref={ref}
      style={[styles.container, style]}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={largeTitle ? 0 : headerHeight + -insets.bottom}
      {...rest}
    />
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
