import { StyleSheet, View, useWindowDimensions } from "react-native";
import { Title } from "../common/Title";
import { Safe } from "../common/Safe";
import { Section } from "../common/Section";

import { interpolate, useSharedValue } from "react-native-reanimated";
import Carousel from "react-native-reanimated-carousel";
import FonbetImage from "./images/fonbet.webp";
import A1Image from "./images/a1.webp";
import AlfaBankImage from "./images/alfa-bank.webp";
import BurgerKingImage from "./images/burger-king.webp";
import MtbankImage from "./images/mtbank.webp";
import MtsImage from "./images/mts.webp";
import RbImage from "./images/rb.webp";

import { withAnchorPoint } from "utils/anchor-point";
import { Image } from "expo-image";

const data = [
  {
    source: FonbetImage,
    alt: "Реклама мобильного оператора A1 с тарифным планом, включающим интернет и звонки, стоимостью 12.90 белорусских рублей.",
  },
  {
    source: A1Image,
    alt: "Реклама Альфа-Банка с предложением кредитов, оформление за 30 минут, контактная информация банка.",
  },
  {
    source: AlfaBankImage,
    alt: "Акционное предложение Burger King на биг кинг по цене 2.95 белорусских рублей.",
  },
  {
    source: BurgerKingImage,
    alt: "Реклама букмекерской компании Fonbet с бонусом за регистрацию и бонус-кодом для игры.",
  },
  {
    source: MtbankImage,
    alt: "Реклама МТБанка с предложением кредита и указанием ежемесячного платежа по кредиту.",
  },
  {
    source: MtsImage,
    alt: "Реклама МТС со скидками до 30% на гаджеты и информацией о подарках.",
  },
  {
    source: RbImage,
    alt: 'Реклама ресторана "Бровар" с предложением блюда брискет и адресом ресторана.',
  },
];

export const Gallery = () => {
  const dimensions = useWindowDimensions();
  const PAGE_WIDTH = Math.min(dimensions.width, 800) - 64 * 2;

  const progressValue = useSharedValue<number>(0);
  const baseOptions = {
    vertical: false,
    width: PAGE_WIDTH,
    height: PAGE_WIDTH * (392 / 662),
  } as const;

  return (
    <Section id="examples" style={styles.container}>
      <Safe style={styles.safe}>
        <Title>Примеры работ</Title>
      </Safe>
      <Carousel
        {...baseOptions}
        loop
        style={[
          styles.carousel,
          {
            height: PAGE_WIDTH * 0.9,
            width: dimensions.width,
          },
        ]}
        autoPlay
        autoPlayInterval={3000}
        scrollAnimationDuration={600}
        onProgressChange={(_, absoluteProgress) =>
          (progressValue.value = absoluteProgress)
        }
        customAnimation={(value: number) => {
          "worklet";
          const size = PAGE_WIDTH;
          const scale = interpolate(
            value,
            [-2, -1, 0, 1, 2],
            [1.7, 1.2, 1, 1.2, 1.7],
            "clamp"
          );

          const translate = interpolate(
            value,
            [-2, -1, 0, 1, 2],
            [-size * 1.45, -size * 0.9, 0, size * 0.9, size * 1.45]
          );

          const transform = {
            transform: [
              { scale },
              {
                translateX: translate,
              },
              { perspective: 1000 },
              {
                rotateY: `${interpolate(
                  value,
                  [-1, 0, 1],
                  [30, 0, -30],
                  "clamp"
                )}deg`,
              },
            ],
          };

          return {
            ...withAnchorPoint(
              transform,
              { x: 0.5, y: 0.5 },
              {
                width: baseOptions.width,
                height: baseOptions.height,
              }
            ),
          };
        }}
        data={data}
        renderItem={({ item }) => (
          <View
            style={{
              flex: 1,
              overflow: "hidden",
              borderWidth: 1,
              borderColor: "rgba(189, 189, 189, 0.37)",
              borderRadius: 10,
              aspectRatio: 662 / 392,

              shadowColor: "rgba(0, 0, 0, 0.2)",
              shadowOffset: {
                width: 0,
                height: 5,
              },
              shadowOpacity: 1,
              shadowRadius: 20,
              elevation: 20,
              pointerEvents: "none",
            }}
          >
            <Image
              style={{
                minWidth: "100%",
                minHeight: "100%",
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: 8,
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowOffset: {
                  width: 0,
                  height: 5,
                },
                shadowOpacity: 1,
                shadowRadius: 20,
              }}
              placeholder={item.source}
              {...item}
            />
          </View>
        )}
      />
    </Section>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    paddingHorizontal: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
  safe: {
    paddingHorizontal: 16,
    alignItems: "center",
  },
  carousel: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: -32,
    zIndex: -1,
  },
});
