import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useState } from "react";
import {
  LayoutChangeEvent,
  LayoutRectangle,
  StyleSheet,
  View,
  ViewProps,
} from "react-native";
import GradientShimmer, {
  ShimmerLayout as _ShimmerLayout,
  ShimmerLayoutPropsType,
} from "react-native-gradient-shimmer";
import useColorScheme from "hooks/useColorScheme";

export const Shimmer = ({
  style,
  backgroundColor,
  highlightColor,
}: ViewProps & { backgroundColor?: string; highlightColor?: string }) => {
  const [layout, setLayout] = useState<LayoutRectangle>();
  const colorScheme = useColorScheme();

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => setLayout(event.nativeEvent.layout),
    []
  );

  return (
    <View style={[styles.container, style]} onLayout={onLayout}>
      <GradientShimmer
        LinearGradientComponent={LinearGradient}
        backgroundColor={
          backgroundColor ||
          (colorScheme === "light" ? "rgb(230 230 230)" : "rgb(30 30 30)")
        }
        highlightColor={
          highlightColor ||
          (colorScheme === "light" ? "rgb(240 240 240)" : "rgb(40 40 40)")
        }
        height={layout?.height || 1}
        width={layout?.width || 1}
        style={StyleSheet.absoluteFill}
      />
    </View>
  );
};

export const ShimmerLayout = (
  props: Omit<
    ShimmerLayoutPropsType,
    | "LinearGradientComponent"
    | "backgroundColor"
    | "highlightColor"
    | "highlightWidth"
    | "duration"
    | "animating"
    | "easing"
  >
) => {
  return <_ShimmerLayout LinearGradientComponent={LinearGradient} {...props} />;
};

export type { ShimmerLayoutContainerType } from "react-native-gradient-shimmer";

const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
  },
});
