import { Stack } from "expo-router";
import { useTranslation } from "react-i18next";

export const unstable_settings = {
  initialRouteName: "index",
};

export default function Layout() {
  const { t } = useTranslation("pages");

  return (
    <Stack screenOptions={{ headerTitleAlign: "center" }}>
      <Stack.Screen name="index" options={{ title: t("cars.title") }} />
    </Stack>
  );
}
