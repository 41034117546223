import { Cloudinary } from "@cloudinary/url-gen";

export const cloudinary = new Cloudinary({
  cloud: {
    cloudName: "incarmedia",
    apiKey: "554561175349774",
    apiSecret: "P1Vgdm2wfGz4_SswwUbSqUht4qQ",
  },
  url: {
    secure: true,
  },
});
