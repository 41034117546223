import { selectTheme } from "store/settings";
import { useColorScheme as _useColorScheme } from "react-native";
import { useSelector } from "react-redux";

export default function useColorScheme() {
  const theme = useSelector(selectTheme);
  const systemTheme = _useColorScheme() || "dark";

  if (theme === "auto") return systemTheme;

  return theme;
}
