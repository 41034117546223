import AsyncStorage from "@react-native-async-storage/async-storage";
import { isServer } from "constants/Device";
import { Storage } from "redux-persist";

export const storage: Storage = isServer
  ? {
      getItem: async () => undefined,
      setItem: async () => undefined,
      removeItem: async () => undefined,
    }
  : AsyncStorage;
