import { ScrollView } from "react-native";
import {
  StyleSheet,
  View,
  TextInput,
  KeyboardAvoidingView,
  Text,
  useColors,
  Wrap,
  ActivityIndicator,
  toast,
} from "components/common";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { Redirect, router } from "expo-router";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import { selectSignIn, setAuth } from "store/auth";
import NewMessageIcon from "assets/icons/new-message.svg";
import { useSelector } from "react-redux";
import { selectDevice } from "store/device";
import { dispatch } from "store";
import { gql } from "__generated__";
import { useMutation } from "@apollo/client";

const SignIn_Mutation = gql(`
  mutation SignIn_Mutation($deviceId: String!, $phone: String!, $code: String!) {
    signIn(deviceId: $deviceId, phone: $phone, code: $code) {
      token
      user {
        id
        signUpRequired
      }
    }
  }
`);

export const SignInCode = () => {
  const data = useSelector(selectSignIn);
  const colors = useColors();
  const insets = useSafeAreaInsets();
  const { t } = useTranslation("SignInCode");
  const [code, setCode] = useState(data?.code ?? "");
  const [error, setError] = useState("");
  const [signIn, { loading }] = useMutation(SignIn_Mutation, {
    onCompleted(data) {
      dispatch(
        setAuth({
          token: data.signIn.token,
          signUpRequired: data.signIn.user.signUpRequired,
        })
      );
    },
    onError({ graphQLErrors }) {
      graphQLErrors?.forEach((error) => {
        const code = error.extensions.code as string;
        if (["OTP_NOT_FOUND", "OTP_TOO_MANY_REQUESTS"].includes(code)) {
          toast(t(`code.error.${code}`, { defaultValue: error.message }));
          router.back();
        } else
          setError(
            t(`code.error.${code}`, {
              defaultValue: error.message,
            })
          );
      });

      setCode("");
      setTimeout(() => inputRef.current?.focus());
    },
  });
  const device = useSelector(selectDevice);

  useEffect(() => {
    if (code.length !== data?.codeLength) return;

    signIn({ variables: { deviceId: device.id, phone: data.phone, code } });
  }, [data?.codeLength, code, signIn, t, data?.phone, device.id]);

  const formatedPhone = useMemo(() => {
    return formatIncompletePhoneNumber("+" + data?.phone);
  }, [data?.phone]);

  const inputRef = useRef<TextInput>(null);

  if (!data?.phone) return <Redirect href="/signIn/" />;

  if (loading && !code.length)
    return (
      <View
        style={[
          styles.container,
          { backgroundColor: colors.backgroundPrimary },
        ]}
      >
        <Wrap style={styles.wrap}>
          <View style={styles.header}>
            <View style={styles.header}>
              <NewMessageIcon style={styles.icon} color={colors.primary} />
              <Text style={styles.title}>{formatedPhone}</Text>
              <Text style={styles.description}>{t("checkingNumber")}</Text>
              <ActivityIndicator />
            </View>
          </View>
        </Wrap>
      </View>
    );

  return (
    <KeyboardAvoidingView style={{ backgroundColor: colors.backgroundPrimary }}>
      <ScrollView
        style={styles.container}
        contentContainerStyle={[
          styles.contentContainer,
          {
            paddingTop: 16,
            paddingBottom: insets.bottom + 16,
          },
        ]}
      >
        <Wrap style={styles.wrap}>
          <View style={styles.header}>
            <View>
              <NewMessageIcon style={styles.icon} color={colors.primary} />
              <Text style={styles.title}>{t("title")}</Text>
              <Text style={styles.description}>
                <Trans
                  t={t}
                  i18nKey="description"
                  components={{
                    b: <Text style={{ fontWeight: "600" }} />,
                  }}
                  values={{ phone: formatedPhone }}
                />
              </Text>
            </View>
            <TextInput
              ref={inputRef}
              placeholder={t("code.placeholder")}
              onChangeText={(code) => {
                setCode(code);
                setError("");
              }}
              value={code}
              error={error}
              keyboardType="number-pad"
              textContentType="oneTimeCode"
              autoComplete="one-time-code"
              maxLength={data.codeLength}
              editable={!loading}
              loading={loading}
              autoFocus
            />
          </View>
        </Wrap>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flexGrow: 1,
    padding: 16,
  },
  wrap: {
    flex: 1,
  },
  header: {
    flex: 1,
    justifyContent: "center",
  },
  icon: {
    alignSelf: "center",
    marginBottom: 16,
  },
  title: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: 28,
    marginBottom: 16,
  },
  description: {
    textAlign: "center",
    fontSize: 16,
    marginBottom: 32,
  },
  footer: {
    gap: 44,
  },
});
