@media screen and (max-width: 860px) {
  .app-how-we-work-item {
    width: calc(100% / 3);
  }
  .app-how-we-work-item:nth-child(-n + 3) {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 500px) {
  .app-how-we-work-item {
    width: calc(100% / 2);
  }
  .app-how-we-work-item:nth-child(-n + 4) {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 320px) {
  .app-how-we-work-item {
    width: 100%;
  }
  .app-how-we-work-item:nth-child(-n + 5) {
    margin-bottom: 32px;
  }
}
