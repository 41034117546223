import { shadow } from "constants/Theme";
import { useActionSheet as _useActionSheet } from "@expo/react-native-action-sheet";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";
import { useColors } from "components/common";
import useColorScheme from "./useColorScheme";

export interface UseActionSheetValue {
  text: string;
  destructive?: boolean;
  disabled?: boolean;
  onPress?: () => void;
}

interface Props {
  title?: string;
  message?: string;
}

export default function useActionSheet(
  values?: UseActionSheetValue[],
  props?: Props
) {
  const { showActionSheetWithOptions } = _useActionSheet();
  const { t } = useTranslation();
  const userInterfaceStyle = useColorScheme();
  const colors = useColors();

  const show = useCallback(
    (values2?: UseActionSheetValue[]) => {
      const items = values2 || values || [];
      setTimeout(() => {
        const options = items.map((_) => _.text).concat(t("cancel"));
        const destructiveButtonIndex = items.findIndex((_) => _.destructive);
        const disabledButtonIndices = items.reduce((indexes, _, index) => {
          if (_.disabled) return indexes.concat(index);
          return indexes;
        }, <number[]>[]);
        const cancelButtonIndex = items.length;

        showActionSheetWithOptions(
          {
            userInterfaceStyle,
            options,
            cancelButtonIndex,
            destructiveButtonIndex,
            disabledButtonIndices,
            containerStyle: {
              backgroundColor: colors.card,
              maxWidth: 300,
              width: "100%",
              marginBottom: 16,
              borderRadius: 14,
              alignSelf: "center",

              ...shadow,
            },
            textStyle: {
              color: colors.text,
              textAlign: "center",
              width: "100%",
            },
            titleTextStyle: {
              fontSize: 14,
              textAlign: "center",
              width: "100%",
              fontWeight: "bold",
              color: colors.text,
            },
            messageTextStyle: {
              color: colors.textSecondary,
              textAlign: "center",
              width: "100%",
            },
            separatorStyle: {
              backgroundColor: colors.border,
              height: StyleSheet.hairlineWidth,
            },
            showSeparators: true,
            ...props,
          },
          (buttonIndex) => {
            buttonIndex !== undefined && items[buttonIndex]?.onPress?.();
          }
        );
      });
    },
    [
      colors.border,
      colors.card,
      colors.text,
      colors.textSecondary,
      props,
      showActionSheetWithOptions,
      t,
      userInterfaceStyle,
      values,
    ]
  );
  return show;
}
