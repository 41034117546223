import { ActivityIndicator as _ActivityIndicator } from "react-native";
import { ThemeProps } from "./types";
import { useColor } from "./useColor";

export type ActivityIndicatorProps = ThemeProps & _ActivityIndicator["props"];
export function ActivityIndicator(props: ActivityIndicatorProps) {
  const { style, ...rest } = props;
  const color = useColor(props);

  return (
    <_ActivityIndicator
      style={style}
      color={color}
      {...rest}
      hitSlop={undefined}
    />
  );
}
