import { StyleSheet, TextProps, View } from "react-native";
import { darkColor, primaryColor } from "../../Constants";
import { CSSProperties } from "react";

export const Title = ({
  style,
  dark,
  ...rest
}: TextProps & { dark?: boolean }) => {
  return (
    <View style={styles.container}>
      <View style={styles.border} />
      <h2
        style={
          {
            ...styles.text,
            ...(dark ? { color: "white" } : {}),
            ...(typeof style === "object" ? style : {}),
          } as CSSProperties
        }
        {...rest}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingStart: 12,
    marginBottom: 32,
  },
  border: {
    backgroundColor: primaryColor,
    width: 5,
    position: "absolute",
    top: 7,
    bottom: 6,
    left: 0,
  },
  text: {
    fontSize: 30,
    fontWeight: "700",
    textTransform: "uppercase",
    color: darkColor,
    margin: 0,
  },
});
