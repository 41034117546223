import {
  Platform,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { Upload } from "./UploadsManager";
import XMarkIcon from "assets/icons/xmark.svg";
import { Circle, Svg } from "react-native-svg";
import Animated, {
  Easing,
  FadeIn,
  FadeOut,
  ZoomIn,
  ZoomOut,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import { useEffect } from "react";

const AnimatedSvg = Animated.createAnimatedComponent(Svg);
const AnimatedCircle = Animated.createAnimatedComponent(Circle);

type UploadProgressIndicatorProps = {
  style?: StyleProp<ViewStyle>;
  upload?: Upload;
  size?: number;
};
export const UploadProgressIndicator = ({
  style,
  upload,
  size = 50,
}: UploadProgressIndicatorProps) => {
  const strokeWidth = 3;
  const padding = 1;
  const radius = size / 2 - strokeWidth - padding;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = useSharedValue(circumference * 1.05);

  const animatedProps = useAnimatedProps(() => ({
    strokeDashoffset: Platform.OS === "android" ? 0 : strokeDashoffset.value,
    strokeDasharray: circumference,
  }));

  useEffect(() => {
    const progress = upload?.progress?.progress ?? 0;
    strokeDashoffset.value = withTiming(
      circumference * (1.05 + 0.9 * progress)
    );
  }, [circumference, strokeDashoffset, upload?.progress?.progress]);

  const rotate = useSharedValue(0);
  const svgAnimatedProps = useAnimatedProps(() => {
    return { rotation: rotate.value };
  }, []);
  const svgAnimatedStyle = useAnimatedStyle(() => {
    if (Platform.OS === "web") return {};
    return { transform: [{ rotate: `${rotate.value}deg` }] };
  }, []);

  useEffect(() => {
    rotate.value = withRepeat(
      withTiming(360, { duration: 1500, easing: Easing.linear }),
      -1
    );
  }, [rotate]);

  if (!upload) return null;

  return (
    <Animated.View
      style={[
        styles.container,
        { width: size, height: size, borderRadius: size / 2 },
        !!style && style,
      ]}
      entering={ZoomIn}
      exiting={ZoomOut}
    >
      <Animated.View
        style={styles.container2}
        entering={FadeIn}
        exiting={FadeOut}
      >
        <AnimatedSvg
          style={svgAnimatedStyle}
          animatedProps={svgAnimatedProps}
          width={size}
          height={size}
        >
          <AnimatedCircle
            animatedProps={animatedProps}
            cx={radius - strokeWidth - padding}
            cy={radius - strokeWidth - padding}
            r={radius}
            fill="none"
            stroke="white"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            transform={`translate(${
              2 * radius
            }, 0) scale(-1, 1) rotate(-90, ${radius}, ${radius})`}
          />
        </AnimatedSvg>
        {upload?.abort && (
          <Animated.View
            style={styles.cancel}
            entering={ZoomIn.duration(150)}
            exiting={ZoomOut.duration(150)}
          >
            <TouchableOpacity style={styles.cancel} onPress={upload.abort}>
              <XMarkIcon
                width={size * 0.36}
                height={size * 0.36}
                color="white"
              />
            </TouchableOpacity>
          </Animated.View>
        )}
      </Animated.View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 99,
    overflow: "hidden",
  },
  container2: {
    backgroundColor: "#0008",
  },
  cancel: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
});
