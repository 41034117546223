import {
  HeaderButton,
  SectionListMenu,
  SectionListMenuItem,
} from "components/common";
import { Stack, router } from "expo-router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Attachment, Attachments } from "components/Attachments";
import { gql, getFragmentData, FragmentType } from "__generated__";
import { useMutation } from "@apollo/client";
import { Asset } from "__generated__/graphql";
import { getOrgListItem } from "components/Org/getOrgListItem";

type InputErrors = {
  name?: string;
  regions?: string;
};

const SaveCampaign_Mutation = gql(`
  mutation SaveCampaign_Mutation($id: ObjectID!, $data: CampaignSaveInput!) {
    saveCampaign(id: $id, data: $data) {
      id
      name
      periodDays
      carsCount
      regions
      comment
      attachments {
        public_id
        thumbhash
      }
    }
  }
`);

export const CampaignEdit_QueryFragment = gql(`
  fragment CampaignEdit_QueryFragment on Query {
    campaign(id: $id) {
      id
      name
      carsCount
      periodDays
      regions
      comment
      attachments {
        public_id
        thumbhash
      }
      org {
        id
        name
        ...GetListItem_OrgFragment
      }
    }
    user {
      orgsMember {
        id
        org {
          id
          name
        }
      }
    }
  }
`);

export type CampaignEditProps = {
  query?: FragmentType<typeof CampaignEdit_QueryFragment>;
};

export const CampaignEdit = (props: CampaignEditProps) => {
  const query = getFragmentData(CampaignEdit_QueryFragment, props.query);

  const { t } = useTranslation("CampaignEdit");
  const { t: g } = useTranslation();

  const [saveCampaign, { loading }] = useMutation(SaveCampaign_Mutation, {
    onCompleted() {
      router.back();
    },
  });

  const [name, setName] = useState("");
  const [periodDays, setPeriodDays] = useState("");
  const [carsCount, setCarsCount] = useState("");
  const [regions, setRegions] = useState<string[]>([]);
  const [comment, setComment] = useState("");
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  useEffect(() => {
    query?.campaign?.name && setName(query.campaign.name);
    query?.campaign?.periodDays &&
      setPeriodDays(String(query.campaign.periodDays));
    query?.campaign?.carsCount &&
      setCarsCount(String(query.campaign.carsCount));
    query?.campaign?.regions && setRegions(query.campaign.regions);
    query?.campaign?.comment && setComment(query.campaign.comment);
    query?.campaign?.attachments && setAttachments(query.campaign.attachments);
  }, [
    query?.campaign?.name,
    query?.campaign?.periodDays,
    query?.campaign?.carsCount,
    query?.campaign?.regions,
    query?.campaign?.comment,
    query?.campaign?.attachments,
  ]);

  const nameRef = useRef<SectionListMenuItem>(null);
  const periodDaysRef = useRef<SectionListMenuItem>(null);
  const carsCountRef = useRef<SectionListMenuItem>(null);
  const regionsRef = useRef<SectionListMenuItem>(null);

  const [errors, setErrors] = useState<InputErrors>({});
  useEffect(() => {
    errors.name && nameRef.current?.shake();
  }, [errors]);

  const submit = useCallback(() => {
    const errors: InputErrors = {};
    if (!name.length) errors.name = t("name.empty");
    if (!regions.length) errors.regions = t("regions.empty");

    setErrors(errors);

    if (Object.keys(errors).length) return;

    query?.campaign &&
      saveCampaign({
        variables: {
          id: query.campaign.id,
          data: {
            name,
            periodDays: parseInt(periodDays),
            carsCount: parseInt(carsCount),
            regions,
            comment,
            attachments: attachments as Asset[],
          },
        },
      });
  }, [
    attachments,
    carsCount,
    comment,
    name,
    periodDays,
    query?.campaign,
    regions,
    saveCampaign,
    t,
  ]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "org",
        data: [getOrgListItem({ org: query?.campaign?.org })],
      },
      {
        key: "name",
        title: t("name.title"),
        error: errors.name,
        data: [
          {
            ref: nameRef,
            key: "name",
            type: "textInput",
            textInputProps: {
              value: name,
              onChangeText: (value) => {
                setErrors({ ...errors, name: undefined });
                setName(value);
              },
            },
            loading: !query,
          },
        ],
      },
      {
        key: "periodDays",
        title: t("periodDays.title"),
        description: t("periodDays.description"),
        data: [
          {
            ref: periodDaysRef,
            key: "periodDays",
            type: "textInput",
            textInputProps: {
              value: periodDays,
              onChangeText: (value) => {
                setPeriodDays(value);
              },
              onBlur: () => {
                let count = parseInt(periodDays);
                if (count < 30 || isNaN(count)) count = 30;

                setPeriodDays(String(count));
              },
            },
            loading: !query,
          },
        ],
      },
      {
        key: "carsCount",
        title: t("carsCount.title"),
        description: t("carsCount.description"),
        data: [
          {
            ref: carsCountRef,
            key: "carsCount",
            type: "textInput",
            textInputProps: {
              value: carsCount,
              onChangeText: (value) => {
                setCarsCount(value);
              },
              onBlur: () => {
                let count = parseInt(carsCount);
                if (count < 50 || isNaN(count)) count = 50;

                setCarsCount(String(count));
              },
            },
            loading: !query,
          },
        ],
      },
      {
        key: "regions",
        title: t("regions.title"),
        description: t("regions.description"),
        error: errors.regions,
        data: [
          {
            ref: regionsRef,
            key: "regions",
            type: "textInput",
            textInputProps: {
              value: regions.join(", "),
              onChangeText: (value) => {
                setErrors({ ...errors, regions: undefined });
                setRegions(value.split(", ").filter((_) => _));
              },
            },
            loading: !query,
          },
        ],
      },
      {
        key: "layouts",
        title: t("layouts.title"),
        data: [
          {
            key: "layouts",
            Component: (
              <Attachments
                attachments={attachments}
                onChange={setAttachments}
                editable
                loading={!query}
              />
            ),
          },
        ],
      },
      {
        key: "comment",
        title: t("comment.title"),
        data: [
          {
            key: "comment",
            type: "textInput",
            textInputProps: {
              value: comment,
              onChangeText: setComment,
              multiline: true,
            },
            loading: !query,
          },
        ],
      },
    ];
  }, [
    attachments,
    carsCount,
    comment,
    errors,
    name,
    periodDays,
    query,
    regions,
    t,
  ]);

  return (
    <>
      <Stack.Screen
        options={{
          headerRight: query
            ? () => (
                <HeaderButton
                  text={g("done")}
                  bold
                  loading={loading}
                  onPress={submit}
                />
              )
            : undefined,
        }}
      />
      <SectionListMenu sections={sections} />
    </>
  );
};
