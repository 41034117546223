import { FragmentType, getFragmentData, gql } from "__generated__";
import {
  Button,
  SectionListMenuItemProps,
  UserContent,
  openPicker,
} from "components/common";
import { Link } from "expo-router";
import { getFixedT } from "i18next";
import { CampaignPhoto } from "./CampaignPhoto";

const GetListItem_CampaignFragment = gql(`
  fragment GetListItem_CampaignFragment on Campaign {
    id
    name
    periodDays
    carsCount
    org {
        id
        name
    }
  }
`);

type GetCampaignListItemProps = {
  campaign?: FragmentType<typeof GetListItem_CampaignFragment> | null;
  showOrgName?: boolean;
  key?: string;
  loading?: boolean;
  picker?: boolean;
  onPick?: (campaignId: string) => void;
  onCampaignIdChange?: (campaignId: string) => void;
};

export const getCampaignListItem = (
  props?: GetCampaignListItemProps
): SectionListMenuItemProps => {
  const campaign = getFragmentData(
    GetListItem_CampaignFragment,
    props?.campaign
  );
  const g = getFixedT(null, "g");
  const t = getFixedT(null, "getCampaignListItem");

  const loading = props?.loading !== undefined ? props.loading : !campaign;
  let title = campaign?.name;
  let subtitle: (string | false | undefined)[] | string = [
    props?.showOrgName && campaign?.org.name,
    t("subtitle", {
      periodDays: campaign?.periodDays,
      carsCount: campaign?.carsCount,
    }),
  ];

  if (!loading && props?.picker && !campaign) {
    title = t("campaign");
    subtitle = g("notChoosen");
  }

  return {
    key: props?.key || campaign?.id || "campaign",
    Link: campaign ? <Link href={`/campaign/${campaign.id}/`} /> : undefined,
    LeadingComponent: <CampaignPhoto />,
    Content: (
      <UserContent title={title} subtitle={subtitle} loading={loading} />
    ),
    showTrailingArrow: !props?.picker && !props?.onPick,
    TrailingComponent:
      props?.picker || props?.onPick ? (
        <Button
          style={{ maxHeight: 36, borderRadius: 6 }}
          type="semitransparent"
          text={g("choose")}
          onPress={() => {
            if (props.onPick) props.onPick(campaign?.id || "");
            else
              props.onCampaignIdChange &&
                openPicker("/campaign/picker", props.onCampaignIdChange);
          }}
        />
      ) : undefined,
  };
};
