import {
  Button,
  SectionListMenu,
  SectionListMenuItem,
  toast,
  useColors,
} from "components/common";
import config from "config";
import { useMemo, useRef, useState } from "react";
import CheckmarkIcon from "assets/icons/checkmark.svg";
import MinusCircleIcon from "assets/icons/minus-circle.svg";
import { useSelector } from "react-redux";
import {
  selectApiUrl,
  selectCustomApiUrls,
  setApiUrl,
  setCustomApiUrls,
} from "store/settings";
import { Link, Stack } from "expo-router";
import { dispatch } from "store";
import { AppInfo } from "components/AppInfo";
import { gql } from "__generated__";
import { useMutation } from "@apollo/client";

const SendTestSms_Mutation = gql(`
  mutation SendTestSms_Mutation($flash: Boolean) {
    sendTestSms(flash: $flash)
  }
`);

const SendTestPush_Mutation = gql(`
  mutation SendTestPush_Mutation {
    sendTestPush
  }
`);

export default function Page() {
  const colors = useColors();
  const apiUrl = useSelector(selectApiUrl);
  const customApiUrls = useSelector(selectCustomApiUrls);
  const [showCustomUrlInput, setShowCustomUrlInput] = useState(false);
  const [customUrl, setCustomUrl] = useState(apiUrl);
  const urls = useMemo(
    () =>
      (
        [
          config.api.production,
          config.api.test,
          config.api.development,
          ...customApiUrls,
        ] as const
      ).filter((_) => typeof _ === "string") as string[],
    [customApiUrls]
  );

  const customRef = useRef<SectionListMenuItem>(null);

  const [sendTestPush, { loading: testPushLoading }] = useMutation(
    SendTestPush_Mutation
  );
  const [sendTestSms, { loading: testSmsLoading }] =
    useMutation(SendTestSms_Mutation);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "apiUrl",
        title: "API URL",
        error:
          showCustomUrlInput && urls.includes(customUrl)
            ? "URL already exists"
            : undefined,
        data: [
          ...urls.map((url) => ({
            key: url,
            title: url,
            TrailingComponent:
              apiUrl === url ? (
                <CheckmarkIcon color={colors.primary} />
              ) : customApiUrls.includes(url) ? (
                <Button
                  style={{ marginEnd: -16, backgroundColor: "transparent" }}
                  type="destructive"
                  Icon={MinusCircleIcon}
                  onPress={() => {
                    dispatch(
                      setCustomApiUrls(
                        customApiUrls.filter((item) => item !== url)
                      )
                    );
                  }}
                />
              ) : undefined,
            onPress: () => {
              dispatch(setApiUrl(url));
              setCustomUrl(url);
            },
          })),
          showCustomUrlInput
            ? {
                key: "customInput",
                type: "textInput",
                ref: customRef,
                textInputProps: {
                  autoFocus: true,
                  value: customUrl,
                  onChangeText: setCustomUrl,
                  onBlur: () => setShowCustomUrlInput(false),
                },
                TrailingComponent: (
                  <Button
                    style={{ marginRight: -16 }}
                    type="flat"
                    text="Add"
                    onPress={() => {
                      if (urls.includes(customUrl)) {
                        customRef.current?.shake();
                      } else {
                        setShowCustomUrlInput(false);
                        dispatch(
                          setCustomApiUrls([...customApiUrls, customUrl])
                        );
                      }
                    }}
                  />
                ),
              }
            : {
                key: "custom",
                buttonType: "primary",
                title: "Add URL",
                onPress: () => setShowCustomUrlInput(true),
              },
        ],
      },
      {
        key: "device",
        data: [
          {
            key: "device",
            title: "Device",
            Link: <Link href="/debug/device" />,
          },
        ],
      },
      {
        key: "testMessage",
        data: [
          {
            key: "testToast",
            title: "Send test toast",
            centerContent: true,
            buttonType: "primary",
            onPress: () => {
              toast("Test toast");
            },
          },
          {
            key: "testPush",
            title: "Send test push",
            centerContent: true,
            buttonType: "primary",
            loading: testPushLoading,
            onPress: () => {
              sendTestPush();
            },
          },
          {
            key: "testSms",
            title: "Send test SMS",
            centerContent: true,
            buttonType: "primary",
            loading: testSmsLoading,
            onPress: () => {
              sendTestSms();
            },
          },
          {
            key: "testFlashSms",
            title: "Send test Flash SMS",
            centerContent: true,
            buttonType: "primary",
            loading: testSmsLoading,
            onPress: () => {
              sendTestSms({ variables: { flash: true } });
            },
          },
        ],
      },
    ];
  }, [
    apiUrl,
    colors.primary,
    customApiUrls,
    customUrl,
    sendTestPush,
    sendTestSms,
    showCustomUrlInput,
    testPushLoading,
    testSmsLoading,
    urls,
  ]);

  return (
    <>
      <Stack.Screen options={{ title: "Debug" }} />
      <SectionListMenu
        sections={sections}
        ListFooterComponent={<AppInfo showBuildVersion showReleaseChannel />}
        keyboardShouldPersistTaps="always"
        keyboardDismissMode="on-drag"
      />
    </>
  );
}
