import { Pressable, ScrollView } from "react-native";
import {
  Button,
  StyleSheet,
  Text,
  View,
  TextInput,
  KeyboardAvoidingView,
  useColors,
  Wrap,
  PhoneInput,
} from "components/common";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useRef, useState } from "react";
import { Stack, router, useRouter } from "expo-router";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import Icon from "assets/icons/icon.svg";
import { dispatch } from "store";
import { setSignIn } from "store/auth";
import { createEventCounter } from "utils/eventCounter";
import { AppInfo } from "components/AppInfo";
import { useSelector } from "react-redux";
import { selectDevice } from "store/device";
import { gql } from "__generated__";
import { useMutation } from "@apollo/client";

const onLogoTap = createEventCounter(() => router.push("/debug/"));

const SendCode_Mutation = gql(`
  mutation SendCode_Mutation($deviceId: String!, $phone: String!) {
    sendCode(deviceId: $deviceId, phone: $phone) {
      type
      codeLength
      code
    }
  }
`);

export const SignIn = () => {
  const insets = useSafeAreaInsets();
  const colors = useColors();
  const { t: g } = useTranslation();
  const { t } = useTranslation("SignIn");
  const [phone, setPhone] = useState("");
  const router = useRouter();
  const [sendCode, { loading }] = useMutation(SendCode_Mutation, {
    onCompleted: ({ sendCode }) => {
      dispatch(
        setSignIn({
          phone,
          code: sendCode?.code || undefined,
          codeLength: sendCode.codeLength,
        })
      );
      router.push("/signIn/code");
    },
    onError: ({ graphQLErrors }) => {
      const haveError = graphQLErrors.some(
        (e) => e.extensions?.code === "ESME_RINVDSTADR"
      );

      if (haveError) {
        setError(t("error"));
        setTimeout(() => inputRef.current?.focus());
      }
    },
  });

  const disabled = useMemo(() => {
    return !isPossiblePhoneNumber("+" + phone);
  }, [phone]);

  const [error, setError] = useState("");
  const device = useSelector(selectDevice);
  const submit = useCallback(() => {
    setError("");

    sendCode({
      variables: {
        deviceId: device.id,
        phone,
      },
    });
  }, [device.id, phone, sendCode]);

  const inputRef = useRef<TextInput>(null);

  return (
    <KeyboardAvoidingView style={{ backgroundColor: colors.backgroundPrimary }}>
      <Stack.Screen options={{ title: t("title") }} />
      <ScrollView
        style={styles.container}
        contentContainerStyle={[
          styles.contentContainer,
          {
            paddingTop: insets.top + 16,
            paddingBottom: insets.bottom + 16,
          },
        ]}
      >
        <Wrap style={styles.wrap}>
          <Pressable style={styles.header} onPress={onLogoTap}>
            <Icon />
            <View style={styles.headerText}>
              <Text style={styles.appName}>
                <Text style={styles.bold}>INCAR</Text> MEDIA
              </Text>
              <AppInfo />
            </View>
          </Pressable>
          <View style={styles.footer}>
            <PhoneInput
              ref={inputRef}
              onChangeText={(phone) => {
                setError("");
                setPhone(phone);
              }}
              value={phone}
              onSubmitEditing={submit}
              editable={!loading}
              error={error}
              loading={loading}
            />
            <Button
              text={g("next")}
              onPress={submit}
              disabled={disabled || loading}
              dataSet={{ signInButton: true }}
            />
          </View>
        </Wrap>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flexGrow: 1,
    padding: 16,
  },
  wrap: {
    flex: 1,
  },
  header: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  headerText: {
    gap: 8,
  },
  appName: {
    marginTop: 16,
    fontSize: 22,
  },
  bold: {
    fontWeight: "600",
  },
  footer: {
    gap: 44,
  },
});
