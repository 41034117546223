import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import OrgMemberInvite from "components/OrgMember/OrgMemberInvite";
import { useSelector } from "react-redux";
import { selectCurrentOrgId } from "store/settings";

export const OrgMemberInvite_Query = gql(`
  query OrgMemberInvite_Query($orgId: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {
    ...OrgMemberInvite_QueryFragment
  }
`);

export default function Page() {
  const orgId = useSelector(selectCurrentOrgId);

  const { data } = useQuery(OrgMemberInvite_Query, {
    variables: { orgId: orgId! },
    skip: !orgId,
  });

  return <OrgMemberInvite query={data} />;
}
