import { Button, SectionListMenu, Switch } from "components/common";
import { useMemo } from "react";
import { AppInfo } from "components/AppInfo";
import { Alert, Platform } from "react-native";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useMutation } from "@apollo/client";
import { ControlSettings_MutationMutation } from "__generated__/graphql";
import { formatIncompletePhoneNumber } from "libphonenumber-js";

const ControlSettings_Mutation = gql(`
  mutation ControlSettings_Mutation($data: SettingsSaveInput!) {
    saveSettings(data: $data) {
      ...ControlSettings_Fragment
    }
  }
`);

const ControlSettings_Fragment = gql(`
  fragment ControlSettings_Fragment on AppSettings {
    id
    whitelistEnabled
    whitelist
  }
`);

const ControlSettings_QueryFragment = gql(`
  fragment ControlSettings_QueryFragment on Query {
    settings {
      ...ControlSettings_Fragment
    }
  }
`);

type ControlSettingsProps = {
  query?: FragmentType<typeof ControlSettings_QueryFragment>;
};

export const ControlSettings = (props: ControlSettingsProps) => {
  const query = getFragmentData(ControlSettings_QueryFragment, props.query);
  const settings = getFragmentData(ControlSettings_Fragment, query?.settings);

  const [save] = useMutation(ControlSettings_Mutation, {
    optimisticResponse(vars): ControlSettings_MutationMutation {
      return {
        saveSettings: { __typename: "AppSettings", ...settings, ...vars.data },
      };
    },
  });

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "whitelist",
        title: "Whitelist",
        description: settings
          ? settings?.whitelistEnabled
            ? "Only whitelisted phones can log in to the app"
            : "Everybody can log in to the app"
          : undefined,
        data: [
          {
            key: "whitelistEnabled",
            title: "Enabled",
            TrailingComponent: settings ? (
              <Switch
                value={settings?.whitelistEnabled}
                onValueChange={(whitelistEnabled) => {
                  save({ variables: { data: { whitelistEnabled } } });
                }}
              />
            ) : undefined,
            trailingLoading: !settings,
          },
          ...(settings?.whitelist.map((phone) => ({
            key: phone,
            title: formatIncompletePhoneNumber("+" + phone),
            TrailingComponent: (
              <Button
                style={{ maxHeight: 34, borderRadius: 8 }}
                type="destructive"
                text="Remove"
                onPress={() => {
                  save({
                    variables: {
                      data: {
                        whitelist: settings.whitelist.filter(
                          (item) => item !== phone
                        ),
                      },
                    },
                  });
                }}
              />
            ),
          })) || []),
          {
            key: "add",
            title: "Add phone number",
            buttonType: "primary",
            disabled: !settings,
            onPress: () => {
              if (!settings) return;
              if (Platform.OS === "web") {
                const phone = prompt("Enter phone number", "");
                if (phone)
                  save({
                    variables: {
                      data: { whitelist: [...settings.whitelist, phone] },
                    },
                  });
              } else
                Alert.prompt(
                  "Enter phone number",
                  undefined,
                  (phone) => {
                    save({
                      variables: {
                        data: { whitelist: [...settings.whitelist, phone] },
                      },
                    });
                  },
                  "plain-text",
                  "375",
                  "number-pad"
                );
            },
          },
        ],
      },
    ];
  }, [save, settings]);

  return (
    <SectionListMenu
      sections={sections}
      ListFooterComponent={<AppInfo showBuildVersion showReleaseChannel />}
      keyboardShouldPersistTaps="always"
      keyboardDismissMode="on-drag"
    />
  );
};
