import { createAsyncThunk } from "@reduxjs/toolkit";
import { authSignOut } from "store/auth";
import { appoloClient } from "utils/appolo";

export const signOut = createAsyncThunk(
  "auth/signOut",
  async (args, { dispatch }) => {
    dispatch(authSignOut());
    appoloClient.clearStore();
  }
);
