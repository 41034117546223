import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import { useColors } from "../Themed";
import { Wrap } from "../Wrap";
import Animated, {
  FadeIn,
  FadeOut,
  LinearTransition,
} from "react-native-reanimated";
import { RenderSectionFooterInfo } from ".";

export const SectionFooter = ({
  style,
  ...section
}: RenderSectionFooterInfo["section"] & { style?: StyleProp<ViewStyle> }) => {
  const colors = useColors();
  if (!section.description && !section.error) return null;

  return (
    <>
      {!!section.error && (
        <Wrap style={!!section.description && style} key="error">
          <Animated.Text
            style={styles.errorText}
            layout={LinearTransition}
            entering={FadeIn}
            exiting={FadeOut}
          >
            {section.error}
          </Animated.Text>
        </Wrap>
      )}
      {!!section.description && (
        <Wrap style={style} key={section.description}>
          <Animated.Text
            style={[styles.text, { color: colors.textSecondary }]}
            layout={LinearTransition}
            entering={FadeIn}
            exiting={FadeOut}
          >
            {section.description}
          </Animated.Text>
        </Wrap>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 13,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  errorText: {
    color: "#ea544b",
    fontSize: 13,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});
