import { FragmentType, gql, getFragmentData } from "__generated__";
import { Text, View, Wrap } from "components/common";
import { StyleSheet } from "react-native";
import { CarPhoto } from "./CarPhoto";

const Header_CarFragment = gql(`
  fragment Header_CarFragment on Car {
    id
    brand
    model
    plate
  }
`);

type CarHeaderProps = {
  car?: FragmentType<typeof Header_CarFragment> | null;
};

export const CarHeader = (props: CarHeaderProps) => {
  const car = getFragmentData(Header_CarFragment, props.car);

  const title = [car?.brand, car?.model].join(" ");

  return (
    <Wrap style={styles.header}>
      <CarPhoto size={100} />
      <View>
        <Text
          style={styles.headerTitle}
          shimmerMaxWidth={240}
          loading={!car?.brand || !car?.model}
        >
          {title}
        </Text>
        <Text
          style={styles.headerSubtitle}
          colorName="textSecondary"
          shimmerMaxWidth={120}
          loading={!car?.plate}
        >
          {car?.plate}
        </Text>
      </View>
    </Wrap>
  );
};

const styles = StyleSheet.create({
  header: {
    gap: 8,
    paddingTop: 8,
    paddingBottom: 16,
  },
  headerTitle: {
    fontSize: 28,
    textAlign: "center",
    fontWeight: "500",
  },
  headerSubtitle: {
    textAlign: "center",
    fontSize: 17,
  },
});
