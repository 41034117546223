import { Link } from "expo-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SectionListMenu, usePickerCallback } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getCampaignListItem } from "./getCampaignListItem";

const CampaignList_QueryFragment = gql(`
  fragment CampaignList_QueryFragment on Query {
    campaigns(orgId: $orgId) {
      ...GetListItem_CampaignFragment
    }
  }
`);

type CampaignListProps = {
  query?: FragmentType<typeof CampaignList_QueryFragment>;
  showOrgName?: boolean;
  picker?: boolean;
  orgId?: string;
};

export const CampaignList = (props: CampaignListProps) => {
  const query = getFragmentData(CampaignList_QueryFragment, props.query);
  const { t } = useTranslation("CampaignList");

  const onPick = usePickerCallback(props.picker);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "create",
        data: [
          {
            key: "create",
            buttonType: "primary",
            centerContent: true,
            title: t("create"),
            Link: (
              <Link
                href={{
                  pathname: "/campaign/create",
                  params: { orgId: props.orgId as string },
                }}
              />
            ),
          },
        ],
      },
      {
        key: "campaigns",
        data: query?.campaigns?.map((campaign) =>
          getCampaignListItem({
            campaign,
            showOrgName: props.showOrgName,
            onPick,
          })
        ) || [getCampaignListItem()],
      },
    ];
  }, [onPick, props.orgId, props.showOrgName, query?.campaigns, t]);

  return <SectionListMenu sections={sections} />;
};
