import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { ProfileEdit } from "components/Profile/ProfileEdit";
import { HeaderButton } from "components/common";
import { Stack } from "expo-router";
import { useTranslation } from "react-i18next";

const ProfileEdit_Query = gql(`
  query ProfileEdit_Query {
    ...ProfileEdit_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(ProfileEdit_Query);
  const { t } = useTranslation();

  return (
    <>
      <Stack.Screen
        options={{
          headerLeft: () => <HeaderButton text={t("cancel")} href=".." />,
        }}
      />
      <ProfileEdit query={data} />
    </>
  );
}
