@media (max-height: 600px) {
  .modal-container {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
@media (max-width: 650px) {
  .modal-container {
    padding-bottom: 0;
    padding-top: 24px;
  }
  .modal-parent-screen {
    display: flex;
  }
  .modal {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-width: 0;
    max-width: inherit;
  }
}
