import { FragmentType, gql, getFragmentData } from "__generated__";
import { OrgMemberInviteStatus } from "__generated__/graphql";
import { UserPhoto } from "components/User/UserPhoto";
import {
  Button,
  SectionListMenuItemProps,
  UserContent,
  openPicker,
} from "components/common";
import { Link } from "expo-router";
import { getFixedT } from "i18next";
import { formatIncompletePhoneNumber } from "libphonenumber-js";

const GetListItem_OrgMemberFragment = gql(`
  fragment GetListItem_OrgMemberFragment on OrgMember {
    id
    inviteStatus
    role
    jobTitle
    user {
        firstName
        lastName
        phone
        ...Photo_UserFragment
    }
    inviter {
        id
        firstName
    }
  }
`);

type OrgMemberListItemProps = {
  member?: FragmentType<typeof GetListItem_OrgMemberFragment> | null;
  editable?: boolean;
  removeMutation?: SectionListMenuItemProps["removeMutation"];
  key?: string;
  loading?: boolean;
  picker?: boolean;
  onPick?: (orgId: string) => void;
  onOrgMemberIdChange?: (orgId: string) => void;
};

export const getOrgMemberListItem = (
  props?: OrgMemberListItemProps
): SectionListMenuItemProps => {
  const member = getFragmentData(GetListItem_OrgMemberFragment, props?.member);
  const g = getFixedT(null, "g");
  const t = getFixedT(null, "getOrgMemberListItem");

  const loading = props?.loading !== undefined ? props.loading : !member;

  let title =
    [member?.user.firstName, member?.user.lastName]
      .filter((_) => _)
      .join(" ") || formatIncompletePhoneNumber("+" + member?.user.phone);
  let subtitle = member
    ? member?.inviter
      ? t("invitedBy", {
          name: member?.inviter.firstName,
        })
      : t(`role.${member.role}`)
    : undefined;

  if (!loading && props?.picker && !member) {
    title = t("member");
    subtitle = g("notChoosen");
  }

  return {
    key: member?.id || "",
    Link: member ? <Link href={`/orgMember/${member.id}/`} /> : undefined,
    LeadingComponent: (
      <UserPhoto
        status={
          member?.inviteStatus !== OrgMemberInviteStatus.Joined
            ? member?.inviteStatus
            : undefined
        }
        user={member?.user}
      />
    ),
    leadingWidth: 40,
    Content: (
      <UserContent title={title} subtitle={subtitle} loading={!member} />
    ),
    trailingText: props?.onOrgMemberIdChange ? member?.jobTitle : undefined,
    loadingTrailing: !member,
    shimmerTrailingMinWidth: 50,
    editable: props?.editable,
    removeMutation: props?.removeMutation,
    showTrailingArrow: !props?.picker && !props?.onPick,
    TrailingComponent:
      props?.picker || props?.onPick ? (
        <Button
          style={{ maxHeight: 36, borderRadius: 6 }}
          type="semitransparent"
          text={g("choose")}
          onPress={() => {
            if (props.onPick) props.onPick(member?.id || "");
            else
              props.onOrgMemberIdChange &&
                openPicker("/orgMember/picker", props.onOrgMemberIdChange);
          }}
        />
      ) : undefined,
  };
};
