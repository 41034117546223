import { FragmentType, getFragmentData, gql } from "__generated__";
import {
  Button,
  SectionListMenuItemProps,
  UserContent,
  openPicker,
} from "components/common";
import { Link } from "expo-router";
import { getFixedT } from "i18next";
import { OrgListItemFooter } from "./OrgListItemFooter";
import { OrgPhoto } from "./OrgPhoto";

const GetListItem_OrgFragment = gql(`
  fragment GetListItem_OrgFragment on Org {
    id
    name
    tin
    member @skip(if: $hideCurrentOrgMemberRole) {
        id
        jobTitle
        role
    }
    ...ListItemFooter_OrgFragment
  }
`);

type GetOrgListItemProps = {
  org?: FragmentType<typeof GetListItem_OrgFragment> | null;
  key?: string;
  loading?: boolean;
  picker?: boolean;
  onPick?: (orgId: string) => void;
  onOrgIdChange?: (orgId: string) => void;
};

export const getOrgListItem = (
  props?: GetOrgListItemProps
): SectionListMenuItemProps => {
  const org = getFragmentData(GetListItem_OrgFragment, props?.org);
  const g = getFixedT(null, "g");
  const t = getFixedT(null, "getOrgListItem");

  const loading = props?.loading !== undefined ? props.loading : !org;

  let title = org?.name;
  let subtitle =
    org?.member?.jobTitle && org.member.role
      ? org.member.jobTitle +
        (org.member.role !== "member"
          ? ` (${t(`role.${org.member.role}`)})`
          : "")
      : t("tin", { tin: org?.tin });

  if (!loading && props?.picker && !org) {
    title = t("org");
    subtitle = g("notChoosen");
  }

  return {
    key: props?.key || org?.id || "org",
    LeadingComponent: <OrgPhoto />,
    leadingWidth: 40,
    Link: org ? <Link href={`/org/${org.id}/`} /> : undefined,
    Content: (
      <UserContent title={title} subtitle={subtitle} loading={loading} />
    ),
    showTrailingArrow: !props?.picker && !props?.onPick,
    TrailingComponent:
      props?.picker || props?.onPick ? (
        <Button
          style={{ maxHeight: 36, borderRadius: 6 }}
          type="semitransparent"
          text={g("choose")}
          onPress={() => {
            if (props.onPick) props.onPick(org?.id || "");
            else
              props.onOrgIdChange &&
                openPicker("/org/picker", props.onOrgIdChange);
          }}
        />
      ) : undefined,
    Footer: <OrgListItemFooter org={org} />,
  };
};
