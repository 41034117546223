@media screen and (max-width: 1000px) {
  .app-facts-item {
    width: calc(100% / 3);
    flex: inherit;
  }
  .app-facts-item:nth-child(-n + 3) {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 700px) {
  .app-facts-item {
    width: calc(100% / 2);
  }
  .app-facts-item:nth-child(-n + 4) {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 500px) {
  .app-facts-item {
    width: 100%;
  }
}
