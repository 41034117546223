import { useMutation } from "@apollo/client";
import { gql } from "__generated__";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "store";
import {
  clearPendingSignOutToken,
  selectPendingSignOutToken,
} from "store/auth";

const SignOut_Mutation = gql(`
  mutation SignOut_Mutation($token: String!) {
    signOut(token: $token)
  }
`);

export const usePendingSignOut = () => {
  const token = useSelector(selectPendingSignOutToken);

  const [signOut] = useMutation(SignOut_Mutation, {
    onCompleted: () => dispatch(clearPendingSignOutToken()),
  });

  useEffect(() => {
    token && signOut({ variables: { token } });
  }, [signOut, token]);
};
