import {
  ForwardedRef,
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { ActivityIndicator, Text, useColors } from "../Themed";
import {
  DimensionValue,
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import ChevronRightIcon from "assets/icons/chevron-right.svg";
import { Wrap } from "../Wrap";
import { RectButton } from "react-native-gesture-handler";
import { SvgProps } from "react-native-svg";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSequence,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import * as Haptics from "expo-haptics";
import MinusCircleRedIcon from "assets/icons/minus-circle-red.svg";
import {
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";

export type SectionListMenuItem = {
  shake: () => void;
};
export type SectionListMenuItemProps = {
  key: string | number;
  ref?: ForwardedRef<SectionListMenuItem>;
  style?: StyleProp<ViewStyle>;
  visible?: boolean;
  type?: "item" | "textInput";
  label?: string;
  title?: string | number | undefined;
  buttonType?: "destructive" | "primary";
  leadingWidth?: number;
  LeadingComponent?: JSX.Element;
  leadingIconColor?: string;
  LeadingIcon?: React.FC<SvgProps>;
  Component?: JSX.Element;
  Content?: JSX.Element;
  centerContent?: boolean;
  trailingText?: string | number;
  trailingLoading?: boolean;
  Link?: JSX.Element;
  onPress?: () => void;
  textInputProps?: TextInputProps;
  loading?: boolean;
  disabled?: boolean;
  shimmerMinWidth?: DimensionValue;
  shimmerMaxWidth?: DimensionValue;
  loadingTrailing?: boolean;
  shimmerTrailingMinWidth?: DimensionValue;
  shimmerTrailingMaxWidth?: DimensionValue;
  TrailingComponent?: JSX.Element;
  Footer?: JSX.Element;
  editable?: boolean;
  removeMutation?: RemoveButtonProps;
  showTrailingArrow?: boolean;
};

export const SectionListMenuItem = forwardRef<
  SectionListMenuItem,
  SectionListMenuItemProps & {
    isFirst: boolean;
    isLast: boolean;
    onActiveStateChange: (active: boolean) => void;
  }
>(function SectionListMenuItem(
  {
    isFirst,
    isLast,
    type = "item",
    onActiveStateChange,
    LeadingComponent,
    leadingWidth = 22,
    Component,
    Content,
    centerContent,
    textInputProps: { style: textInputStyle, ...textInputProps } = {},
    loading,
    shimmerMinWidth,
    shimmerMaxWidth = 120,
    loadingTrailing,
    shimmerTrailingMinWidth,
    shimmerTrailingMaxWidth,
    trailingLoading,
    TrailingComponent,
    Footer,
    editable,
    removeMutation,
    showTrailingArrow,
    ...item
  },
  ref
) {
  const colors = useColors();

  const LeadingIcon = item.LeadingIcon;

  const color = useMemo(() => {
    switch (item.buttonType) {
      case "primary":
        return colors.primary;
      case "destructive":
        return "#fd3b30";
      default:
        return colors.text;
    }
  }, [colors.primary, colors.text, item.buttonType]);

  useImperativeHandle(ref, () => ({
    shake: () => {
      offset.value = withSequence(
        withTiming(2, { duration: 75 }),
        withTiming(-2, { duration: 75 }),
        withTiming(2, { duration: 75 }),
        withSpring(0, { damping: 50, stiffness: 500, mass: 2.8 })
      );

      Platform.OS !== "web" &&
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    },
  }));

  const offset = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: offset.value }],
      ...(Platform.OS === "web"
        ? {}
        : {
            borderTopStartRadius: withTiming(isFirst ? 12 : 0),
            borderTopEndRadius: withTiming(isFirst ? 12 : 0),
            borderBottomStartRadius: withTiming(isLast ? 12 : 0),
            borderBottomEndRadius: withTiming(isLast ? 12 : 0),
          }),
    };
  }, [isFirst, isLast]);

  const content = (
    <>
      <View style={[styles.contentContainer]}>
        {LeadingComponent ? (
          <View style={styles.leading}>{LeadingComponent}</View>
        ) : (
          (LeadingIcon || item.leadingIconColor) && (
            <View style={[styles.leading, { minWidth: leadingWidth + 16 }]}>
              <View
                style={[
                  styles.leadingIcon,
                  {
                    backgroundColor: item.leadingIconColor,
                  },
                ]}
              >
                {LeadingIcon && (
                  <LeadingIcon
                    width={item.leadingIconColor ? "71%" : "100%"}
                    height={item.leadingIconColor ? "71%" : "100%"}
                    color={item.leadingIconColor ? "white" : color}
                  />
                )}
              </View>
            </View>
          )
        )}
        <View
          style={[
            styles.content,
            centerContent && { alignItems: "center" },
            type === "textInput" && { paddingVertical: 0 },
          ]}
        >
          {Content ? (
            Content
          ) : type === "textInput" ? (
            <>
              {loading ? (
                <Text
                  loading
                  shimmerMinWidth={shimmerMinWidth}
                  shimmerMaxWidth={shimmerMaxWidth}
                />
              ) : (
                <TextInput
                  style={[
                    styles.textInput,
                    { color: colors.text },
                    textInputStyle,
                  ]}
                  placeholderTextColor={colors.text + "60"}
                  {...textInputProps}
                />
              )}
            </>
          ) : (
            <>
              {!!item.label && (
                <Text
                  style={styles.label}
                  colorName="textSecondary"
                  shimmerMaxWidth={120}
                >
                  {item.label}
                </Text>
              )}
              <Text
                style={[styles.title, { color }]}
                loading={loading}
                shimmerMaxWidth={shimmerMaxWidth}
                loadingIndicatorType={
                  item.buttonType ? "activityIndicator" : "shimmer"
                }
              >
                {item.title}
              </Text>
            </>
          )}
        </View>
        {TrailingComponent}
        {((!!item.Link && showTrailingArrow !== false) ||
          item.trailingText !== undefined ||
          trailingLoading) &&
          item.buttonType !== "primary" && (
            <View style={styles.trailing}>
              {trailingLoading ? (
                <ActivityIndicator />
              ) : (
                (item.trailingText !== undefined || loadingTrailing) && (
                  <Text
                    style={styles.trailingText}
                    colorName="textSecondary"
                    loading={loadingTrailing}
                    shimmerMinWidth={shimmerTrailingMinWidth}
                    shimmerMaxWidth={shimmerTrailingMaxWidth}
                  >
                    {item.trailingText}
                  </Text>
                )
              )}
              {!!item.Link && showTrailingArrow !== false && (
                <ChevronRightIcon
                  style={styles.trailingIcon}
                  color={colors.textSecondary}
                  height={14}
                />
              )}
            </View>
          )}
      </View>
      {Platform.OS !== "web" && Footer}
    </>
  );

  const buttonStyle = {
    ...styles.button,
    ...(editable ? { paddingStart: 32 } : {}),
  };

  return (
    <Wrap>
      {Component ? (
        <Animated.View style={[animatedStyle, item.style]}>
          {Component}
        </Animated.View>
      ) : (
        <Animated.View
          style={[
            styles.container,
            { backgroundColor: colors.card },
            animatedStyle,
            isFirst && styles.isFirst,
            isLast && styles.isLast,
            item.style,
          ]}
        >
          {editable && removeMutation && <RemoveButton {...removeMutation} />}
          {item.Link ? (
            cloneElement(item.Link, {
              asChild: true,
              preventDefault: true,
              children: (
                <RectButton
                  style={buttonStyle}
                  onActiveStateChange={onActiveStateChange}
                  underlayColor={colors.border}
                  rippleColor={colors.border}
                  activeOpacity={0.7}
                >
                  {content}
                </RectButton>
              ),
            })
          ) : item.onPress && !item.disabled && !loading ? (
            <RectButton
              style={buttonStyle}
              onPress={item.onPress}
              onActiveStateChange={onActiveStateChange}
              underlayColor={colors.border}
              rippleColor={colors.border}
              activeOpacity={0.7}
            >
              {content}
            </RectButton>
          ) : (
            <View style={[styles.button, item.disabled && styles.disabled]}>
              {content}
            </View>
          )}
          {Platform.OS === "web" && Footer}
        </Animated.View>
      )}
    </Wrap>
  );
});

type RemoveButtonProps<
  TData = DocumentNode,
  TVariables = OperationVariables
> = {
  doc: DocumentNode | TypedDocumentNode<TData, TVariables>;
  options: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>>;
};

const RemoveButton = (props: RemoveButtonProps) => {
  const [remove, { loading }] = useMutation(props.doc, props.options);

  return (
    <View style={styles.removeButton}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <TouchableOpacity
          onPress={() => {
            remove();
          }}
        >
          <MinusCircleRedIcon />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  isFirst: {
    borderTopStartRadius: 12,
    borderTopEndRadius: 12,
  },
  isLast: {
    borderBottomStartRadius: 12,
    borderBottomEndRadius: 12,
  },
  container: {
    overflow: "hidden",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    flex: 1,
  },
  contentContainer: {
    flexDirection: "row",
    paddingHorizontal: 16,
    minHeight: 44,
    alignItems: "center",
    gap: 16,
  },
  leading: {
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: 6,
  },
  leadingIcon: {
    width: 28,
    aspectRatio: 1,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    paddingVertical: 10,
    gap: 4,
    flex: 1,
  },
  label: {
    fontSize: 13,
  },
  title: {
    fontSize: 17,
  },
  trailing: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  trailingText: {
    fontSize: 16,
  },
  trailingIcon: {
    opacity: 0.7,
  },

  textInput: {
    fontSize: 18,
    minHeight: "100%",
    marginHorizontal: -16,
    paddingStart: 16,
    paddingTop: 11,
    paddingBottom: 11,
  },

  disabled: {
    opacity: 0.5,
  },

  removeButton: {
    position: "absolute",
    zIndex: 1,
    width: 16,
    height: 16,
    justifyContent: "center",
    alignItems: "center",
    left: 16,
  },
});
