import { Stack } from "expo-router";
import { useTranslation } from "react-i18next";

export const unstable_settings = {
  initialRouteName: "index",
};

export default function Layout() {
  const { t } = useTranslation("pages", { keyPrefix: "control" });

  return (
    <Stack screenOptions={{ headerTitleAlign: "center" }}>
      <Stack.Screen name="index" options={{ title: t("title") }} />
      <Stack.Screen
        name="campaigns"
        options={{ title: t("campaigns.title") }}
      />
      <Stack.Screen name="ads" options={{ title: t("ads.title") }} />
      <Stack.Screen name="cars" options={{ title: t("cars.title") }} />
      <Stack.Screen name="orgs" options={{ title: t("orgs.title") }} />
      <Stack.Screen name="users" options={{ title: t("users.title") }} />
      <Stack.Screen name="settings" options={{ title: t("settings.title") }} />
    </Stack>
  );
}
