:root {
  --color-primary: rgb(10, 132, 255);
}

[role="button"]:not([disabled]),
[role="link"] {
  cursor: pointer !important;
}

input,
textarea {
  outline: none;
}

[data-disabled="true"] {
  pointer-events: none;
  cursor: default;
}
