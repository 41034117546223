import { router } from "expo-router";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SectionListMenu, SectionListMenuItem } from "components/common";
import { gql } from "__generated__";
import { useMutation } from "@apollo/client";
import {
  formatIncompletePhoneNumber,
  isPossiblePhoneNumber,
  parseDigits,
} from "libphonenumber-js";
import { refetchQueries } from "utils/refetchQueries";

type InputErrors = {
  phone?: string;
};

const UserInvite_Mutation = gql(`
  mutation UserInvite_Mutation(
    $phone: String!
  ) {
    inviteUser(phone: $phone) {
      id,
      phone
    }
  }
`);

export const UserInvite = () => {
  const { t: g } = useTranslation();
  const { t } = useTranslation("UserInvite");

  const [inviteUser, { loading }] = useMutation(UserInvite_Mutation, {
    refetchQueries: refetchQueries.User,
    onCompleted() {
      router.back();
    },
  });

  const [phone, setPhone] = useState<string>("");
  const phoneRef = useRef<SectionListMenuItem>(null);
  const [errors, setErrors] = useState<Partial<InputErrors>>({});

  const submit = useCallback(() => {
    const errors: InputErrors = {};

    if (!phone) errors.phone = t("phone.empty");
    else if (!isPossiblePhoneNumber("+" + phone))
      errors.phone = t("phone.invalid");

    setErrors(errors);

    errors.phone && phoneRef.current?.shake();

    if (Object.keys(errors).length) return;

    inviteUser({ variables: { phone } });
  }, [inviteUser, phone, t]);

  const formatedPhone = useMemo(() => {
    return formatIncompletePhoneNumber(
      phone.length ? (/^\+/.test(phone) ? phone : "+" + phone) : ""
    );
  }, [phone]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "phone",
        title: t("phone.label"),
        error: errors.phone,
        data: [
          {
            key: "phone",
            ref: phoneRef,
            type: "textInput",
            textInputProps: {
              value: formatedPhone,
              onChangeText: (phone) => {
                setErrors({ ...errors, phone: undefined });
                setPhone(parseDigits(phone));
              },
              keyboardType: "phone-pad",
              textContentType: "telephoneNumber",
            },
          },
        ],
      },
      {
        key: "create",
        data: [
          {
            key: "create",
            title: g("invite"),
            buttonType: "primary",
            centerContent: true,
            loading,
            onPress: submit,
          },
        ],
      },
    ];
  }, [t, errors, formatedPhone, g, loading, submit]);

  return <SectionListMenu sections={sections} />;
};
