import { SectionListData, StyleSheet } from "react-native";
import { Text, useColors } from "../Themed";
import { SectionListMenuItemProps } from "./SectionListMenuItem";
import { SectionListMenu } from ".";
import { Wrap } from "../Wrap";
import Animated, { FadeIn, FadeOut } from "react-native-reanimated";

type Info = {
  section: SectionListData<SectionListMenuItemProps, SectionListMenu>;
};

export const renderSectionHeader = ({ section }: Info) => {
  return <SectionHeader {...section} />;
};

export const SectionHeader = (section: Info["section"]) => {
  const colors = useColors();
  if (!section.title) return null;

  return (
    <Animated.View
      style={[styles.container, { backgroundColor: colors.background }]}
      entering={FadeIn}
      exiting={FadeOut}
    >
      <Wrap>
        <Text style={styles.text} colorName="textSecondary">
          {section.title}
        </Text>
      </Wrap>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    // marginBottom: -8,
    marginHorizontal: -16,
  },
  text: {
    textTransform: "uppercase",
    fontSize: 13,
    paddingHorizontal: 16,
  },
});
