import { FragmentType, getFragmentData, gql } from "__generated__";
import {
  Button,
  SectionListMenuItemProps,
  UserContent,
  openPicker,
} from "components/common";
import { Link } from "expo-router";
import { getFixedT } from "i18next";
import { CarPhoto } from "./CarPhoto";
import moment from "moment";

const GetListItem_CarFragment = gql(`
  fragment GetListItem_CarFragment on Car {
    id
    brand
    model
    year
    plate
    org {
        id
        name
    }
    drivers {
        id
    }
    currentAd {
      id
      createdAt
      campaign {
        id
        periodDays
      
      }
    }
  }
`);

type GetCarListItemProps = {
  car?: FragmentType<typeof GetListItem_CarFragment> | null;
  showOrgName?: boolean;
  key?: string;
  loading?: boolean;
  picker?: boolean;
  onPick?: (carId: string) => void;
  onCarIdChange?: (carId: string) => void;
};

export const getCarListItem = (
  props?: GetCarListItemProps
): SectionListMenuItemProps => {
  const car = getFragmentData(GetListItem_CarFragment, props?.car);
  const g = getFixedT(null, "g");
  const t = getFixedT(null, "getCarListItem");

  const loading = props?.loading !== undefined ? props.loading : !car;

  const availability = t(car?.currentAd ? "busy" : "available", {
    expireAt: moment(car?.currentAd?.createdAt)
      .add(car?.currentAd?.campaign.periodDays, "days")
      .format("lll"),
  });

  let title = car?.plate;
  let subtitle: (string | false | undefined)[] | string = [
    props?.showOrgName && car?.org?.name,
    [car?.brand, car?.model, car?.year].join(" "),
    availability,
  ];

  if (!loading && props?.picker && !car) {
    title = t("car");
    subtitle = g("notChoosen");
  }

  return {
    key: props?.key || car?.id || "car",
    Link: car ? <Link href={`/car/${car.id}/`} /> : undefined,
    LeadingComponent: <CarPhoto />,
    leadingWidth: 40,
    Content: (
      <UserContent title={title} subtitle={subtitle} loading={loading} />
    ),
    shimmerTrailingMinWidth: 80,
    showTrailingArrow: !props?.picker && !props?.onPick,
    TrailingComponent:
      props?.picker || props?.onPick ? (
        <Button
          style={{ maxHeight: 36, borderRadius: 6 }}
          type="semitransparent"
          text={g("choose")}
          onPress={() => {
            if (props.onPick) props.onPick(car?.id || "");
            else
              props.onCarIdChange &&
                openPicker("/car/picker", props.onCarIdChange);
          }}
        />
      ) : undefined,
  };
};
