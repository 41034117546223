@media screen and (max-width: 940px) {
  .app-about-rows {
    flex-direction: column;
  }
  .app-about-row {
    flex: auto;
  }

  .app-about-description {
    max-width: inherit;
  }
}

@media screen and (max-width: 500px) {
  .app-about-item {
    flex-direction: column;
    align-items: flex-start;
  }
}
