import { StyleSheet, Text, View } from "react-native";
import { Title } from "../common/Title";
import { Safe } from "../common/Safe";
import "./index.css";
import { darkColor, primaryColor, secondaryColor } from "../Constants";
import { Section } from "../common/Section";
import { Link } from "expo-router";
import { TextInput } from "components/common";
import { BaseButton } from "../common/Button";
import { cssClass } from "utils/css";
import moment from "moment";
import { useCallback, useState } from "react";
import {
  formatIncompletePhoneNumber,
  isPossiblePhoneNumber,
} from "libphonenumber-js";
import { gql } from "__generated__";
import { useMutation } from "@apollo/client";

const SendContact_Mutation = gql(`
  mutation SendContact_Mutation($name: String!, $email: String!, $phone: String!) {
    sendContact(name: $name, email: $email, phone: $phone)
  }
`);

export const Contacts = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [errors, setErrors] = useState<{
    name?: string;
    email?: string;
    phone?: string;
  }>({
    name: undefined,
    email: undefined,
    phone: undefined,
  });

  const [sendContact, { loading }] = useMutation(SendContact_Mutation, {
    onCompleted() {
      setName("");
      setEmail("");
      setPhone("");
    },
  });

  const submit = useCallback(() => {
    const nameIsValid = name.length >= 2;
    const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const phoneIsPossible = isPossiblePhoneNumber(phone);
    const isValid = nameIsValid && emailIsValid && phoneIsPossible;

    setErrors({
      name: !nameIsValid ? "Имя должно быть больше 2-х символов" : undefined,
      email: !emailIsValid ? "Неверный формат email" : undefined,
      phone: !phoneIsPossible ? "Неверный формат телефона" : undefined,
    });

    if (!isValid) return;

    sendContact({ variables: { name, email, phone } });
  }, [email, name, phone, sendContact]);

  return (
    <>
      <Section id="contacts" style={styles.container}>
        <Safe>
          <Title dark>Наши контакты</Title>
          <View style={[cssClass("contacts-rows"), styles.rows]}>
            <View style={styles.leftSide}>
              <Text style={styles.subtitle}>Остались вопросы?</Text>
              <Text style={styles.description}>
                Свяжитесь с нами любым удобным для вас способом.
              </Text>
              <View style={styles.form}>
                <TextInput
                  style={styles.input}
                  primaryColor={primaryColor}
                  borderColor="#fff8"
                  backgroundColor={darkColor}
                  placeholderColor={secondaryColor}
                  placeholder="Ваше имя"
                  editable={!loading}
                  error={errors.name}
                  value={name}
                  onChangeText={(text) => {
                    setName(text);
                    setErrors({ ...errors, name: undefined });
                  }}
                  textContentType="name"
                  autoComplete="name"
                  importantForAutofill="yes"
                />
                <TextInput
                  style={styles.input}
                  primaryColor={primaryColor}
                  borderColor="#fff8"
                  backgroundColor={darkColor}
                  placeholderColor={secondaryColor}
                  placeholder="Email"
                  editable={!loading}
                  error={errors.email}
                  value={email}
                  onChangeText={(text) => {
                    setEmail(text);
                    setErrors({ ...errors, email: undefined });
                  }}
                  keyboardType="email-address"
                  textContentType="emailAddress"
                  autoComplete="email"
                  importantForAutofill="yes"
                />
                <TextInput
                  style={styles.input}
                  borderColor="#fff8"
                  primaryColor={primaryColor}
                  backgroundColor={darkColor}
                  placeholderColor={secondaryColor}
                  placeholder="Телефон"
                  editable={!loading}
                  error={errors.phone}
                  value={phone}
                  onChangeText={(text) => {
                    setErrors({ ...errors, phone: undefined });
                    const phone = text.length
                      ? /^\+/.test(text)
                        ? text
                        : "+" + text
                      : "";
                    setPhone(formatIncompletePhoneNumber(phone));
                  }}
                  keyboardType="phone-pad"
                  textContentType="telephoneNumber"
                  autoComplete="tel"
                  importantForAutofill="yes"
                />
                <BaseButton
                  style={styles.button}
                  onPress={submit}
                  loading={loading}
                >
                  Отправить
                </BaseButton>
              </View>
            </View>
            <View style={[cssClass("contacts-items"), styles.items]}>
              <Link
                style={[cssClass("contacts-phone"), styles.phone]}
                href="tel:+375291200444"
              >
                +375 (29) 1-200-444
              </Link>
              <Link
                style={[cssClass("contacts-email"), styles.email]}
                href="mailto:info@incarmedia.by"
              >
                info@incarmedia.by
              </Link>
              <Link
                style={styles.address}
                href="https://yandex.by/profile/113302454716"
                target="_blank"
              >
                г. Минск, ул. Тимирязева 65, офис 117
              </Link>
            </View>
          </View>
        </Safe>
      </Section>
      <Text style={styles.copyright}>
        © {moment().format("YYYY")} «Инкармедиа»{"\n"}Все права защищены
      </Text>
      <Link style={styles.blogLink} href="https://incarmedia.by/blog">
        Блог
      </Link>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: darkColor,
    paddingBottom: 32,
  },
  rows: {
    flexDirection: "row",
  },
  leftSide: {
    maxWidth: 300,
  },
  subtitle: {
    color: "white",
    fontSize: 18,
  },
  description: {
    color: secondaryColor,
    fontSize: 15,
    fontWeight: "300",
  },
  form: {
    marginTop: 24,
    gap: 24,
  },
  input: {
    color: "white",
  },
  button: {
    alignSelf: "flex-start",
  },
  items: {
    flex: 1,
    alignItems: "flex-end",
    gap: 16,
  },
  phone: {
    color: primaryColor,
    fontSize: 50,
    fontWeight: "500",
    letterSpacing: -2,
  },
  email: {
    color: secondaryColor,
    fontSize: 30,
  },
  address: {
    color: secondaryColor,
  },
  copyright: {
    textAlign: "center",
    paddingVertical: 32,
    paddingHorizontal: 16,
    color: "white",
    opacity: 0.8,
  },
  blogLink: {
    color: "white",
    alignSelf: "center",
    opacity: 0.8,
    marginBottom: 32,
    marginTop: -16,
  },
});
