.app-contacts-rows {
  --color-primary: #ffc515;
}

@media screen and (max-width: 860px) {
  .app-contacts-phone {
    font-size: 34px;
  }
}

@media screen and (max-width: 700px) {
  .app-contacts-rows {
    flex-direction: column-reverse;
    gap: 64px;
  }
  .app-contacts-items {
    align-items: flex-start;
    gap: 8px;
  }
  .app-contacts-email {
    font-size: 22px;
  }
}
