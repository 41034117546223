import { View as _View } from "react-native";
import { forwardRef } from "react";
import { ThemeProps } from "./types";
import { useColor } from "./useColor";

export type ViewProps = ThemeProps & _View["props"] & { dir?: "rtl" | "ltr" };
export const View = forwardRef<_View, ViewProps>(function View(props, ref) {
  const { style, ...rest } = props;
  const backgroundColor = useColor(props);

  return <_View ref={ref} style={[{ backgroundColor }, style]} {...rest} />;
});
