import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { CampaignList } from "components/Campaign/CampaignList";

const CampaignPicker_Query = gql(`
  query CampaignPicker_Query($orgId: ObjectID) {
    ...CampaignList_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(CampaignPicker_Query);

  return <CampaignList query={data} picker />;
}
