import {
  StyleSheet,
  TextInput,
  Button,
  Wrap,
  Platform,
  useColors,
} from "components/common";
import { useTranslation } from "react-i18next";
import { Redirect } from "expo-router";
import { ScrollView } from "react-native-gesture-handler";
import { useCallback, useEffect, useState } from "react";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { useMutation } from "@apollo/client";
import { selectSignUpRequired, setSignUpRequired } from "store/auth";
import { useSelector } from "react-redux";
import { dispatch } from "store";

const SignUp_QueryFragment = gql(`
  fragment SignUp_QueryFragment on Query {
    user {
      id
      firstName
      lastName
      middleName
    }
  }
`);

const SignUp_Mutation = gql(`
  mutation SignUp_Mutation($data: UserInput!) {
    updateUser(data: $data) {
      id
      firstName
      lastName
      middleName
    }
  }
`);

type SignUpProps = {
  query?: FragmentType<typeof SignUp_QueryFragment>;
};

export const SignUp = (props: SignUpProps) => {
  const query = getFragmentData(SignUp_QueryFragment, props.query);
  const signUpRequired = useSelector(selectSignUpRequired);

  const { t: g } = useTranslation();
  const { t } = useTranslation("SignUp");
  const colors = useColors();

  const [firstName, setFirstName] = useState(query?.user?.firstName || "");
  const [lastName, setLastName] = useState(query?.user?.lastName || "");
  const [middleName, setMiddleName] = useState(query?.user?.middleName || "");

  useEffect(() => {
    if (!query?.user) return;

    setFirstName(query.user.firstName || "");
    setLastName(query.user.lastName || "");
    setMiddleName(query.user.middleName || "");
  }, [query?.user]);

  const isValid = firstName.trim() && lastName.trim();

  const [updateCurrentUser, { loading }] = useMutation(SignUp_Mutation, {
    onCompleted() {
      dispatch(setSignUpRequired(false));
    },
  });
  const submit = useCallback(() => {
    updateCurrentUser({
      variables: { data: { firstName, lastName, middleName } },
    });
  }, [firstName, lastName, middleName, updateCurrentUser]);

  if (!signUpRequired) return <Redirect href="/settings/" />;

  return (
    <ScrollView
      style={[styles.container, { backgroundColor: colors.backgroundPrimary }]}
      contentContainerStyle={styles.contentContainer}
      contentInsetAdjustmentBehavior="automatic"
      centerContent={Platform.OS === "web"}
    >
      <Wrap style={styles.wrap}>
        <TextInput
          placeholder={t("lastName")}
          value={lastName}
          onChangeText={setLastName}
        />
        <TextInput
          placeholder={t("firstName")}
          value={firstName}
          onChangeText={setFirstName}
        />
        <TextInput
          placeholder={t("middleName")}
          value={middleName}
          onChangeText={setMiddleName}
        />
        <Button
          style={styles.submitButton}
          text={g("continue")}
          disabled={!isValid}
          loading={loading}
          onPress={submit}
        />
      </Wrap>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingHorizontal: 16,
    paddingVertical: 32,
  },
  wrap: {
    gap: 16,
  },
  submitButton: {
    marginTop: 16,
    minHeight: 50,
  },
});
