import { modalOptions } from "components/common";
import { Redirect, Stack } from "expo-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectToken } from "store/auth";

export const unstable_settings = {
  initialRouteName: "(tabs)",
};

export default function AppLayout() {
  const { t } = useTranslation("pages");
  const token = useSelector(selectToken);

  if (!token) return <Redirect href="/signIn/" />;

  return (
    <Stack screenOptions={{ headerTitleAlign: "center" }}>
      <Stack.Screen
        name="(tabs)"
        options={{
          headerShown: false,
          animation: "fade_from_bottom",
        }}
      />
      <Stack.Screen
        name="signUp"
        options={{
          title: t("signUp.title"),
          animation: "fade_from_bottom",
          headerLargeTitle: true,
        }}
      />
      <Stack.Screen
        name="profile/edit"
        options={{
          title: t("profile.edit.title"),
          animation: "fade",
          gestureDirection: "vertical",
        }}
      />
      <Stack.Screen name="(modal)" options={modalOptions} />
    </Stack>
  );
}
