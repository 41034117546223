import { FragmentType, gql, getFragmentData } from "__generated__";
import { StyleSheet, Text, View, Wrap } from "components/common";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import { UserPhoto } from "./UserPhoto";
import { useTranslation } from "react-i18next";

const Header_UserFragment = gql(`
  fragment Header_UserFragment on User {
    firstName
    middleName
    lastName
    phone
    signUpRequired
    ...Photo_UserFragment
  }
`);

type UserHeaderProps = {
  user?: FragmentType<typeof Header_UserFragment> | null;
};

export const UserHeader = (props: UserHeaderProps) => {
  const user = getFragmentData(Header_UserFragment, props.user);
  const { t } = useTranslation("UserHeader");

  const formatedPhone =
    user?.phone && formatIncompletePhoneNumber("+" + user.phone);
  const name = [user?.firstName, user?.middleName, user?.lastName]
    .filter((_) => _)
    .join(" ");

  const title = user?.signUpRequired
    ? t("signUpRequired")
    : name || formatedPhone;

  return (
    <Wrap style={styles.header}>
      <UserPhoto user={user} size={100} />
      <View>
        <Text style={styles.headerTitle} shimmerMaxWidth={240} loading={!title}>
          {title}
        </Text>
      </View>
    </Wrap>
  );
};

const styles = StyleSheet.create({
  header: {
    gap: 8,
    paddingTop: 8,
    paddingBottom: 16,
  },
  headerTitle: {
    fontSize: 28,
    textAlign: "center",
    fontWeight: "500",
  },
  headerSubtitle: {
    textAlign: "center",
    fontSize: 17,
  },
});
