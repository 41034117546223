.app-main-container {
  min-height: 100vh;
  min-height: 100dvh;
}

@keyframes bounce {
  0% {
    transform: translateY(0) scaleX(1.2);
  }
  50% {
    transform: translateY(10px) scaleX(1);
  }
  100% {
    transform: translateY(0) scaleX(1.2);
  }
}

.app-main-arrow-down {
  animation: bounce 2s infinite ease-in-out;
}

@media screen and (max-width: 900px) {
  .app-main-container {
    justify-content: center;
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .app-main-rows {
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
  }

  .app-main-description-container {
    align-items: flex-start;
    width: 100%;
  }

  .app-main-description,
  .app-main-description2 {
    text-align: left;
  }
}

@media screen and (max-width: 500px) {
  .app-main-title-border {
    top: 13px;
    bottom: 12px;
  }
  .app-main-title {
    font-size: 54px !important;
  }
}
