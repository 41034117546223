import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { User } from "components/User/User";
import { useLocalSearchParams } from "expo-router";

const User_Query = gql(`
  query User_Query($id: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {
    ...User_QueryFragment
  }
`);

export default function Page() {
  const { id } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(User_Query, { variables: { id } });

  return <User query={data} />;
}
