import { Image, StyleSheet, Text, View } from "react-native";
import { Title } from "../common/Title";
import { Safe } from "../common/Safe";
import { darkColor, secondaryColor } from "../Constants";
import { Section } from "../common/Section";
import { LinearGradient } from "expo-linear-gradient";
import MapImage from "./map.webp";

export const Locations = () => {
  return (
    <Section style={styles.container}>
      <Safe>
        <Title dark>Реклама в такси в 6 городах Беларуси</Title>
        <View style={styles.texts}>
          <Text style={styles.subtitle}>
            Добавьте оборотов свему бизнесу рекламой в{" "}
            <Text style={styles.bold}>
              Минске, Гомеле, Могилеве, Витебске, Гродно и Бресте
            </Text>
            .
          </Text>
          <Text style={styles.description}>
            Это ваш ключ к большей аудитории, укреплению на рынке и увеличению
            продаж через ведущие города Беларуси.
          </Text>
        </View>
      </Safe>
      <View style={styles.mapContainer}>
        <LinearGradient
          style={styles.gradient}
          colors={["#212121", "transparent"]}
        />
        <View style={styles.mapWrap}>
          <View style={styles.map}>
            <Image style={styles.background} source={MapImage} />
          </View>
        </View>
      </View>
    </Section>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: darkColor,
  },
  texts: {
    gap: 16,
    marginBottom: 0,
  },
  subtitle: {
    color: "white",
    fontSize: 14,
    fontWeight: "300",
  },
  description: {
    color: secondaryColor,
    fontSize: 14,
    fontWeight: "300",
  },
  bold: {
    fontWeight: "bold",
  },
  gradient: {
    height: 100,
    marginBottom: -100,
    zIndex: 1,
    marginLeft: -32,
    marginRight: -32,
  },
  mapContainer: {
    zIndex: -1,
  },
  mapWrap: {
    pointerEvents: "none",
    marginBottom: -94,
    marginLeft: -32,
    marginRight: -32,
  },
  map: {
    backgroundColor: "red",
    aspectRatio: 2.54,
  },
  background: {
    minWidth: "100%",
    minHeight: "100%",
    maxHeight: "100%",
    maxWidth: "100%",
    backgroundColor: "green",
    resizeMode: "contain",
  },
});
