import { HeaderButton, SectionListMenu } from "components/common";
import { Link, Stack } from "expo-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import UserAdd from "assets/icons/user-add.svg";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { CarHeader } from "./CarHeader";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { getOrgMemberListItem } from "components/OrgMember/getOrgMemberListItem";
import { getAdListItem } from "components/Ad/getAdListItem";

export const Car_QueryFragment = gql(`
  fragment Car_QueryFragment on Query {
    car(id: $id) {
      id
      year
      plate
      drivers {
        id
        ...GetListItem_OrgMemberFragment
      }
      org {
        id
        ...GetListItem_OrgFragment
      }
      currentAd {
        id
        ...GetListItem_AdFragment
      }
      ads {
        id
        ...GetListItem_AdFragment
      }
      ...Header_CarFragment
    }
  }
`);

export type CarProps = {
  query?: FragmentType<typeof Car_QueryFragment>;
};

export const Car = (props: CarProps) => {
  const query = getFragmentData(Car_QueryFragment, props.query);

  const { t: g } = useTranslation();
  const { t } = useTranslation("Car");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "org",
        data: [getOrgListItem({ org: query?.car?.org })],
      },
      {
        key: "info",
        data: [
          {
            key: "plate",
            label: t("plate"),
            loading: !query,
            title: query?.car?.plate,
          },
          {
            key: "year",
            label: t("year"),
            loading: !query,
            visible: !!query?.car?.year,
            title: query?.car?.year || undefined,
          },
        ],
      },
      {
        key: "currentAd",
        title: t("currentAd"),
        data: [
          ...(query?.car?.currentAd !== null
            ? [getAdListItem({ ad: query?.car?.currentAd })]
            : []),
          {
            key: "createAd",
            title: t(query?.car?.currentAd ? "replaceAd" : "createAd"),
            buttonType: "primary",
            disabled: !query,
            Link: (
              <Link
                href={{
                  pathname: "/ad/create",
                  params: { carId: query?.car?.id || "" },
                }}
              />
            ),
          },
        ],
      },
      {
        key: "ads",
        title: t("ads"),
        data: query?.car?.ads?.map((ad) => getAdListItem({ ad })) || [],
      },
      {
        key: "drivers",
        title: t("drivers"),
        data: [
          {
            key: "add",
            LeadingIcon: UserAdd,
            buttonType: "primary",
            title: g("add"),
            Link: query?.car ? (
              <Link href={`/car/${query.car.id}/driver/add`} />
            ) : undefined,
          },
          ...(query?.car?.drivers?.map((member) =>
            getOrgMemberListItem({ member })
          ) || [getOrgMemberListItem()]),
        ],
      },
    ];
  }, [g, query, t]);

  return (
    <>
      {query && (
        <Stack.Screen
          options={{
            headerRight: () => (
              <HeaderButton
                text={g("edit")}
                href={`/car/${query.car?.id}/edit`}
              />
            ),
          }}
        />
      )}
      <SectionListMenu
        sections={sections}
        ListHeaderComponent={<CarHeader car={query?.car} />}
      />
    </>
  );
};
