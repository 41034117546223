import { StyleSheet, View } from "components/common";
import { Redirect, Stack } from "expo-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectToken } from "store/auth";

export const unstable_settings = {
  initialRouteName: "index",
};

export default function AppLayout() {
  const { t } = useTranslation("pages");
  const token = useSelector(selectToken);

  if (token) return <Redirect href="/settings/" />;

  return (
    <Stack>
      <Stack.Screen
        name="index"
        options={{
          title: t("signIn.title"),
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="code"
        options={{
          title: t("signIn.code.title"),
          headerTitle: () => "",
          headerShadowVisible: false,
          headerBackground: () => (
            <View
              style={StyleSheet.absoluteFill}
              colorName="backgroundPrimary"
            />
          ),
        }}
      />
    </Stack>
  );
}
