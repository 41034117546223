import { gql } from "__generated__";

export const RejectOrgMemberInvite_Mutation = gql(`
  mutation RejectOrgMemberInvite_Mutation($orgId: ObjectID!) {
    rejectOrgMemberInvite(orgId: $orgId) {
      id
      inviteStatus
    }
  }
`);

export const AcceptOrgMemberInvite_Mutation = gql(`
  mutation AcceptOrgMemberInvite_Mutation($orgId: ObjectID!) {
    acceptOrgMemberInvite(orgId: $orgId) {
      id
      inviteStatus
    }
  }
`);
