import * as ImagePicker from "expo-image-picker";
import { useCallback } from "react";
import { useUploads } from "components/common";
import { useAssetPicker } from "hooks/useAssetPicker";
import { gql } from "__generated__";
import { useMutation } from "@apollo/client";

const UseUpdateCurrentUserPhoto_Mutation = gql(`
  mutation UseUpdateCurrentUserPhoto_Mutation($data: UserInput!) {
    updateUser(data: $data) {
      id
      photo {
        public_id
        thumbhash
      }
    }
  }
`);

export const useUpdateCurrentUserPhoto = () => {
  const [uploads, uploadAsset] = useUploads("currentUserPhoto");
  const [updateUser] = useMutation(UseUpdateCurrentUserPhoto_Mutation);

  const [pick] = useAssetPicker({
    doc: false,
  });

  const update = useCallback(() => {
    pick().then((assets) => {
      uploadAsset(assets[0], false).then(({ data, upload }) => {
        if (!data) return;

        updateUser({
          variables: {
            data: {
              photo: {
                public_id: data.public_id,
              },
            },
          },
          onCompleted() {
            upload.remove();
          },
          onError() {
            upload.remove();
          },
        });
      });
    });
  }, [pick, updateUser, uploadAsset]);

  return [uploads[0] ? uploads[0] : undefined, update] as const;
};

export async function assetToFile(
  asset: ImagePicker.ImagePickerAsset
): Promise<File> {
  const res: Response = await fetch(asset.uri);
  const blob: Blob = await res.blob();
  return new File([blob], asset.fileName || "", { type: asset.mimeType });
}
