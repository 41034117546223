import Constants from "expo-constants";
import { Platform } from "react-native";

const devHost =
  Platform.OS === "web" && typeof location !== "undefined"
    ? location.hostname
    : Constants.expoConfig?.hostUri?.split(":")[0];

export default {
  defaultLocale: "ru",
  api: {
    production: "https://api.incarmedia.by",
    test: "https://test.api.incarmedia.by",
    development: devHost ? `http://${devHost}:4000` : undefined,
  },
  mapbox: {
    accessToken:
      "pk.eyJ1IjoiZmx5Z29tZWwiLCJhIjoiY2xzNGVueWZuMTY4ajJrbzNvYWpiMGoxcyJ9.yF0lZMZ2AHVmZEXmf3j_lg",
  },
  appMetrika: "",
} as const;
