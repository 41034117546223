import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { OrgMemberList } from "components/OrgMember/OrgMemberList";
import { useLocalSearchParams } from "expo-router";

const OrgMemberPicker_Query = gql(`
  query OrgMemberPicker_Query($orgId: ObjectID) {
    ...OrgMemberList_QueryFragment
  }
`);

export default function Page() {
  const { orgId } = useLocalSearchParams<{ orgId?: string }>();

  const { data } = useQuery(OrgMemberPicker_Query, {
    variables: {
      orgId,
    },
  });

  return <OrgMemberList query={data} picker />;
}
