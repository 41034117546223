import { StyleSheet, View, ViewProps } from "react-native";

export const Safe = ({ style, ...rest }: ViewProps) => {
  return <View style={[styles.container, style]} {...rest} />;
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    width: "100%",
    maxWidth: 1192,
  },
});
