import { forwardRef, useMemo } from "react";
import { TextInput, TextInputProps } from "./Themed";
import { useTranslation } from "react-i18next";
import { formatIncompletePhoneNumber, parseDigits } from "libphonenumber-js";

export const PhoneInput = forwardRef<TextInput, TextInputProps>(
  function PhoneInput({ value, onChangeText, ...rest }, ref) {
    const { t } = useTranslation("common");

    const phone = useMemo(() => {
      if (!value) return "";

      const phone = value.length
        ? /^\+/.test(value)
          ? value
          : "+" + value
        : "";

      return formatIncompletePhoneNumber(phone);
    }, [value]);

    return (
      <TextInput
        ref={ref}
        placeholder={t("PhoneInput.placeholder")}
        value={phone}
        onChangeText={(text) => {
          onChangeText?.(parseDigits(text));
        }}
        keyboardType="phone-pad"
        textContentType="telephoneNumber"
        returnKeyType="next"
        autoFocus
        autoComplete="tel"
        importantForAutofill="yes"
        {...rest}
      />
    );
  }
);
