import { CampaignList } from "components/Campaign/CampaignList";
import { gql } from "__generated__";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { selectCurrentOrgId } from "store/settings";
import { Stack } from "expo-router";
import { OrgSwitcher } from "components/Org/OrgSwithcher";
import { OrgMemberInviteStatus } from "__generated__/graphql";

const Campaigns_Query = gql(`
  query Campaigns_Query($orgId: ObjectID!) {
    user {
      orgsMember {
        id
        inviteStatus
      }
    }
    ...CampaignList_QueryFragment
    ...OrgSwitcher_QueryFragment
  }
`);

export default function Page() {
  const currentOrgId = useSelector(selectCurrentOrgId);

  const { data } = useQuery(Campaigns_Query, {
    variables: { orgId: currentOrgId! },
    skip: !currentOrgId,
  });

  return (
    <>
      <Stack.Screen
        options={{
          headerTitle:
            !data ||
            data.user?.orgsMember.filter(
              (member) => member.inviteStatus === OrgMemberInviteStatus.Joined
            ).length
              ? () => <OrgSwitcher query={data} />
              : undefined,
        }}
      />
      <CampaignList query={data} orgId={currentOrgId} />
    </>
  );
}
