import { FragmentType, getFragmentData, gql } from "__generated__";
import { SectionListMenuItemProps, UserContent } from "components/common";
import { Link } from "expo-router";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import { UserPhoto } from "./UserPhoto";
import { getFixedT } from "i18next";

const GetListItem_UserFragment = gql(`
  fragment GetListItem_UserFragment on User {
    id
    phone
    firstName
    lastName
    signUpRequired
    inviter {
      id
      firstName
      lastName
    }
    ...Photo_UserFragment
  }
`);

type OrgUserListItemProps = {
  user?: FragmentType<typeof GetListItem_UserFragment> | null;
};

export const getUserListItem = (
  props?: OrgUserListItemProps
): SectionListMenuItemProps => {
  const user = getFragmentData(GetListItem_UserFragment, props?.user);
  const t = getFixedT(null, "getUserListItem");

  const phone = formatIncompletePhoneNumber("+" + user?.phone);
  const subtitle = user?.signUpRequired ? t("signUpRequired") : phone;

  return {
    key: user?.id || "user",
    Link: user ? <Link href={`/user/${user.id}/`} /> : undefined,
    LeadingComponent: <UserPhoto user={user} />,
    leadingWidth: 40,
    Content: (
      <UserContent
        title={
          [user?.firstName, user?.lastName].filter((_) => _).join(" ") || phone
        }
        subtitle={
          user?.inviter
            ? [subtitle, t("inviter", { user: user.inviter })]
            : [subtitle]
        }
        loading={!user}
      />
    ),
  };
};
