import { Home } from "components/Home";
import { Redirect } from "expo-router";
import { useSelector } from "react-redux";
import { selectToken } from "store/auth";

export default function Page() {
  const token = useSelector(selectToken);
  if (token) return <Redirect href="/settings/" />;
  return <Home />;
}
