import { SectionListMenu } from "components/common";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { AppInfo } from "components/AppInfo";
import { useUpdateCurrentUserPhoto } from "utils/photo";
import CameraPlusIcon from "assets/icons/camera-plus.svg";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { SettingsHeader } from "./SettingsHeader";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { useSelector } from "react-redux";
import { selectCurrentOrgId } from "store/settings";
import { OrgMemberInviteStatus } from "__generated__/graphql";
import { Link } from "expo-router";

const Settings_QueryFragment = gql(`
  fragment Settings_QueryFragment on Query {
    user {
      id
      ...Header_SettingsFragment
      orgsMember {
        id
        inviteStatus
        org {
          id
          ...GetListItem_OrgFragment
        }
      }
    }
  }
`);

type SettingsProps = {
  query?: FragmentType<typeof Settings_QueryFragment>;
};

export const Settings = (props: SettingsProps) => {
  const query = getFragmentData(Settings_QueryFragment, props.query);
  const currentOrgId = useSelector(selectCurrentOrgId);

  const { t } = useTranslation("Settings");

  const [upload, updatePhoto] = useUpdateCurrentUserPhoto();

  const sections = useMemo(() => {
    const sections: SectionListMenu[] = [
      {
        key: "changePhoto",
        data: [
          {
            key: "changePhoto",
            LeadingIcon: CameraPlusIcon,
            title: t("changePhoto"),
            buttonType: "primary",
            onPress: updatePhoto,
            disabled: !!upload,
          },
        ],
      },
    ];

    const currentOrg = query
      ? query?.user?.orgsMember.find(
          (member) =>
            member.org.id === currentOrgId &&
            member.inviteStatus === OrgMemberInviteStatus.Joined
        ) || null
      : undefined;

    sections.push({
      key: "currentOrg",
      title: t("currentOrg"),
      data: [
        currentOrg !== null
          ? getOrgListItem({
              org: currentOrg?.org,
            })
          : {
              key: "joinOrg",
              title: t("joinOrg"),
              centerContent: true,
              buttonType: "primary",
              Link: <Link href="/org/add" />,
            },
      ],
    });
    query?.user?.orgsMember
      .filter(
        (member) =>
          member.inviteStatus === OrgMemberInviteStatus.Pending &&
          member.org.id !== currentOrgId
      )
      .forEach((member, index) =>
        sections.push({
          key: member.id,
          title: !index ? t("invites") : undefined,
          data: [getOrgListItem({ org: member.org })],
        })
      );
    return sections;
  }, [currentOrgId, query, t, updatePhoto, upload]);

  return (
    <SectionListMenu
      sections={sections}
      ListHeaderComponent={<SettingsHeader user={query?.user} />}
      ListFooterComponent={AppInfo}
    />
  );
};
