import { StyleSheet, Text, View } from "react-native";
import { Title } from "../common/Title";
import { Safe } from "../common/Safe";
import "./index.css";
import { darkColor } from "../Constants";
import { Section } from "../common/Section";
import { cssClass } from "utils/css";

export const HowWeWork = () => {
  return (
    <Section style={styles.container}>
      <Safe>
        <Title dark>Как мы работаем</Title>
        <View style={styles.items}>
          {[
            "Связываемся",
            "Обсуждаем",
            "Подбираем",
            "Предоплата",
            "Размещаем",
            "Оплата",
          ].map((_, index) => (
            <View
              key={index}
              style={[cssClass("how-we-work-item"), styles.item]}
            >
              <Text style={styles.itemStep}>0{index + 1}</Text>
              <Text style={styles.itemText}>{_}</Text>
            </View>
          ))}
        </View>
      </Safe>
    </Section>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: darkColor,
  },
  items: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  item: {
    minWidth: 100,
  },
  itemStep: {
    color: "#ffc515",
    fontSize: 26,
    fontWeight: "500",
  },
  itemText: {
    color: "white",
    fontSize: 20,
    fontWeight: "500",
  },
});
