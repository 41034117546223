@media screen and (max-width: 860px) {
  .app-monitoring-rows {
    flex-direction: column;
  }
  .app-monitoring-row {
    flex: inherit;
  }
  .app-monitoring-image-container {
    transform: scaleX(-1);
  }
}

@media screen and (max-width: 500px) {
  .app-monitoring-rows {
    gap: 16px;
  }
  .app-monitoring-item {
    width: 100%;
  }
  .app-monitoring-item-container {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
