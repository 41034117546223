import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { AdList } from "components/Ad/AdList";

const ControlAds_Query = gql(`
  query ControlAds_Query {
    ...AdList_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(ControlAds_Query);

  return <AdList query={data} />;
}
