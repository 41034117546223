import "mapbox-gl/dist/mapbox-gl.css";
import "./ReportMap.css";
import React, { useMemo } from "react";
import Map, { Source, Layer } from "react-map-gl";
import type { FeatureCollection } from "geojson";
import { Platform, StyleSheet, useColorScheme } from "components/common";
import { FragmentType, getFragmentData, gql } from "__generated__";
import config from "config";
import { useSelector } from "react-redux";
import { selectLang } from "store/settings";
import Theme from "constants/Theme";

const ReportMap_QueryFragment = gql(`
  fragment ReportMap_QueryFragment on Campaign {
    ads {
        id
        car {
            id
            coordinates
        }
    }
  }
`);

type ReportMapProps = {
  campaign?: FragmentType<typeof ReportMap_QueryFragment> | null;
};

export const ReportMap = (props: ReportMapProps) => {
  const campaign = getFragmentData(ReportMap_QueryFragment, props.campaign);

  const routeLine = useMemo(
    (): FeatureCollection => ({
      type: "FeatureCollection",
      features: [
        ...(campaign?.ads
          .filter((_) => _.car.coordinates)
          .map((ad): FeatureCollection["features"][0] => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: ad.car.coordinates as number[],
            },
            properties: {},
          })) || []),
      ],
    }),
    [campaign?.ads]
  );

  const lang = useSelector(selectLang);
  const scheme = useColorScheme();

  return (
    <Map
      ref={(ref) => {
        if (
          ref &&
          "setLanguage" in ref &&
          typeof ref.setLanguage === "function"
        )
          ref.setLanguage(lang);
      }}
      mapboxAccessToken={config.mapbox.accessToken}
      maxZoom={10}
      scrollZoom={false}
      initialViewState={{
        bounds: [
          [27.385, 53.82],
          [27.72, 53.98],
        ],
      }}
      bearing={0.5}
      style={{
        ...styles.map,
        ...{ backgroundColor: scheme ? Theme[scheme].colors.card : undefined },
      }}
      mapStyle={
        scheme === "dark"
          ? "mapbox://styles/mapbox/dark-v11"
          : "mapbox://styles/mapbox/light-v11"
      }
      locale={{
        name: "ru",
      }}
    >
      <Source id="cars" type="geojson" data={routeLine}>
        <Layer
          id="cars"
          type="circle"
          paint={{
            "circle-radius": 5,
            "circle-color": "green",
            // "circle-blur": 0.9,
            "circle-stroke-color": "white",
            "circle-stroke-opacity": 1,
            "circle-stroke-width": 2,
          }}
        />
      </Source>
    </Map>
  );
};

export interface MapboxDirections {
  code: string;
  uuid: string;
  waypoints: {
    distance: number;
    name: string;
    location: [number, number];
  }[];
  routes: {
    distance: number;
    duration: number;
    geometry: string;
    legs: {
      via_waypoints: [];
      admins: {
        iso_3166_1: string;
        iso_3166_1_alpha3: string;
      }[];
      distance: number;
      duration: number;
      steps: [];
      summary: string;
      weight: number;
    }[];
    weight: number;
    weight_name: string;
  }[];
}

const styles = StyleSheet.create({
  map: {
    flex: 1,
    aspectRatio: 2,
    borderRadius: 10,
    marginTop: 16,

    ...Platform.select({
      default: {
        shadowColor: "rgba(0, 0, 0, 0.1)",
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 1,
        shadowRadius: 5,
        elevation: 5,
      },
      web: { boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" },
    }),
  },
});
