import "intl-pluralrules";
import "moment/locale/ru";

import ru from "./ru";

const resources = { ru } as const;

export type LangCodes = keyof typeof resources;
export const langsList = Object.entries(resources).map(([code, _]) => ({
  code: code as LangCodes,
  flag: _.g.flag,
  name: _.g.langName,
  nameNative: _.g.langNameNative,
}));
export type LangsList = (typeof langsList)[0];

export type SupportedLanguages = keyof typeof resources;
export const supportedLanguages = Object.keys(
  resources
) as SupportedLanguages[];

export default resources;
