import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { ControlSettings } from "components/Control/ControlSettings";

const ControlSettings_Query = gql(`
  query ControlSettings_Query {
    ...ControlSettings_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(ControlSettings_Query);

  return <ControlSettings query={data} />;
}
