import { useMutation } from "@apollo/client";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { getOrgListItem } from "components/Org/getOrgListItem";
import {
  SectionListMenu,
  SectionListMenuItem,
  openPicker,
  toast,
} from "components/common";
import { router } from "expo-router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCarDraft } from "store/drafts";
import { selectCurrentOrgId } from "store/settings";
import UserAdd from "assets/icons/user-add.svg";
import { getOrgMemberListItem } from "components/OrgMember/getOrgMemberListItem";
import { refetchQueries } from "utils/refetchQueries";

type InputErrors = {
  brand?: string;
  model?: string;
  year?: string;
  plate?: string;
};

const AddCar_Mutation = gql(`
  mutation AddCar_Mutation($orgId: ObjectID!, $data: CarCreateInput!) {
    addCar(orgId: $orgId, data: $data) {
      id
    }
  }
`);

const CarCreate_QueryFragment = gql(`
  fragment CarCreate_QueryFragment on Query {
    org(id: $orgId) {
      id
      ...GetListItem_OrgFragment
    }
    orgMembers(ids: $driverIds, orgId: $orgId) {
      id
      ...GetListItem_OrgMemberFragment
    }
  }
`);

type CarCreateProps = {
  query?: FragmentType<typeof CarCreate_QueryFragment>;
};

export const CarCreate = (props: CarCreateProps) => {
  const query = getFragmentData(CarCreate_QueryFragment, props.query);

  const { t } = useTranslation("CarCreate");
  const { t: g } = useTranslation();

  const currentOrgId = useSelector(selectCurrentOrgId);

  const [addCar, { loading }] = useMutation(AddCar_Mutation, {
    refetchQueries: refetchQueries.Car,
    onCompleted() {
      router.back();
      resetDraft();
    },
  });

  const [draft, setDraft, resetDraft] = useCarDraft();

  const brandRef = useRef<SectionListMenuItem>(null);
  const modelRef = useRef<SectionListMenuItem>(null);
  const yearRef = useRef<SectionListMenuItem>(null);
  const plateRef = useRef<SectionListMenuItem>(null);

  const [errors, setErrors] = useState<InputErrors>({});
  useEffect(() => {
    errors.brand && brandRef.current?.shake();
    errors.model && modelRef.current?.shake();
    errors.year && yearRef.current?.shake();
    errors.plate && plateRef.current?.shake();
  }, [errors.brand, errors.model, errors.plate, errors.year]);

  const submit = useCallback(() => {
    const errors: InputErrors = {};
    if (!draft.brand.length) errors.brand = t("brand.empty");
    if (!draft.model.length) errors.model = t("model.empty");
    if (draft.year.length && !/^\d{4}$/.test(draft.year))
      errors.year = t("year.invalid");
    if (!draft.plate.length) errors.plate = t("plate.empty");

    setErrors(errors);

    if (Object.keys(errors).length) return;

    if (!currentOrgId) return toast("Please select organization first");

    addCar({
      variables: {
        orgId: currentOrgId,
        data: {
          plate: draft.plate,
          brand: draft.brand,
          model: draft.model,
          year: draft.year.length ? Number(draft.year) : undefined,
        },
      },
    });
  }, [
    addCar,
    currentOrgId,
    draft.brand,
    draft.model,
    draft.plate,
    draft.year,
    t,
  ]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "org",
        data: [
          getOrgListItem({
            key: "org",
            org: query?.org,
            picker: true,
            onOrgIdChange: (orgId) => setDraft({ orgId }),
            loading: !query,
          }),
        ],
      },
      {
        key: "plate",
        title: t("plate.title"),
        error: errors.plate,
        data: [
          {
            ref: plateRef,
            key: "plate",
            type: "textInput",
            textInputProps: {
              value: draft.plate,
              onChangeText: (plate) => {
                setErrors({ ...errors, plate: undefined });
                setDraft({ plate });
              },
              placeholder: t("plate.placeholder"),
            },
          },
        ],
      },
      {
        key: "brand",
        title: t("brand.title"),
        error: errors.brand,
        data: [
          {
            ref: brandRef,
            key: "brand",
            type: "textInput",
            textInputProps: {
              value: draft.brand,
              onChangeText: (brand) => {
                setErrors({ ...errors, brand: undefined });
                setDraft({ brand });
              },
            },
          },
        ],
      },
      {
        key: "model",
        title: t("model.title"),
        error: errors.model,
        data: [
          {
            ref: modelRef,
            key: "model",
            type: "textInput",
            textInputProps: {
              value: draft.model,
              onChangeText: (model) => {
                setErrors({ ...errors, model: undefined });
                setDraft({ model });
              },
            },
          },
        ],
      },
      {
        key: "year",
        title: t("year.title"),
        error: errors.year,
        data: [
          {
            ref: yearRef,
            key: "year",
            type: "textInput",
            textInputProps: {
              value: draft.year,
              onChangeText: (year) => {
                setErrors({ ...errors, year: undefined });
                setDraft({ year });
              },
              keyboardType: "number-pad",
              maxLength: 4,
              placeholder: t("year.placeholder"),
            },
          },
        ],
      },
      {
        key: "drivers",
        title: t("drivers"),
        data: [
          {
            key: "add",
            LeadingIcon: UserAdd,
            disabled: !query,
            title: t("addDriver"),
            buttonType: "primary",
            onPress: () =>
              openPicker(
                "orgMember/picker",
                { orgId: draft.orgId || "" },
                (memberId) => {
                  setDraft({ driverIds: [memberId, ...draft.driverIds] });
                }
              ),
          },
          ...(query?.orgMembers?.map((member) =>
            getOrgMemberListItem({ member })
          ) || [getOrgMemberListItem()]),
        ],
      },
      {
        key: "add",
        data: [
          {
            key: "add",
            style: { marginTop: 16 },
            title: g("add"),
            buttonType: "primary",
            centerContent: true,
            loading,
            onPress: submit,
            disabled: !query?.org,
          },
        ],
      },
    ];
  }, [
    draft.brand,
    draft.driverIds,
    draft.model,
    draft.orgId,
    draft.plate,
    draft.year,
    errors,
    g,
    loading,
    query,
    setDraft,
    submit,
    t,
  ]);

  return <SectionListMenu sections={sections} />;
};
