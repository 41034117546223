import { FragmentType, getFragmentData, gql } from "__generated__";
import { SectionListMenuItemProps, UserContent } from "components/common";
import { Link } from "expo-router";
import { getFixedT } from "i18next";
import moment from "moment";
import { AdListItemTrailing } from "./AdListItemTrailing";

const GetListItem_AdFragment = gql(`
  fragment GetListItem_AdFragment on Ad {
    id
    createdAt
    car {
        id
        brand
        model
        year
        plate
    }
    campaign {
        id
        org {
            id
            name
        }
        periodDays
        name
    }
    ...ListItemTrailing_AdFragment
  }
`);

type GetAdListItemProps = {
  ad?: FragmentType<typeof GetListItem_AdFragment> | null;
  showOrgName?: boolean;
  showCampaignName?: boolean;
};

export const getAdListItem = (
  props?: GetAdListItemProps
): SectionListMenuItemProps => {
  const ad = getFragmentData(GetListItem_AdFragment, props?.ad);
  const t = getFixedT(null, "getAdListItem");

  return {
    key: ad?.id || "",
    Link: ad ? <Link href={`/ad/${ad.id}/`} /> : undefined,
    Content: (
      <UserContent
        title={ad?.car?.plate}
        subtitle={[
          [ad?.car?.brand, ad?.car?.model, ad?.car?.year].join(" ") ||
            "carName",
          t("createdAt", { date: moment(ad?.createdAt).format("lll") }),
          t("expireAt", {
            date: moment(ad?.createdAt)
              .add(ad?.campaign.periodDays, "days")
              .format("lll"),
          }),
          (props?.showOrgName && ad?.campaign.org.name) || "orgName",
          (props?.showCampaignName && ad?.campaign.name) || "campaignName",
        ]}
        loading={!ad}
      />
    ),
    loadingTrailing: !ad,
    shimmerTrailingMinWidth: 80,
    TrailingComponent: <AdListItemTrailing ad={ad} />,
  };
};
