import { Redirect, Tabs, router } from "expo-router";
import { useTranslation } from "react-i18next";
import GearIcon from "assets/icons/gear.svg";
import { SvgProps } from "react-native-svg";
import { createEventCounter } from "utils/eventCounter";
import ShieldIcon from "assets/icons/shield-righthalf.svg";
import PersonsIcon from "assets/icons/persons.svg";
import CarIcon from "assets/icons/car.svg";
import HornIcon from "assets/icons/horn.svg";
import { useSelector } from "react-redux";
import { selectSignUpRequired } from "store/auth";
import { useMemo } from "react";
import { OrgMemberPermission } from "__generated__/graphql";
import { gql } from "__generated__";
import { useQuery } from "@apollo/client";
import { selectCurrentOrgId } from "store/settings";

export const unstable_settings = {
  initialRouteName: "settings",
};

const icons: { [key: string]: React.FC<SvgProps> } = {
  control: ShieldIcon,
  orgMembers: PersonsIcon,
  cars: CarIcon,
  campaigns: HornIcon,
  settings: GearIcon,
};

const onSettingsTap = createEventCounter(() => router.push("/debug/"));

const allTabs = [
  "control",
  "orgMembers",
  "cars",
  "campaigns",
  "settings",
] as const;
type Tab = (typeof allTabs)[number];

const Tabs_Query = gql(`
  query Tabs_Query {
    user {
      orgsMember {
        id
        orgId
        inviteStatus
        permissions
      }
    }
  }
`);

export default function AppLayout() {
  const { data } = useQuery(Tabs_Query);
  const currentOrgId = useSelector(selectCurrentOrgId);

  const { t } = useTranslation("pages");
  const signUpRequired = useSelector(selectSignUpRequired);
  const orgMember = useMemo(() => {
    return data?.user?.orgsMember.find(
      (member) => member.orgId === currentOrgId
    );
  }, [currentOrgId, data?.user?.orgsMember]);

  const settingsBadge = useMemo(() => {
    return (
      data?.user?.orgsMember?.filter(
        (member) => member.inviteStatus === "pending"
      ).length || undefined
    );
  }, [data?.user?.orgsMember]);

  const visibleTabs = useMemo(() => {
    const tabs: Tab[] = [];

    tabs.push("control");

    orgMember?.permissions.includes(OrgMemberPermission.MembersManagement) &&
      tabs.push("orgMembers");

    orgMember?.permissions.includes(OrgMemberPermission.CarsManagement) &&
      tabs.push("cars");

    orgMember?.permissions.includes(OrgMemberPermission.CampaignsManagement) &&
      tabs.push("campaigns");

    tabs.push("settings");

    return tabs;
  }, [orgMember?.permissions]);

  if (signUpRequired) return <Redirect href="/signUp" />;

  return (
    <Tabs
      screenOptions={{ headerTitleAlign: "center", headerShown: false }}
      initialRouteName="settings"
    >
      {allTabs.map((key) => (
        <Tabs.Screen
          key={key}
          name={key}
          options={{
            title: t(`${key}.title`),
            href: visibleTabs.includes(key) ? undefined : null,
            tabBarIconStyle: {
              zIndex: 1,
            },
            tabBarBadge: key === "settings" ? settingsBadge : undefined,
            tabBarBadgeStyle: {
              maxWidth: "auto",
            },
            tabBarIcon: icons[key]
              ? ({
                  size,
                  color,
                }: {
                  focused: boolean;
                  color: string;
                  size: number;
                }) => {
                  const Icon = icons[key];
                  return <Icon width={size} height={size} color={color} />;
                }
              : undefined,
          }}
          listeners={{
            tabPress: (e) => {
              e.target?.includes("settings") && onSettingsTap();
            },
          }}
        />
      ))}
    </Tabs>
  );
}
