import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { UserList } from "components/User/UserList";

const ControlUsers_Query = gql(`
  query ControlUsers_Query {
    ...UserList_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(ControlUsers_Query);

  return <UserList query={data} />;
}
