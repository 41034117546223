import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { CarList } from "components/Car/CarList";

const CarPicker_Query = gql(`
  query CarPicker_Query($orgId: ObjectID) {
    ...CarList_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(CarPicker_Query);

  return <CarList query={data} picker />;
}
