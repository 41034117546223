import { StyleSheet } from "react-native";
import Animated, {
  FadeIn,
  FadeOut,
  LinearTransition,
} from "react-native-reanimated";
import { useColors } from "./Themed";
import { mobileMaxWidth } from "constants/Device";

export const Separator = () => {
  const colors = useColors();

  return (
    <Animated.View
      style={[styles.separator, { backgroundColor: colors.border }]}
      layout={LinearTransition}
      entering={FadeIn}
      exiting={FadeOut}
    />
  );
};

const styles = StyleSheet.create({
  separator: {
    height: StyleSheet.hairlineWidth,
    width: "100%",
    maxWidth: mobileMaxWidth,
    alignSelf: "center",
  },
});
