.app-header-menu-item {
  transition: all 0.2s;
}
.app-header-menu-item:hover {
  background-color: #fff2;
}

@media screen and (max-width: 726px) {
  .app-header-menu {
    display: none !important;
  }
}
