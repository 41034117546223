import { useLocalSearchParams } from "expo-router";
import { CampaignEdit } from "components/Campaign/CampaignEdit";
import { useQuery } from "@apollo/client";
import { gql } from "__generated__";

export const CampaignEdit_Query = gql(`
  query CampaignEdit_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {
    ...CampaignEdit_QueryFragment
  }
`);

export default function Page() {
  const { id } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(CampaignEdit_Query, { variables: { id } });

  return <CampaignEdit query={data} />;
}
