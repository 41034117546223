import {
  HeaderButton,
  SectionListMenu,
  SectionListMenuItem,
} from "components/common";
import { Stack, router } from "expo-router";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { dispatch } from "store";
import { signOut } from "store/actions/signOut";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { ProfileEditHeader } from "./ProfileEditHeader";
import { useMutation } from "@apollo/client";

const ProfileEdit_Mutation = gql(`
  mutation ProfileEdit_Mutation($data: UserInput!) {
    updateUser(data: $data) {
      id
      firstName
      lastName
      middleName
    }
  }
`);

const ProfileEdit_QueryFragment = gql(`
  fragment ProfileEdit_QueryFragment on Query {
    user {
      id
      phone
      firstName
      middleName
      lastName
      ...ProfileEditHeader_UserFragment
    }
  }
`);

type ProfileEditProps = {
  query?: FragmentType<typeof ProfileEdit_QueryFragment> | null;
};

export const ProfileEdit = (props: ProfileEditProps) => {
  const query = getFragmentData(ProfileEdit_QueryFragment, props.query);

  const { t } = useTranslation("ProfileEdit");
  const { t: g } = useTranslation();

  const [firstName, setFirstName] = useState(query?.user?.firstName || "");
  const [lastName, setLastName] = useState(query?.user?.lastName || "");
  const [middleName, setMiddleName] = useState(query?.user?.middleName || "");

  useEffect(() => {
    if (!query) return;

    setFirstName(query.user?.firstName || "");
    setLastName(query.user?.lastName || "");
    setMiddleName(query.user?.middleName || "");
  }, [query]);

  const firstNameRef = useRef<SectionListMenuItem>(null);
  const lastNameRef = useRef<SectionListMenuItem>(null);
  const middleNameRef = useRef<SectionListMenuItem>(null);

  const [updateUser, { data, loading }] = useMutation(ProfileEdit_Mutation, {
    onCompleted: () => router.back(),
  });

  const submit = useCallback(() => {
    if (!firstName.length || !lastName.length) {
      !firstName.length && firstNameRef.current?.shake();
      !lastName.length && lastNameRef.current?.shake();
      return;
    }
    updateUser({
      variables: {
        data: {
          firstName,
          lastName,
          middleName,
        },
      },
    });
  }, [firstName, lastName, middleName, updateUser]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "basicInfo",
        data: [
          {
            key: "lastName",
            ref: lastNameRef,
            type: "textInput",
            textInputProps: {
              placeholder: t("lastName"),
              value: lastName,
              onChangeText: setLastName,
              textContentType: "familyName",
              autoComplete: "name-family",
              editable: !loading,
            },
            loading: !query,
            shimmerMaxWidth: 110,
          },
          {
            key: "firstName",
            ref: firstNameRef,
            type: "textInput",
            textInputProps: {
              placeholder: t("firstName"),
              value: firstName,
              onChangeText: setFirstName,
              textContentType: "givenName",
              autoComplete: "name-given",
              editable: !loading,
            },
            loading: !query,
            shimmerMaxWidth: 80,
          },
          {
            key: "middleName",
            ref: middleNameRef,
            type: "textInput",
            textInputProps: {
              placeholder: t("middleName"),
              value: middleName,
              onChangeText: setMiddleName,
              textContentType: "middleName",
              autoComplete: "name-middle",
              editable: !loading,
            },
            loading: !query,
            shimmerMaxWidth: 100,
          },
        ],
      },
      {
        key: "phone",
        data: [
          {
            key: "phone",
            title: t("phone"),
            trailingText: formatIncompletePhoneNumber("+" + query?.user?.phone),
            loadingTrailing: !query,
            shimmerTrailingMinWidth: 142,
          },
        ],
      },
      {
        key: "logout",
        data: [
          {
            key: "logout",
            title: g("logout"),
            buttonType: "destructive",
            centerContent: true,
            onPress: () => {
              dispatch(signOut());
            },
          },
        ],
      },
    ];
  }, [firstName, g, lastName, loading, middleName, query, t]);

  return (
    <>
      <Stack.Screen
        options={{
          headerRight: !data
            ? () => (
                <HeaderButton
                  text={g("done")}
                  bold
                  loading={loading}
                  onPress={submit}
                />
              )
            : undefined,
        }}
      />
      <SectionListMenu
        sections={sections}
        ListHeaderComponent={<ProfileEditHeader user={query?.user} />}
      />
    </>
  );
};
