import { useMutation, useQuery } from "@apollo/client";
import { gql } from "__generated__";
import {
  KeyboardAvoidingView,
  SectionListMenu,
  SectionListMenuItem,
  SectionListMenuItemProps,
} from "components/common";
import { router } from "expo-router";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { dispatch } from "store";
import { setCurrentOrgId } from "store/settings";
import { refetchQueries } from "utils/refetchQueries";

export const OrgInfo_Query = gql(`
  query OrgInfo_Query($tin: Int!) {
    orgInfo(tin: $tin) {
      entityTypeName
      displayName
      activityTypeName
      registrationAuthorityName
      registrationDate
      statusName
    }
  }
`);

const AddOrg_Mutation = gql(`
  mutation AddOrg_Mutation($tin: Int!, $jobTitle: String!) {
    addOrg(tin: $tin, jobTitle: $jobTitle) {
      id
      org {
        id
      }
    }
  }
`);

export const OrgAdd = () => {
  const { t } = useTranslation("OrgAdd");
  const [tin, setTin] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [tinError, setTinError] = useState<string>();
  const [jobTitleError, setJobTitleError] = useState<string>();

  const tinRef = useRef<SectionListMenuItem>(null);
  const jobTitleRef = useRef<SectionListMenuItem>(null);

  const tinValid = /^\d{9}$/.test(tin);

  const {
    data,
    error: infoError,
    loading: infoLoading,
  } = useQuery(OrgInfo_Query, {
    variables: { tin: parseInt(tin) },
    skip: !tinValid,
  });

  const [addOrg, { loading }] = useMutation(AddOrg_Mutation, {
    refetchQueries: refetchQueries.Org,
    onCompleted: ({ addOrg }) => {
      addOrg && dispatch(setCurrentOrgId(addOrg.org.id));
      router.back();
    },
  });

  const info = !infoError && tinValid ? data?.orgInfo : undefined;

  useEffect(() => {
    infoError?.graphQLErrors.forEach((error) => {
      typeof error.extensions.code === "string" &&
        setTinError(
          t(`unp.error.${error.extensions.code}`, {
            defaultValue: t("unp.error.default", {
              code: error.extensions.code,
            }),
          })
        );
      tinRef.current?.shake();
    });
  }, [infoError, t]);

  const submit = useCallback(() => {
    if (!tinValid || !jobTitle.length) {
      if (!tinValid) {
        setTinError(t("unp.error.invalid"));
        tinRef.current?.shake();
      }
      if (!jobTitle) {
        setJobTitleError(t("jobTitle.error.empty"));
        jobTitleRef.current?.shake();
      }
      return;
    }
    addOrg({ variables: { tin: parseInt(tin), jobTitle } });
  }, [addOrg, jobTitle, t, tin, tinValid]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "unp",
        title: t("unp.title"),
        error: tinError,
        data: [
          {
            key: "tin",
            ref: tinRef,
            type: "textInput",
            textInputProps: {
              placeholder: t("unp.placeholder"),
              value: tin,
              onChangeText: (tin) => {
                setTinError(undefined);
                setTin(tin);
              },
              keyboardType: "number-pad",
              maxLength: 9,
              editable: !loading,
            },
            trailingLoading: infoLoading,
          },
        ],
      },
      {
        key: "jobTitle",
        title: t("jobTitle.title"),
        error: jobTitleError,
        data: [
          {
            key: "jobTitle",
            ref: jobTitleRef,
            type: "textInput",
            textInputProps: {
              placeholder: t("jobTitle.placeholder"),
              value: jobTitle,
              onChangeText: (jobTitle) => {
                setJobTitleError(undefined);
                setJobTitle(jobTitle);
              },
              editable: !loading,
            },
          },
        ],
      },

      {
        key: "info",
        visible: !!info,
        title: t("orgInfo"),
        data: (
          [
            "entityTypeName",
            "displayName",
            "activityTypeName",
            "registrationAuthorityName",
            "registrationDate",
            "statusName",
          ] as const
        ).map(
          (key): SectionListMenuItemProps => ({
            key,
            visible: !!info,
            label: t(key),
            title:
              key === "registrationDate"
                ? moment(data?.orgInfo?.[key]).format("DD.MM.YYYY")
                : data?.orgInfo?.[key] || "",
          })
        ),
      },
      {
        key: "submit",
        data: [
          {
            key: "submit",
            style: { marginTop: 8 },
            buttonType: "primary",
            title: "Добавить",
            centerContent: true,
            onPress: submit,
            loading: loading,
            disabled: !info,
          },
        ],
      },
    ];
  }, [
    data?.orgInfo,
    info,
    infoLoading,
    jobTitle,
    jobTitleError,
    loading,
    submit,
    t,
    tin,
    tinError,
  ]);

  return (
    <KeyboardAvoidingView largeTitle>
      <SectionListMenu sections={sections} />
    </KeyboardAvoidingView>
  );
};
