import { router, useLocalSearchParams } from "expo-router";
import { nanoid } from "nanoid";
import { useCallback, useEffect } from "react";

const ref: { callbacks: Record<string, undefined | ((id: string) => void)> } = {
  callbacks: {},
};

type OpenOrgPickerCallback = (orgId: string) => void;
type ParamsT = Record<string, string | number | boolean>;
export function openPicker(href: string, fn: OpenOrgPickerCallback): void;
export function openPicker(
  href: string,
  params: ParamsT,
  fn: OpenOrgPickerCallback
): void;
export function openPicker(
  href: string,
  ...args: [] | [OpenOrgPickerCallback] | [ParamsT, OpenOrgPickerCallback]
) {
  const [params, fn] = args.length === 2 ? args : [undefined, args[0]];

  const id = nanoid();
  if (fn) ref.callbacks[id] = fn;

  router.navigate({
    pathname: `${href}/${id}`,
    params,
  });
}

export const usePickerCallback = (redirect?: boolean) => {
  const { id } = useLocalSearchParams<{ id: string }>();
  const fn = ref.callbacks[id];

  useEffect(() => {
    setTimeout(() => {
      redirect && !fn && router.back();
    });
  }, [fn, redirect]);

  useEffect(() => {
    return () => {
      delete ref.callbacks[id];
    };
  }, [id]);

  const callback = useCallback(
    (_id: string) => {
      fn?.(_id);
      router.back();
    },
    [fn]
  );

  return fn ? callback : undefined;
};
