import { SectionListMenu, SectionListMenuItem } from "components/common";
import { router } from "expo-router";
import {
  formatIncompletePhoneNumber,
  isPossiblePhoneNumber,
  parseDigits,
} from "libphonenumber-js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { gql, getFragmentData, FragmentType } from "__generated__";
import { useMutation } from "@apollo/client";
import {
  OrgMemberPermission,
  OrgMembers_QueryDocument,
} from "__generated__/graphql";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { getOrgMemberAdminFeaturesSection } from "./getOrgMemberAdminFeaturesSection";

type InputErrors = {
  phone?: string;
  jobTitle?: string;
};

const InviteOrgMember_Mutation = gql(`
  mutation InviteOrgMember_Mutation(
    $orgId: ObjectID!
    $phone: String!
    $jobTitle: String!
    $permissions: [OrgMemberPermission!]!
  ) {
    inviteOrgMember(
      orgId: $orgId
      phone: $phone
      jobTitle: $jobTitle
      permissions: $permissions
    ) {
      id
      jobTitle
      user {
        id
        phone
      }
    }
  }
`);

export const OrgMemberInvite_QueryFragment = gql(`
  fragment OrgMemberInvite_QueryFragment on Query {
    org(id: $orgId) {
      id
      ...GetListItem_OrgFragment
      ...GetAdminFeaturesSection_OrgMemberFragment
    }
  }
`);

type OrgMemberInviteProps = {
  query?: FragmentType<typeof OrgMemberInvite_QueryFragment>;
};

export default function OrgMemberInvite(props: OrgMemberInviteProps) {
  const query = getFragmentData(OrgMemberInvite_QueryFragment, props.query);
  const { t } = useTranslation("OrgMemberInvite");
  const { t: g } = useTranslation();

  const [body, setBody] = useState<{
    phone: string;
    jobTitle: string;
    permissions: OrgMemberPermission[];
  }>({
    phone: "",
    jobTitle: "",
    permissions: [],
  });

  const phoneRef = useRef<SectionListMenuItem>(null);
  const jobTitleRef = useRef<SectionListMenuItem>(null);

  const [errors, setErrors] = useState<Partial<InputErrors>>({});
  useEffect(() => {
    errors.phone && phoneRef.current?.shake();
    errors.jobTitle && jobTitleRef.current?.shake();
  }, [errors.jobTitle, errors.phone]);

  const [inviteMember, { loading, data }] = useMutation(
    InviteOrgMember_Mutation,
    {
      refetchQueries: [OrgMembers_QueryDocument],
    }
  );

  useEffect(() => {
    data && router.back();
  }, [data]);

  const submit = useCallback(() => {
    const errors: InputErrors = {};

    if (!body.phone) errors.phone = t("phone.empty");
    else if (!isPossiblePhoneNumber("+" + body.phone))
      errors.phone = t("phone.invalid");
    if (!body.jobTitle) errors.jobTitle = t("jobTitle.empty");

    setErrors(errors);

    if (Object.keys(errors).length) return;

    query?.org &&
      inviteMember({
        variables: {
          orgId: query.org.id,
          phone: body.phone,
          jobTitle: body.jobTitle,
          permissions: body.permissions,
        },
      });
  }, [body.jobTitle, body.permissions, body.phone, inviteMember, query, t]);

  const formatedPhone = useMemo(() => {
    return formatIncompletePhoneNumber(
      body.phone.length
        ? /^\+/.test(body.phone)
          ? body.phone
          : "+" + body.phone
        : ""
    );
  }, [body.phone]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "org",
        data: [getOrgListItem({ org: query?.org })],
      },
      {
        key: "phone",
        title: t("phone.label"),
        error: errors.phone,
        data: [
          {
            key: "phone",
            ref: phoneRef,
            type: "textInput",
            textInputProps: {
              value: formatedPhone,
              onChangeText: (phone) => {
                setErrors({ ...errors, phone: undefined });
                setBody({ ...body, phone: parseDigits(phone) });
              },
              keyboardType: "phone-pad",
              textContentType: "telephoneNumber",
            },
          },
        ],
      },
      {
        key: "jobTitleEdit",
        title: t("jobTitle.label"),
        error: errors.jobTitle,
        data: [
          {
            key: "jobTitle",
            ref: jobTitleRef,
            type: "textInput",
            textInputProps: {
              value: body.jobTitle,
              onChangeText: (jobTitle) => {
                setErrors({ ...errors, jobTitle: undefined });
                setBody({ ...body, jobTitle });
              },
            },
          },
        ],
      },
      getOrgMemberAdminFeaturesSection({
        org: query?.org,
        edit: true,
        permissions: body.permissions,
        onChangePermissions(permissions) {
          setBody({ ...body, permissions });
        },
      }),
      {
        key: "invite",
        data: [
          {
            key: "invite",
            buttonType: "primary",
            title: g("invite"),
            centerContent: true,
            loading,
            disabled: !query,
            onPress: submit,
          },
        ],
      },
    ];
  }, [query, t, errors, formatedPhone, body, g, loading, submit]);

  return <SectionListMenu sections={sections} />;
}
