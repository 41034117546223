[data-input],
[data-input] + div,
[data-input] + div + div > div {
  transition: border-color 0.15s, color 0.15s;
}

[data-input]:hover + div {
  border-color: var(--color-primary) !important;
}

[data-input]:hover + div + div > div {
  color: var(--color-primary) !important;
}
