import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { CampaignList } from "components/Campaign/CampaignList";

const ControlCapaigns_Query = gql(`
  query ControlAdCmpaigns_Query($orgId: ObjectID) {
    ...CampaignList_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(ControlCapaigns_Query);

  return <CampaignList query={data} showOrgName />;
}
