import { StyleSheet, Text, View } from "react-native";
import { Title } from "../common/Title";
import { Safe } from "../common/Safe";
import "./index.css";

import CoverageIcon from "./icons/coverage.svg";
import CheapIcon from "./icons/cheap.svg";
import HighQualityIcon from "./icons/high-quality.svg";
import _24Icon from "./icons/24-7.svg";
import MonitoringIcon from "./icons/monitoring.svg";
import AnalyticsIcon from "./icons/analytics.svg";
import { cssClass } from "utils/css";
import { darkColor, darkSecondaryColor } from "../Constants";
import { Section } from "../common/Section";

export const Benefits = () => {
  return (
    <Section style={styles.container}>
      <Safe>
        <Title>Преимущества рекламы в такси</Title>
        <View style={[cssClass("benefits-items"), styles.items]}>
          {[
            {
              Icon: CoverageIcon,
              title: "Большой охват",
              description:
                "100 авто = 120 000 контактов в месяц. Аэропорт, вокзалы, дворы жилых домов, бизнес-центры, центр города.",
            },
            {
              Icon: CheapIcon,
              title: "Низкая стоимость",
              description:
                "Цена контакта - CPT 5 копеек. Пакетные размещения - от 1 500 руб.",
            },
            {
              Icon: HighQualityIcon,
              title: "Качественная ЦА",
              description:
                "И главное – платежеспособная. Средний возраст – 25-45 лет.",
            },
            {
              Icon: _24Icon,
              title: "Работает 24/7",
              description:
                "Реклама в такси работает 24 часа в сутки и 7 дней в неделю, без перерывов и выходных.",
            },
            {
              Icon: MonitoringIcon,
              title: "Онлайн-мониторинг",
              description:
                "В режиме реального времени вы сможете отслеживать маршрут вашей рекламы по городу.",
            },
            {
              Icon: AnalyticsIcon,
              title: "Медиааналитика",
              description:
                "Вам будут предоставлены аналитические отчеты по итогам рекламной кампании.",
            },
          ].map((_, index) => (
            <View key={index} style={[cssClass("benefits-item"), styles.item]}>
              <View style={styles.itemContainer}>
                <View style={styles.itemIcon}>{_.Icon && <_.Icon />}</View>
                <Text style={[styles.itemTitle, !index && { marginTop: -6 }]}>
                  {_.title}
                </Text>
                <Text style={styles.itemDescription}>{_.description}</Text>
              </View>
            </View>
          ))}
        </View>
      </Safe>
    </Section>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    paddingTop: 0,
  },
  items: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: -8,
  },
  item: {
    width: `${100 / 3}%`,
    padding: 8,
  },
  itemContainer: {
    gap: 16,
    maxWidth: 300,
  },
  itemIcon: {},
  itemTitle: {
    fontWeight: "600",
    fontSize: 24,
    color: darkColor,
  },
  itemDescription: {
    fontSize: 16,
    color: darkSecondaryColor,
  },
});
