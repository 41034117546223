import { StyleSheet, ViewProps } from "react-native";
import Animated, {
  FadeIn,
  FadeOut,
  LinearTransition,
} from "react-native-reanimated";

export const AnimatedCell = (props: ViewProps) => {
  return (
    <Animated.View
      {...props}
      layout={LinearTransition}
      entering={FadeIn}
      exiting={FadeOut}
      style={styles.container}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: "100%",
    marginHorizontal: -16,
    paddingHorizontal: 16,
  },
});
