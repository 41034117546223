import { FragmentType, getFragmentData, gql } from "__generated__";
import { OrgMemberPermission } from "__generated__/graphql";
import { SectionListMenu, Switch } from "components/common";
import { getFixedT } from "i18next";

export const permissionKeys: OrgMemberPermission[] = [
  OrgMemberPermission.CarsManagement,
  OrgMemberPermission.CampaignsManagement,
  OrgMemberPermission.MembersManagement,
];

const GetAdminFeaturesSection_OrgMemberFragment = gql(`
  fragment GetAdminFeaturesSection_OrgMemberFragment on Org {
    member {
        id
        permissions
    }
  }
`);

type GetOrgMemberAdminFeaturesSectionProps = {
  org?: FragmentType<typeof GetAdminFeaturesSection_OrgMemberFragment> | null;
  permissions?: OrgMemberPermission[];
  onChangePermissions?: (permissions: OrgMemberPermission[]) => void;
  edit?: boolean;
};

export const getOrgMemberAdminFeaturesSection = (
  props: GetOrgMemberAdminFeaturesSectionProps
): SectionListMenu => {
  const org = getFragmentData(
    GetAdminFeaturesSection_OrgMemberFragment,
    props?.org
  );
  const t = getFixedT(null, "getOrgMemberAdminFeaturesSection");

  const availablePermissions = org?.member?.permissions || [];

  return {
    key: "adminFeatures",
    title: t("title"),
    visible: !!props.edit || !!props.permissions?.length,
    description: props.permissions
      ? t(
          props.permissions.includes(OrgMemberPermission.MembersManagement)
            ? "membersManagement.enabled"
            : "membersManagement.disabled"
        )
      : undefined,
    data: props.edit
      ? permissionKeys.map((key) => ({
          key: key,
          title: t(`permission.${key}`),
          disabled: !availablePermissions.includes(key),
          TrailingComponent: (
            <Switch
              value={props.permissions?.includes(key)}
              onValueChange={(value) => {
                props.permissions &&
                  props.onChangePermissions?.(
                    value
                      ? [...props.permissions, key]
                      : props.permissions.filter((p) => p !== key)
                  );
              }}
              disabled={!availablePermissions.includes(key)}
            />
          ),
        }))
      : props.permissions?.map((key) => ({
          key: key,
          title: t(`permission.${key}`),
        })) || [],
  };
};
