import { gql } from "__generated__";

export const RemoveCarDriver_Mutation = gql(`
  mutation RemoveCarDriver_Mutation($carId: ObjectID!, $memberId: ObjectID!) {
    removeCarDriver(carId: $carId, memberId: $memberId) {
      id
      drivers {
        id
      }
    }
  }
`);
