import { useSelector } from "react-redux";
import { selectApiUrl } from "store/settings";
import { StyleSheet, Text, View } from "./common";
import config from "config";
import Constants from "expo-constants";
import * as Application from "expo-application";
import { channel } from "expo-updates";

type AppInfoProps = {
  showBuildVersion?: boolean;
  showReleaseChannel?: boolean;
};
export const AppInfo = ({
  showBuildVersion,
  showReleaseChannel,
}: AppInfoProps) => {
  const apiUrl = useSelector(selectApiUrl);

  return (
    <View style={styles.container}>
      <Text style={styles.text} colorName="textSecondary">
        v{Constants.expoConfig?.version?.replace(/\.0$/, "")}
        {showBuildVersion &&
          (Application.nativeBuildVersion
            ? `(${Application.nativeBuildVersion})`
            : "")}
      </Text>
      {showReleaseChannel && process.env.NODE_ENV === "production" && (
        <Text style={styles.text} colorName="textSecondary">
          Release channel: {channel || "none"}
        </Text>
      )}
      {config.api.production !== apiUrl && (
        <Text style={styles.text} colorName="textSecondary">
          {apiUrl.replace(/^https:\/\//, "")}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  text: {
    textAlign: "center",
    fontSize: 12,
  },
});
