import { useMutation } from "@apollo/client";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { OrgMemberInviteStatus } from "__generated__/graphql";
import { Button, StyleSheet, Text, View } from "components/common";
import { useTranslation } from "react-i18next";
import {
  AcceptOrgMemberInvite_Mutation,
  RejectOrgMemberInvite_Mutation,
} from "./mutations";
import { refetchQueries } from "utils/refetchQueries";
import { dispatch } from "store";
import { setCurrentOrgId } from "store/settings";

const ListItemFooter_OrgFragment = gql(`
  fragment ListItemFooter_OrgFragment on Org {
    id
    name
    member {
        id
        inviteStatus
        inviter {
            id
            firstName
            lastName
        }
    }
  }
`);

type OrgListItemFooterProps = {
  org?: FragmentType<typeof ListItemFooter_OrgFragment> | null;
};

export const OrgListItemFooter = (props: OrgListItemFooterProps) => {
  const org = getFragmentData(ListItemFooter_OrgFragment, props?.org);
  const { t } = useTranslation("OrgListItemFooter");

  const [acceptOrgMemberInvite, { loading: acceptLoading }] = useMutation(
    AcceptOrgMemberInvite_Mutation,
    {
      refetchQueries: refetchQueries.Org,
      onCompleted() {
        org && dispatch(setCurrentOrgId(org.id));
      },
    }
  );
  const [rejectOrgMemberInvite, { loading: rejectLoading }] = useMutation(
    RejectOrgMemberInvite_Mutation,
    { refetchQueries: refetchQueries.Org }
  );

  if (org?.member?.inviteStatus !== OrgMemberInviteStatus.Pending) return null;

  return (
    <View style={styles.itemFooter}>
      {org.member.inviter && (
        <Text style={styles.itemFooterText} colorName="textSecondary">
          {t("invitedYouToJoin", {
            inviterName: [
              org.member.inviter.firstName,
              org.member.inviter.lastName,
            ].join(" "),
          })}
        </Text>
      )}
      <View style={styles.itemFooterButtons}>
        <Button
          style={styles.itemFooterButton}
          type="destructive"
          text={t("reject")}
          loading={rejectLoading}
          disabled={rejectLoading || acceptLoading}
          onPress={() => {
            rejectOrgMemberInvite({
              variables: { orgId: org.id },
            });
          }}
        />
        <Button
          style={styles.itemFooterButton}
          type="semitransparent"
          text={t("join")}
          loading={acceptLoading}
          disabled={rejectLoading || acceptLoading}
          onPress={() => {
            acceptOrgMemberInvite({
              variables: { orgId: org.id },
            });
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemFooter: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    paddingTop: 8,
    gap: 16,
  },
  itemFooterText: {
    textAlign: "center",
  },
  itemFooterButtons: {
    flexDirection: "row",
    gap: 16,
  },
  itemFooterButton: {
    flex: 1,
  },
});
