import { SectionListMenuItemProps } from "components/common";
import { Link, router } from "expo-router";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import SliderHorizontalIcon from "assets/icons/slider-horizontal.svg";
import { SectionListMenu } from "components/common";
import { DimensionValue } from "react-native";
import useActionSheet, { UseActionSheetValue } from "hooks/useActionSheet";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { OrgHeader } from "./OrgHeader";
import { useMutation } from "@apollo/client";
import { RemoveOrgMember_Mutation } from "components/OrgMember/mutations";

const shimmerMaxWidth: { [key: string]: DimensionValue } = {
  tin: 90,
  entityTypeName: 160,
  activityTypeName: 295,
  registrationAuthorityName: "100%",
  registrationDate: 85,
  statusName: 115,
};

export const Org_QueryFragment = gql(`
  fragment Org_QueryFragment on Query {
    org(id: $id) {
      id
      name
      tin
      entityTypeName
      activityTypeName
      registrationAuthorityName
      registrationDate
      statusName
      member {
        id
        role
        inviteStatus
      }
      ...Header_OrgFragment
    }
  }
`);

export type OrgProps = {
  query?: FragmentType<typeof Org_QueryFragment>;
};

export const Org = (props: OrgProps) => {
  const query = getFragmentData(Org_QueryFragment, props.query);

  const { t } = useTranslation("Org");

  const [removeMember, { loading: isLoadingRemoveMember }] = useMutation(
    RemoveOrgMember_Mutation,
    {
      onCompleted() {
        router.back();
      },
    }
  );

  const leaveActions = useMemo(
    (): UseActionSheetValue[] => [
      {
        text: t("leaveOrg"),
        destructive: true,
        onPress: () => {
          query?.org?.member &&
            removeMember({ variables: { id: query?.org?.member.id } });
        },
      },
    ],
    [query?.org?.member, removeMember, t]
  );

  const showLeaveConfirm = useActionSheet(leaveActions);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "info",
        data: (
          [
            "tin",
            "entityTypeName",
            "activityTypeName",
            "registrationAuthorityName",
            "registrationDate",
            "statusName",
          ] as const
        ).map(
          (key): SectionListMenuItemProps => ({
            key,
            label: t(key),
            title:
              key === "registrationDate"
                ? moment(query?.org?.[key]).format("DD.MM.YYYY")
                : query?.org?.[key] || "",
            loading: !query?.org?.[key],
            shimmerMaxWidth: shimmerMaxWidth[key],
          })
        ),
      },
      {
        key: "actions",
        data: [
          {
            key: "settings",
            visible: query?.org?.member?.role === "owner",
            leadingIconColor: "#8f8e95",
            LeadingIcon: SliderHorizontalIcon,
            title: t("settings"),
            Link: <Link href={`/org/${query?.org?.id}/settings`} />,
          },
        ],
      },
      {
        key: "leave",
        visible: query?.org?.member?.inviteStatus === "joined",
        description:
          query?.org?.member?.role === "owner"
            ? t("leaveOrgOwnerDescription")
            : undefined,
        data: [
          {
            key: "leave",
            disabled: query?.org?.member?.role === "owner",
            title: t("leaveOrg"),
            buttonType: "destructive",
            centerContent: true,
            loading: isLoadingRemoveMember,
            onPress: () => {
              showLeaveConfirm();
            },
          },
        ],
      },
    ];
  }, [isLoadingRemoveMember, query?.org, showLeaveConfirm, t]);

  return (
    <SectionListMenu
      sections={sections}
      ListHeaderComponent={<OrgHeader org={query?.org} />}
    />
  );
};
