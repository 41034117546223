import { useMemo } from "react";
import { SectionListMenu, usePickerCallback } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getOrgListItem } from "./getOrgListItem";
import { Link } from "expo-router";
import { useTranslation } from "react-i18next";

const OrgList_QueryFragment = gql(`
  fragment OrgList_QueryFragment on Query {
    orgs(member: $member) {
      ...GetListItem_OrgFragment
    }
  }
`);

type OrgListProps = {
  query?: FragmentType<typeof OrgList_QueryFragment>;
  picker?: boolean;
};

export const OrgList = (props: OrgListProps) => {
  const query = getFragmentData(OrgList_QueryFragment, props.query);
  const onPick = usePickerCallback(props.picker);
  const { t } = useTranslation("OrgList");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "add",
        data: [
          {
            key: "add",
            title: t("add"),
            centerContent: true,
            buttonType: "primary",
            Link: <Link href="/org/add/" />,
          },
        ],
      },
      {
        key: "orgs",
        data: query?.orgs?.map((org) =>
          getOrgListItem({
            org,
            onPick,
          })
        ) || [getOrgListItem()],
      },
    ];
  }, [onPick, query?.orgs, t]);

  return <SectionListMenu sections={sections} />;
};
