import { Link } from "expo-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SectionListMenu } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getAdListItem } from "./getAdListItem";

const AdList2_QueryFragment = gql(`
  fragment AdList2_QueryFragment on Query {
    campaign(id: $id) {
        id
        name
        org {
            id
            name
        }
        ads {
          ...GetListItem_AdFragment
        }
    }
  }
`);

type AdList2Props = {
  query?: FragmentType<typeof AdList2_QueryFragment>;
};

export const AdList2 = (props: AdList2Props) => {
  const query = getFragmentData(AdList2_QueryFragment, props.query);

  const { t } = useTranslation("AdList2");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "info",
        data: [
          {
            key: "org",
            Link: <Link href={`/org/${query?.campaign?.org.id}/`} />,
            label: t("org"),
            title: query?.campaign?.org.name,
          },
          {
            key: "campaign",
            Link: <Link href={`/campaign/${query?.campaign?.id}/`} />,
            label: t("campaign"),
            title: query?.campaign?.name,
          },
        ],
      },
      {
        key: "ads",
        data: query?.campaign?.ads?.map((ad) => getAdListItem({ ad })) || [
          getAdListItem(),
        ],
      },
    ];
  }, [
    query?.campaign?.id,
    query?.campaign?.name,
    query?.campaign?.org.id,
    query?.campaign?.org.name,
    query?.campaign?.ads,
    t,
  ]);

  return <SectionListMenu sections={sections} />;
};
