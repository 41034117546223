import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { AdList2 } from "components/Ad/AdList2";
import { HeaderButton } from "components/common";
import { Stack, useLocalSearchParams } from "expo-router";
import { useTranslation } from "react-i18next";
import { useAdDraft } from "store/drafts";

const Ads_Query = gql(`
  query Ads_Query($id: ObjectID!) {
    ...AdList2_QueryFragment
    campaign(id: $id) {
      id
      org {
        id
      }
    }
  }
`);

export default function Page() {
  const { id } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(Ads_Query, { variables: { id } });
  const { t: g } = useTranslation();
  const [, setDraft] = useAdDraft();

  return (
    <>
      <Stack.Screen
        options={{
          headerRight: data
            ? () => (
                <HeaderButton
                  text={g("add")}
                  href={"/ad/create"}
                  onPress={() => {
                    setDraft({
                      campaignId: data?.campaign?.id || "",
                      orgId: data?.campaign?.org.id || "",
                    });
                  }}
                />
              )
            : undefined,
        }}
      />
      <AdList2 query={data} />
    </>
  );
}
