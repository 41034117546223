import { StyleSheet, View, ViewProps } from "react-native";

export const Section = ({ style, ...rest }: ViewProps) => {
  return <View style={[styles.container, style]} {...rest} />;
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 94,
    paddingHorizontal: 32,
  },
});
