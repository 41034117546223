import { persistReducer } from "redux-persist";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { storage } from "utils/storage";

export type AuthState = Readonly<{
  token?: string;
  signUpRequired?: boolean;
  pendingSignOutToken?: string;
  signIn?: {
    phone: string;
    codeLength: number;
    code?: string;
  };
}>;

const initialState: AuthState = {};

export const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setAuth: (
      state,
      action: PayloadAction<{ token: string; signUpRequired: boolean }>
    ) => {
      state.token = action.payload.token;
      state.signUpRequired = action.payload.signUpRequired;
    },
    clearPendingSignOutToken: (state) => {
      state.pendingSignOutToken = undefined;
    },
    authSignOut: (state) => {
      state.pendingSignOutToken = state.token;
      state.token = undefined;
    },
    setSignIn: (state, action: PayloadAction<AuthState["signIn"]>) => {
      state.signIn = action.payload;
    },
    setSignUpRequired: (state, action: PayloadAction<boolean>) => {
      state.signUpRequired = action.payload;
    },
  },
});

export const {
  setAuth,
  clearPendingSignOutToken,
  authSignOut,
  setSignIn,
  setSignUpRequired,
} = settings.actions;

export const selectToken = (state: RootState) => state.auth.token;
export const selectPendingSignOutToken = (state: RootState) =>
  state.auth.pendingSignOutToken;
export const selectSignIn = (state: RootState) => state.auth.signIn;
export const selectSignUpRequired = (state: RootState) =>
  state.auth.signUpRequired;

export default {
  reducer: persistReducer(
    {
      key: "auth",
      storage,
      blacklist: ["auth"],
    },
    settings.reducer
  ),
};
