import { SectionListMenu } from "components/common";
import { Link } from "expo-router";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import LogoIcon from "assets/icons/logo.svg";
import HornIcon from "assets/icons/horn.svg";
import PersonsIcon from "assets/icons/persons.svg";
import CarIcon from "assets/icons/car.svg";
import BuildingIcon from "assets/icons/building.svg";
import GearIcon from "assets/icons/gear.svg";
import { ControlHeader } from "./ControlHeader";

export const Control = () => {
  const { t } = useTranslation("Control");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "ads",
        title: t("advertisement"),
        data: [
          {
            key: "ads",
            leadingIconColor: "#FF5120",
            LeadingIcon: LogoIcon,
            title: t("ads"),
            Link: <Link href="/control/ads" />,
          },
          {
            key: "campaigns",
            leadingIconColor: "#ff9503",
            LeadingIcon: HornIcon,
            title: t("campaigns"),
            Link: <Link href="/control/campaigns" />,
          },
          {
            key: "cars",
            leadingIconColor: "#5755d5",
            LeadingIcon: CarIcon,
            title: t("cars"),
            Link: <Link href="/control/cars" />,
          },
        ],
      },
      {
        key: "orgs",
        data: [
          {
            key: "orgs",
            leadingIconColor: "#8f8e93",
            LeadingIcon: BuildingIcon,
            title: t("orgs"),
            Link: <Link href="/control/orgs" />,
          },
        ],
      },
      {
        key: "users",
        data: [
          {
            key: "users",
            leadingIconColor: "#007aff",
            LeadingIcon: PersonsIcon,
            title: t("users"),
            Link: <Link href="/control/users" />,
          },
        ],
      },
      {
        key: "settings",
        data: [
          {
            key: "settings",
            leadingIconColor: "#8f8e95",
            LeadingIcon: GearIcon,
            title: t("settings"),
            Link: <Link href="/control/settings" />,
          },
        ],
      },
    ];
  }, [t]);

  return (
    <SectionListMenu sections={sections} ListHeaderComponent={ControlHeader} />
  );
};
