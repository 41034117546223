import { router } from "expo-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SectionListMenu } from "components/common";
import useActionSheet, { UseActionSheetValue } from "hooks/useActionSheet";
import { gql, getFragmentData } from "__generated__";
import { OrgProps, Org_QueryFragment } from "./Org";
import { OrgHeader } from "./OrgHeader";
import { useMutation } from "@apollo/client";

const DeleteOrg_Mutation = gql(`
  mutation DeleteOrg_Mutation($id: ObjectID!) {
    deleteOrg(id: $id)
  }
`);

export const OrgSettings = (props: OrgProps) => {
  const query = getFragmentData(Org_QueryFragment, props.query);
  const { t } = useTranslation("Org");

  const [deleteOrg, { loading: deleteLoading }] = useMutation(
    DeleteOrg_Mutation,
    {
      update(cache) {
        cache.evict({ id: `Org:${query?.org?.id}` });
      },
      onCompleted() {
        router.back();
      },
    }
  );

  const deleteActions = useMemo(
    (): UseActionSheetValue[] => [
      {
        text: t("deleteOrg"),
        destructive: true,
        onPress: () => {
          deleteOrg({ variables: { id: query?.org?.id || "" } });
        },
      },
    ],
    [deleteOrg, query?.org?.id, t]
  );
  const showDeleteConfirm = useActionSheet(deleteActions, {
    title: t("deleteOrgConfirm.title"),
    message: t("deleteOrgConfirm.description", {
      orgName: query?.org?.name,
    }),
  });

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "delete",
        data: [
          {
            key: "delete",
            title: t("deleteOrg"),
            buttonType: "destructive",
            centerContent: true,
            loading: deleteLoading,
            onPress: showDeleteConfirm,
          },
        ],
      },
    ];
  }, [deleteLoading, showDeleteConfirm, t]);

  return (
    <SectionListMenu
      sections={sections}
      ListHeaderComponent={<OrgHeader org={query?.org} />}
    />
  );
};
