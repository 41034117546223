import { DeepPartial } from "@apollo/client/utilities";
import {
  BlurView,
  Image,
  Shimmer,
  StyleSheet,
  Upload,
  UploadProgressIndicator,
  View,
  useColors,
  useUploads,
} from "./common";
import { useEffect, useMemo, useState } from "react";
import { useAssetPicker } from "hooks/useAssetPicker";
import PlusIcon from "assets/icons/plus.svg";
import { RectButton } from "./common/Themed";
import { cloudinary } from "utils/cloudinary";
import { Resize } from "@cloudinary/url-gen/actions";
import Animated, {
  FadeIn,
  LinearTransition,
  ZoomIn,
  ZoomOut,
} from "react-native-reanimated";
import { Link } from "expo-router";
import MinusIcon from "assets/icons/minus.svg";

export type Attachment = {
  public_id: string;
  thumbhash?: string;
};

type AttachmentsProps = {
  attachments?: Attachment[];
  onChange?: (attachments: Attachment[]) => void;
  editable?: boolean;
  loading?: boolean;
  onUploadingStateChange?: (uploading: boolean) => void;
};
export const Attachments = ({
  attachments = [],
  onChange,
  editable,
  loading,
  onUploadingStateChange,
}: AttachmentsProps) => {
  const colors = useColors();

  const [completedIds, setCompletedIds] = useState<
    { id: string; public_id: string }[]
  >([]);
  const [uploads, upload] = useUploads("attachments");

  useEffect(() => {
    const added = uploads
      .filter((upload) => completedIds.some((_) => _.id === upload.id))
      .filter((upload) => {
        const public_id = completedIds.find(
          (_) => _.id === upload.id
        )?.public_id;
        const attachment = attachments.find((a) => a?.public_id === public_id);
        if (attachment) {
          upload.remove();
          return false;
        } else {
          return true;
        }
      })
      .map((upload) => ({
        public_id: completedIds.find((_) => _.id === upload.id)
          ?.public_id as string,
      }));

    added.length && onChange?.([...added, ...attachments]);
  }, [attachments, completedIds, onChange, uploads]);

  const items = useMemo((): {
    key: string;
    asset?: DeepPartial<Attachment>;
    upload?: Upload;
  }[] => {
    const _attachments = attachments.map((asset) => ({
      key:
        completedIds.find((_) => _.public_id === asset?.public_id)?.id ||
        asset?.public_id ||
        "",
      asset,
    }));

    return [
      ...uploads
        .map((upload) => ({ key: upload.id, upload }))
        .filter((_) => !_attachments.some((__) => __.key === _.key)),
      ..._attachments,
    ];
  }, [attachments, completedIds, uploads]);

  useEffect(() => {
    console.log(uploads.length > 0);
    onUploadingStateChange?.(uploads.length > 0);
  }, [items, onUploadingStateChange, uploads]);

  const [pick] = useAssetPicker({
    multiple: true,
    doc: { type: "application/pdf" },
  });

  return (
    <View style={styles.container}>
      {(editable || loading) && (
        <Animated.View
          style={styles.item}
          entering={FadeIn}
          exiting={ZoomOut}
          layout={LinearTransition}
        >
          <View style={styles.itemContainer} colorName="card">
            <PlusIcon
              style={styles.icon}
              color={colors.textSecondary}
              width={50}
              height={50}
            />
            {loading && <Shimmer style={styles.image} />}
            {!loading && (
              <RectButton
                style={styles.button}
                onPress={() => {
                  pick().then((assets) => {
                    assets.forEach((asset) => {
                      upload(asset, false).then(({ data, upload }) => {
                        if (!data) return;
                        setCompletedIds((prev) => [
                          ...prev,
                          { id: upload.id, public_id: data.public_id },
                        ]);
                      });
                    });
                  });
                }}
              />
            )}
          </View>
        </Animated.View>
      )}
      {items.map((_) => {
        const href = cloudinary
          .image(_.asset?.public_id)
          .toURL() as `${string}:${string}`;
        const uri =
          _.upload?.asset.uri ||
          cloudinary
            .image(_.asset?.public_id)
            .resize(Resize.auto(200, 200))
            .toURL();

        return (
          <Animated.View
            key={_.key}
            style={styles.item}
            entering={FadeIn}
            exiting={ZoomOut}
            layout={LinearTransition}
          >
            <View style={styles.itemContainer} colorName="card">
              <Image
                style={styles.image}
                placeholder={{ thumbhash: _.asset?.thumbhash }}
                source={{ uri }}
              />
              <Link style={styles.button} href={href} target="_blank">
                <RectButton style={styles.button} />
              </Link>
              <UploadProgressIndicator upload={_.upload} />
              {!_.upload && editable && (
                <Animated.View
                  style={styles.remove}
                  entering={ZoomIn}
                  exiting={ZoomOut}
                >
                  <BlurView style={styles.removeBlur} tint="dark">
                    <RectButton
                      style={styles.removeButton}
                      onPress={() => {
                        onChange?.(
                          attachments.filter(
                            (a) => a?.public_id !== _.asset?.public_id
                          )
                        );
                      }}
                    >
                      <MinusIcon color="white" />
                    </RectButton>
                  </BlurView>
                </Animated.View>
              )}
            </View>
          </Animated.View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginHorizontal: -4,
  },
  item: {
    minWidth: 90,
    width: `${100 / 4}%`,
    aspectRatio: 1,
    padding: 4,
  },
  itemContainer: {
    flex: 1,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    ...StyleSheet.absoluteFillObject,
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 8,
  },
  icon: {
    opacity: 0.7,
  },

  remove: {
    position: "absolute",
    width: 26,
    height: 26,
    borderRadius: 26 / 2,
    overflow: "hidden",
    left: -6,
    top: -6,
  },
  removeBlur: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 26 / 2,
    overflow: "hidden",
  },
  removeButton: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
});
