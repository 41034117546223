import { SectionListMenu } from "components/common";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectDevice } from "store/device";
import { typedObjectEntries } from "utils/obect";

export default function Page() {
  const device = useSelector(selectDevice);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "items",
        data: typedObjectEntries(device)
          .filter(([key]) => key !== "_persist")
          .map(([key, value]) => ({
            key,
            label: key,
            title: typeof value === "string" ? value : JSON.stringify(value),
          })),
      },
    ];
  }, [device]);

  return <SectionListMenu sections={sections} />;
}
