import { SectionListMenu } from "components/common";
import { Link, router, useLocalSearchParams } from "expo-router";
import useActionSheet, { UseActionSheetValue } from "hooks/useActionSheet";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import UserAdd from "assets/icons/user-add.svg";
import { gql, getFragmentData } from "__generated__";
import { CarProps, Car_QueryFragment } from "./Car";
import { CarHeader } from "./CarHeader";
import { useMutation } from "@apollo/client";
import { RemoveCarDriver_Mutation } from "./mutations";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { getOrgMemberListItem } from "components/OrgMember/getOrgMemberListItem";

const DeleteCar_Mutation = gql(`
  mutation DeleteCar_Mutation($id: ObjectID!) {
    deleteCar(id: $id)
  }
`);

export const CarEdit = (props: CarProps) => {
  const query = getFragmentData(Car_QueryFragment, props.query);
  const { id } = useLocalSearchParams<{ id: string }>();

  const { t: g } = useTranslation();
  const { t } = useTranslation("Car");

  const [deleteSpace, { loading: isLoadingRemove }] = useMutation(
    DeleteCar_Mutation,
    {
      update(cache) {
        cache.evict({ id: `Car:${id}` });
      },
      onCompleted() {
        router.back();
      },
    }
  );

  const removeActions = useMemo(
    (): UseActionSheetValue[] => [
      {
        text: t("deleteSpace"),
        destructive: true,
        onPress: () => {
          deleteSpace({ variables: { id } });
        },
      },
    ],
    [deleteSpace, id, t]
  );
  const showRemoveConfirm = useActionSheet(removeActions);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "org",
        data: [getOrgListItem({ org: query?.car?.org })],
      },
      {
        key: "info",
        data: [
          {
            key: "plate",
            label: t("plate"),
            loading: !query,
            title: query?.car?.plate,
          },
          {
            key: "year",
            label: t("year"),
            loading: !query,
            title: query?.car?.year || undefined,
          },
        ],
      },
      {
        key: "drivers",
        title: t("drivers"),
        data: [
          {
            key: "add",
            LeadingIcon: UserAdd,
            buttonType: "primary",
            title: g("add"),
            Link: query?.car ? (
              <Link href={`/car/${query.car.id}/driver/add`} />
            ) : undefined,
          },
          ...(query?.car?.drivers?.map((member) =>
            getOrgMemberListItem({
              member,
              editable: true,
              removeMutation: query.car
                ? {
                    doc: RemoveCarDriver_Mutation,
                    options: {
                      variables: {
                        carId: query.car.id,
                        memberId: member.id,
                      },
                    },
                  }
                : undefined,
            })
          ) || [getOrgMemberListItem()]),
        ],
      },
      {
        key: "delete",
        data: [
          {
            key: "delete",
            style: { marginTop: 16 },
            buttonType: "destructive",
            title: t("deleteSpace"),
            centerContent: true,
            loading: isLoadingRemove,
            disabled: !query,
            onPress: showRemoveConfirm,
          },
        ],
      },
    ];
  }, [g, isLoadingRemove, query, showRemoveConfirm, t]);

  return (
    <SectionListMenu
      sections={sections}
      ListHeaderComponent={<CarHeader car={query?.car} />}
    />
  );
};
