import { useMemo } from "react";
import { SectionListMenu } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getUserListItem } from "./getUserListItem";
import { useTranslation } from "react-i18next";
import { Link } from "expo-router";

const UserList_QueryFragment = gql(`
  fragment UserList_QueryFragment on Query {
    users {
      ...GetListItem_UserFragment
    }
  }
`);

type UserListProps = {
  query?: FragmentType<typeof UserList_QueryFragment>;
};

export const UserList = (props: UserListProps) => {
  const query = getFragmentData(UserList_QueryFragment, props.query);
  const { t } = useTranslation("UserList");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "add",
        data: [
          {
            key: "add",
            buttonType: "primary",
            centerContent: true,
            title: t("invite"),
            Link: <Link href="/user/invite" />,
          },
        ],
      },
      {
        key: "users",
        data: query?.users?.map((user) => getUserListItem({ user })) || [
          getUserListItem(),
        ],
      },
    ];
  }, [query?.users, t]);

  return <SectionListMenu sections={sections} />;
};
