import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { CarList } from "components/Car/CarList";

const ControlCars_Query = gql(`
  query ControlCars_Query($orgId: ObjectID) {
    ...CarList_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(ControlCars_Query);

  return <CarList query={data} showOrgName />;
}
