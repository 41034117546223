import { StyleSheet, View } from "react-native";
import { Text } from "../Themed";

export const UserContent = ({
  title,
  subtitle: _subtitle,
  loading,
  numberOfLines = 1,
}: {
  title?: string;
  subtitle?:
    | (JSX.Element | string | number)
    | (JSX.Element | string | number | false | undefined)[];
  loading?: boolean;
  numberOfLines?: number;
}) => {
  const subtitle = _subtitle
    ? Array.isArray(_subtitle)
      ? _subtitle
      : [_subtitle]
    : undefined;

  return (
    <View style={styles.user}>
      <Text
        style={styles.userTitle}
        numberOfLines={numberOfLines}
        loading={loading}
        shimmerMaxWidth={155}
      >
        {title}
      </Text>
      <View>
        {!subtitle ? (
          <Text
            style={styles.userSubtitle}
            colorName="textSecondary"
            loading={loading}
            shimmerMaxWidth={130}
          />
        ) : (
          subtitle?.map(
            (_) =>
              _ &&
              (typeof _ === "string" || typeof _ === "number" ? (
                <Text
                  key={_}
                  style={styles.userSubtitle}
                  colorName="textSecondary"
                  loading={loading}
                  shimmerMaxWidth={90}
                >
                  {_}
                </Text>
              ) : (
                _
              ))
          )
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  user: {
    justifyContent: "space-between",
  },
  userTitle: {
    fontSize: 17,
    fontWeight: "500",
  },
  userSubtitle: {
    fontSize: 13,
  },
});
