import { Image, StyleSheet, View } from "react-native";
import { Title } from "../common/Title";
import { Safe } from "../common/Safe";
import "./index.css";
import { cssClass } from "utils/css";
import { Section } from "../common/Section";

import BackgroundImage from "./background.webp";

export const Brands = () => {
  return (
    <Section id="clients" style={styles.container}>
      <Safe>
        <Title>Мы размещали</Title>
      </Safe>
      <View style={styles.items}>
        <Image
          style={[cssClass("brands-item"), styles.item]}
          source={BackgroundImage}
          resizeMode="repeat"
        />
        <Image
          style={[cssClass("brands-item"), styles.item]}
          source={BackgroundImage}
          resizeMode="repeat"
        />
        <Image
          style={[cssClass("brands-item"), styles.item]}
          source={BackgroundImage}
          resizeMode="repeat"
        />
        <Image
          style={[cssClass("brands-item"), styles.item]}
          source={BackgroundImage}
          resizeMode="repeat"
        />
      </View>
    </Section>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  items: {
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
    marginHorizontal: -32,
  },
  item: {
    height: 330,
    width: 1500,
  },
});
