import { Image, StyleSheet, Text, View } from "react-native";
import { Title } from "../common/Title";
import { numberFormat } from "utils/format";
import { Safe } from "../common/Safe";
import "./index.css";
import { Section } from "../common/Section";
import { cssClass } from "utils/css";
import { darkSecondaryColor, primaryColor } from "../Constants";
import BackgroundImage from "./background.webp";

export const Stats = () => {
  return (
    <>
      <Section style={styles.container}>
        <Safe>
          <Title>Цифры говорят сами за себя</Title>
          <View style={[cssClass("stats-items"), styles.items]}>
            {[
              {
                count: 2024000,
                text: "человек население Минска",
              },
              { count: 719000, text: "человек ежемесячно пользуются такси" },
              { count: 930000, text: "машин ежедневно на дорогах Минска" },
            ].map((_, index) => (
              <View key={index} style={styles.item}>
                <Text style={styles.itemCount} numberOfLines={1}>
                  {numberFormat(_.count)}
                </Text>
                <Text style={[cssClass("stats-item-text"), styles.itemText]}>
                  {_.text}
                </Text>
              </View>
            ))}
          </View>
        </Safe>
      </Section>
      <Image
        style={styles.background}
        source={BackgroundImage}
        resizeMode="repeat"
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  items: {
    flexDirection: "row",
    gap: 32,
    justifyContent: "space-between",
  },
  item: {
    maxWidth: 240,
  },
  itemCount: {
    fontSize: 45,
    color: primaryColor,
    fontWeight: "600",
  },
  itemText: {
    fontSize: 20,
    color: darkSecondaryColor,
  },
  background: {
    backgroundColor: "white",
    height: 177,
    width: "100%",
    minWidth: 975,
    alignSelf: "center",
  },
});
