type EventCounterOptions = {
  threshold?: number;
  time?: number;
};
export function createEventCounter(
  callback: () => void,
  {
    threshold = process.env.NODE_ENV === "development" ? 3 : 10,
    time = process.env.NODE_ENV === "development" ? 600 : 2000,
  }: EventCounterOptions | undefined = {}
) {
  let clickCount = 0;
  let startTime = new Date().getTime();

  return function () {
    const currentTime = new Date().getTime();

    if (clickCount === 0 || currentTime - startTime > time) {
      // Если это первый клик или время между кликами превысило заданный интервал, сбрасываем счётчик и время
      clickCount = 1;
      startTime = currentTime;
    } else {
      clickCount++; // Увеличиваем счётчик, если клик в пределах временного интервала
    }

    if (clickCount >= threshold) {
      callback(); // Выполнение заданного действия после достижения порога кликов
      clickCount = 0; // Сброс счётчика после выполнения действия
    }
  };
}
