import { StackModal, modalCloseOptions } from "components/common";
import { Stack } from "expo-router";

export const unstable_settings = {
  initialRouteName: "index",
};

export default function AppLayout() {
  return (
    <StackModal>
      <Stack.Screen name="index" options={modalCloseOptions} />
    </StackModal>
  );
}
