@keyframes marquee {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -5040px 0;
  }
}

.app-brands-item > div {
  animation: marquee 60s linear infinite;
}
