export default {
  g: {
    isRTL: false,
    flag: "🇷🇺",
    langName: "Russian",
    langNameNative: "Русский",
    appName: "INCAR",
    inDev: "В разработке",
    cancel: "Отмена",
    cancel2: "Отменить",
    next: "Далее",
    continue: "Продолжить",
    finish: "Завершить",
    add: "Добавить",
    save: "Сохранить",
    edit: "Изм.",
    done: "Готово",
    invite: "Пригласить",
    loading: "Загрузка...",
    logout: "Выйти",
    search: "Поиск",
    hide: "Скрыть",
    choose: "Выбрать",
    notChoosen: "Не выбрано",
    filesUploading: "Загрузка файлов...",
    create: "Создать",
  },
  operations: {
    default: "Операция {{operationName}} выполнена",
    error: "Ошибка операции {{operationName}}\n{{message}}\n{{code}}",
    RegisterDevice_Mutation: false,
    UpdateUser_Mutation: "Профиль обновлен",
    SendCode_Mutation: {
      ESME_RINVDSTADR: false,
    },
    SignIn_Mutation: {
      OTP_NOT_FOUND: false,
      OTP_INVALID: false,
      OTP_EXPIRED: false,
      OTP_TOO_MANY_REQUESTS: false,
    },
    GetOrgInfo_Mutation: {
      ORG_INFO_NOT_FOUND: false,
    },
    AddOrg_Mutation: {
      ORG_EXISTS: "Организация с таким УНП уже существует",
    },
  },

  pages: {
    signIn: {
      title: "Вход",
      code: { title: "Код подтверждения" },
    },
    signUp: { title: "Регистрация" },
    report: { title: "Отчет" },

    control: {
      title: "Управление",
      campaigns: { title: "Рекламные кампании" },
      ads: { title: "Рекламные материалы" },
      cars: { title: "Автомобили" },
      orgs: { title: "Организации" },
      users: { title: "Пользователи" },
      settings: { title: "Настройки" },
    },
    orgMembers: { title: "Участники" },
    cars: { title: "Автомобили" },
    campaigns: { title: "Рекламные кампании" },
    settings: { title: "Настройки" },

    profile: {
      edit: { title: "Редактирование профиля" },
    },

    campaign: {
      title: "Рекламная кампания",
      create: { title: "Создание рекламной кампании" },
      edit: { title: "Редактирование кампании" },
      ads: { title: "Рекламные материалы" },
      picker: { title: "Выбор рекламной кампании" },
    },
    ad: {
      title: "Рекламный материал",
      create: { title: "Размещение рекламного материала" },
    },
    car: {
      title: "Автомобиль",
      create: { title: "Добавление автомобиля" },
      edit: { title: "Редактирование автомобиля" },
      driver: {
        add: { title: "Добавление водителя" },
      },
      picker: { title: "Выбор автомобиля" },
    },
    org: {
      title: "Организация",
      add: { title: "Добавление организации" },
      settings: { title: "Настройки организации" },
      picker: { title: "Выбор организации" },
    },
    orgMember: {
      title: "Участник",
      invite: { title: "Приглашение участника" },
      edit: { title: "Редактирование участника" },
      picker: { title: "Выбор участника" },
    },
    user: {
      title: "Пользователь",
      invite: {
        title: "Приглашение пользователя",
      },
    },
  },

  common: {
    NetworkError: {
      connecting: "Соединение с сервером...",
    },
    PhoneInput: {
      placeholder: "Номер телефона",
    },
  },

  hooks: {
    useAssetPicker: {
      takePhoto: "Сделать фото",
      chooseFromGallery: "Выбрать из галереи",
      chooseDocument: "Выбрать документ",
      camera: {
        permissionDenied: "Доступ к камере запрещен",
      },
      gallery: {
        permissionDenied: "Доступ к галерее запрещен",
      },
    },
  },

  SignIn: {
    title: "Телефон",
    error: "Неверный номер телефона",
  },
  SignInCode: {
    title: "Код подтверждения",
    description:
      "Мы отправили SMS с кодом проверки на\nВаш телефон <b>{{phone}}</b>",
    code: {
      placeholder: "Код",
      error: {
        OTP_NOT_FOUND: "Неверный код, попробуйте еще раз",
        OTP_INVALID: "Неверный код, попробуйте еще раз",
        OTP_EXPIRED: "Срок действия кода истек, запросите новый код",
        OTP_TOO_MANY_REQUESTS: "Много попыток, запросите новый код",
      },
    },
    checkingNumber: "Проверяем номер телефона...",
  },

  SignUp: {
    logout: "Выйти",
    firstName: "Имя",
    lastName: "Фамилия",
    middleName: "Отчество",
  },

  Campaign: {
    ads: "Рекламные материалы",
    name: "Название",
    deleteCampaign: "Удалить кампанию",
    carsCount: "Количество автомобилей",
    periodDays: "Длительность, дней",
    regions: "Регионы",
    comment: "Комментарий",
    layouts: "Макеты",
  },
  CampaignCreate: {
    name: {
      title: "Название кампании",
      empty: "Укажите название",
    },
    periodDays: {
      title: "Длительность, дней",
      description: "Минимум 30 дней",
    },
    carsCount: {
      title: "Количество автомобилей",
      description: "Минимум 50 автомобилей",
    },
    regions: {
      title: "Регионы размещения",
      empty: "Выберите хотя бы один регион",
      description: "Через запятую",
    },
    layouts: {
      title: "Макеты",
    },
    comment: {
      title: "Комментарий",
    },
  },
  CampaignEdit: {
    name: {
      title: "Название кампании",
      empty: "Укажите название",
    },
    periodDays: {
      title: "Длительность, дней",
      description: "Минимум 30 дней",
    },
    carsCount: {
      title: "Количество автомобилей",
      description: "Минимум 50 автомобилей",
    },
    regions: {
      title: "Регионы размещения",
      empty: "Выберите хотя бы один регион",
      description: "Через запятую",
    },
    layouts: {
      title: "Макеты",
    },
    comment: {
      title: "Комментарий",
    },
  },
  CampaignList: {
    create: "Создать рекламную кампанию",
  },
  getCampaignListItem: {
    campaign: "Рекламная кампания",
    subtitle: "{{carsCount}} авто, {{periodDays}} дней",
  },
  Ad: {
    org: "Организация",
    campaign: "Рекламная кампания",
    car: "Автомобиль",
    createdAt: "Дата размещения",
    expireAt: "Дата окончания размещения",
    photos: "Фотоотчет",
  },
  AdCreate: {
    placement: "Место размещения",
    photos: {
      title: "Фотоотчет",
    },
    create: "Разместить",
  },
  AdList: {
    create: "Разместить рекламный материал",
  },
  AdList2: {
    photos: "{{count}} фото",
    org: "Организация",
    campaign: "Рекламная кампания",
  },
  getAdListItem: {
    createdAt: "Размещено {{date}}",
    expireAt: "до {{date}}",
  },
  Car: {
    editTitle: "Редактирование автомобиля",
    deleteSpace: "Удалить автомобиль",
    year: "Год выпуска",
    plate: "Гос. номер",
    ads: "Рекламные материалы",
    drivers: "Водители",
    currentAd: "Текущий рекламный материал",
    createAd: "Разместить рекламный материал",
    replaceAd: "Заменить рекламный материал",
  },
  CarCreate: {
    brand: {
      title: "Марка",
      empty: "Укажите марку",
    },
    model: {
      title: "Модель",
      empty: "Укажите модель",
    },
    year: {
      title: "Год выпуска",
      placeholder: "ГГГГ",
      invalid: "Неверный год",
    },
    plate: {
      title: "Гос. номер",
      placeholder: "0000 XX-0",
      empty: "Укажите гос. номер",
    },
    drivers: "Водители",
    addDriver: "Добавить водителя",
  },
  CarDriverAdd: {
    phone: {
      label: "Номер телефона водителя",
      error: {
        invalid: "Неверный номер телефона",
        empty: "Укажите номер телефона водителя",
        conflict: "Водитель с таким номером уже добавлен",
      },
    },
  },
  CarList: {
    add: "Добавить автомобиль",
    available: "Свободные",
    busy: "Занятые",
  },
  getCarListItem: {
    car: "Автомобиль",
    available: "Свободен",
    busy: "Занят до {{expireAt}}",
  },
  getOrgListItem: {
    org: "Организация",
    tin: "УНП {{tin}}",
    role: {
      owner: "Владелец",
      admin: "Админ",
    },
  },
  Org: {
    currentOrg: "Текущая организация",
    switchToThisOrg: "Переключиться на эту организацию",
    tin: "УНП",
    entityTypeName: "вид объекта",
    activityTypeName: "вид деятельности",
    registrationAuthorityName: "орган принявший решение о создании",
    registrationDate: "дата регистрации",
    statusName: "состояние",

    settings: "Настройки организации",
    deleteOrg: "Удалить организацию",
    deleteOrgConfirm: {
      title: "Внимание!\nЭто действие не обратимо!",
      description: "Вы уверены, что хотите удалить организацию {{orgName}}?",
    },

    leaveOrg: "Покинуть организацию",
    leaveOrgOwnerDescription:
      "Вы не можете покинуть организацию, так как являетесь ее владельцем",
  },
  OrgAdd: {
    name: "Название организации",
    unp: {
      title: "УНП",
      placeholder: "УНП организации",
      error: {
        default: "{{code}}",
        invalid: "Неверный УНП",
        ORG_INFO_NOT_FOUND: "Организация с таким УНП не найдена",
      },
    },
    jobTitle: {
      title: "Должность",
      placeholder: "Ваша должность в организации",
      error: {
        empty: "Укажите вашу должность",
      },
    },
    orgInfo: "Информация об организации",
    entityTypeName: "Вид объекта",
    displayName: "Название",
    activityTypeName: "Вид деятельности",
    registrationAuthorityName: "Орган принявший решение о создании",
    registrationDate: "Дата регистрации",
    statusName: "Состояние",
  },
  OrgHeader: {
    role: {
      owner: "Владелец",
      admin: "Администратор",
      member: "Участник",
    },
    usersCount_one: "{{count}} участник",
    usersCount_few: "{{count}} участника",
    usersCount_many: "{{count}} участников",
  },
  OrgList: {
    add: "Добавить организацию",
  },
  OrgListItemFooter: {
    reject: "Отклонить",
    join: "Присоединиться",
    invitedYouToJoin: "Вас приглашает {{inviterName}}",
  },
  OrgSwitcher: {
    role: {
      owner: "Владелец",
      admin: "Администратор",
      member: "Участник",
    },
    chooseOrg: "Выбрать организацию",
  },
  getOrgMemberAdminFeaturesSection: {
    title: "Возможности администратора",
    permission: {
      carsManagement: "Управление автомобилями",
      campaignsManagement: "Управление рекламными кампаниями",
      membersManagement: "Управление участниками",
    },
    membersManagement: {
      disabled: "Этот пользователь не может добавлять новых участников.",
      enabled:
        "Этот пользователь может добавлять других участников с такими же или более ограниченными правами.",
    },
  },
  getOrgMemberListItem: {
    member: "Участник организации",
    role: {
      owner: "Владелец",
      admin: "Администратор",
      member: "Участник",
    },
    invitedBy: "Пригласил(а) {{name}}",
  },
  OrgMember: {
    inviteStatuse: {
      pending: "Приглашение отправлено",
      rejected: "Приглашение отклонено",
    },
    resendInvite: "Отправить повторно",
    role: "Роль в организации",
    jobTitle: "Должность",
    phone: "Телефон",
    roles: {
      owner: "Владелец",
      admin: "Администратор",
      member: "Участник",
    },
    cars: {
      title: "Автомобили",
      driversCount_one: "{{count}} водитель",
      driversCount_few: "{{count}} водителя",
      driversCount_many: "{{count}} водителей",
    },
  },
  OrgMemberInvite: {
    phone: {
      label: "Номер телефона",
      invalid: "На этот номер телефона нельзя отправить приглашение",
      empty: "Укажите номер телефона",
    },
    jobTitle: {
      label: "Должность",
      empty: "Укажите должность",
      noMembersManagementPermission:
        "Для изменения должности необходимо разрешение на управление участниками",
      cantChangeOwner: "Вы не можете изменить должность владельца",
    },
  },
  OrgMemberEdit: {
    jobTitle: {
      label: "Должность",
      empty: "Укажите должность",
      noMembersManagementPermission:
        "Для изменения должности необходимо разрешение на управление участниками",
      cantChangeOwner: "Вы не можете изменить должность владельца",
    },
    cars: {
      title: "Автомобили",
      driversCount_one: "{{count}} водитель",
      driversCount_few: "{{count}} водителя",
      driversCount_many: "{{count}} водителей",
    },
    removeFromOrg: "Исключить из организации",
    noPermissionToRemove:
      "Для исключения участника необходимо разрешение на управление участниками",
    cantRemoveOwner: "Владельца нельзя исключить из организации",

    transferOrgOwnership: "Передать права на организацию",
    transferConfirm: {
      title: "Передача прав на организацию",
      message:
        "В этом случае права на организацию {{orgName}} будут полностью переданы пользователю {{userName}}.\n\nВы больше не будете считаться создателем организации. Новый владелец сможет разжаловать Вас из администраторов или даже заблокировать.",
      changeOwner: "Сменить владельца",
    },
  },
  OrgMemberList: {
    admins: "Администраторы",
    members: "Участники",
  },
  ProfileEdit: {
    firstName: "Имя",
    lastName: "Фамилия",
    middleName: "Отчество",
    phone: "Телефон",
  },
  ProfileEditHeader: {
    choosePhoto: "Выбрать фотографию",
  },
  Settings: {
    changePhoto: "Изменить фотографию",
    currentOrg: "Текущая организация",
    invites: "Приглашения",
    joinOrg: "Присоединиться к организации",
  },
  User: {
    phone: "Телефон",
    inviter: "Пригласил(а)",
    orgs: "Организации",
    orgs_one: "Организация",
    cars: "Автомобили",
    cars_one: "Автомобиль",
  },
  UserHeader: {
    signUpRequired: "Ожидание регистрации",
  },
  UserInvite: {
    phone: {
      label: "Номер телефона",
      invalid: "Неверный номер телефона",
      empty: "Укажите номер телефона",
    },
  },
  UserList: {
    invite: "Пригласить пользователя",
  },
  getUserListItem: {
    signUpRequired: "Ожидание регистрации",
    inviter: "Пригласил(а) {{user.firstName}}",
  },
  Control: {
    advertisement: "Реклама",
    ads: "Рекламные материалы",
    campaigns: "Кампании",
    cars: "Автомобили",
    orgs: "Организации",
    users: "Пользователи",
    settings: "Настройки",
  },
  Report: {
    title: "Отчет по рекламной кампании",
    exporting: "Экспорт...",
    org: "Организация",
    campaign: "Рекламная кампания",
    duration: "Длительность",
    day_one: "{{count}} день",
    day_few: "{{count}} дня",
    day_many: "{{count}} дней",
    carsCount: "Количество автомобилей",
    regions: "Регион",
    photos: "Рекламные материалы",
    plannedStart: "Планируемое начало",
    plannedFinish: "Планируемое окончание",
    started: "Запущена",
    finish: "Окончание",
    status: "Статус",
    statusValue: {
      inReview: "На рассмотрении",
      planned: "Запланировано",
      placementStarted: "Размещение начато",
      started: "Активна",
    },
    map: {
      active: "Активные",
      inactive: "Неактивные",
    },
    createdAt: "Добавлено {{date}}",
    generated: "Отчет сформирован {{date}}",
  },
};
