import { FragmentType, gql, getFragmentData } from "__generated__";
import { Text, View, Wrap } from "components/common";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";
import { OrgPhoto } from "./OrgPhoto";

const Header_OrgFragment = gql(`
  fragment Header_OrgFragment on Org {
    id
    name
    members {
      id
    }
    member {
      id
      role
    }
  }
`);

type OrgHeaderProps = {
  org?: FragmentType<typeof Header_OrgFragment> | null;
};

export const OrgHeader = (props: OrgHeaderProps) => {
  const org = getFragmentData(Header_OrgFragment, props.org);
  const { t } = useTranslation("OrgHeader");

  return (
    <Wrap style={styles.header}>
      <OrgPhoto size={100} />
      <View>
        <Text
          style={styles.headerTitle}
          shimmerMaxWidth={240}
          loading={!org?.name}
        >
          {org?.name}
        </Text>
        <Text
          style={styles.headerSubtitle}
          colorName="textSecondary"
          shimmerMaxWidth={80}
          loading={!org?.member}
        >
          {org?.member && t(`role.${org.member.role}`)}
        </Text>
        <Text
          style={styles.headerSubtitle}
          colorName="textSecondary"
          shimmerMaxWidth={120}
          loading={!org?.members}
        >
          {t("usersCount", { count: org?.members?.length })}
        </Text>
      </View>
    </Wrap>
  );
};

const styles = StyleSheet.create({
  header: {
    gap: 8,
    paddingTop: 8,
    paddingBottom: 16,
  },
  headerTitle: {
    fontSize: 28,
    textAlign: "center",
    fontWeight: "500",
  },
  headerSubtitle: {
    textAlign: "center",
    fontSize: 17,
  },
});
