import { Stack } from "expo-router";
import { useTranslation } from "react-i18next";
import { Control } from "components/Control/Control";

export default function Page() {
  const { t } = useTranslation("pages");

  return (
    <>
      <Stack.Screen options={{ title: t("control.title") }} />
      <Control />
    </>
  );
}
