import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources";
import config from "config";

export const defaultNS = "g";
export const fallbackLng = config.defaultLocale;

i18n.use(initReactI18next).init({
  resources,
  fallbackLng,
  defaultNS,
  interpolation: {
    escapeValue: false,
    format: function (value, format, lng) {
      if (value instanceof Array && format === "formatOrgMemberPermissions") {
        return value
          .map((key) =>
            i18n.t(`org:member.permissions.${key}` as never, { lng })
          )
          .join(", ");
      }
      return value as never;
    },
  },
  returnNull: false,
});
