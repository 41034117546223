import { Settings } from "components/Settings/Settings";
import { gql } from "__generated__";
import { useQuery } from "@apollo/client";
import { OrgSwitcher } from "components/Org/OrgSwithcher";
import { Stack } from "expo-router";
import { OrgMemberInviteStatus } from "__generated__/graphql";

const Settings_Query = gql(`
  query Settings_Query($hideCurrentOrgMemberRole: Boolean = false) {
    user {
      orgsMember {
        id
        inviteStatus
      }
    }
    ...OrgSwitcher_QueryFragment
    ...Settings_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(Settings_Query);

  return (
    <>
      <Stack.Screen
        options={{
          headerTitle:
            !data ||
            data.user?.orgsMember.filter(
              (member) => member.inviteStatus === OrgMemberInviteStatus.Joined
            ).length
              ? () => <OrgSwitcher query={data} />
              : undefined,
        }}
      />
      <Settings query={data} />
    </>
  );
}
